import { Link, useNavigate } from 'react-router-dom';
import Header from './../layout/Header'; //Include Heder
import Footer from './../layout/Footer'; //Include Footer
import Service from './../../service';
import toast, { Toaster } from 'react-hot-toast';
import React, { useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';

import FacebookLogin from 'react-facebook-login';
import MetaHelmet from './../metaHelmet'; //Include metaHelmet
import GoogleLogin from 'react-google-login';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { firebase, auth } from './../firebase.js';

import { requestForToken } from './../firebaseNotifications/firebase';

const api = new Service();
let verify;
let recaptchaWrapperRef;

function logout() {
    // revoke app permissions to logout completely because FB.logout() doesn't remove FB cookie
    window.FB.api('/me/permissions', 'delete', null, () => window.FB.logout());
}

const Login = (props) => {
    const [config] = useState(props.config);
    const navigate = useNavigate();
    const [loading, setloading] = useState(false);
    const [showModel, setshowModel] = useState(false);
    const [step, setstep] = useState(0);
    const [message, setMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [invalidNumber, setinvalidNumber] = useState('');
    const [invalidOTP, setinvalidOTP] = useState('');


    useEffect(() => {
        localStorage.setItem("login", 0)
        if (invalidNumber) {
            setTimeout(function () {
                setinvalidNumber(false)
            }, 5000);
        }
    }, [invalidNumber])

    useEffect(() => {
        if (errorMessage) {
            setTimeout(function () {
                setErrorMessage(false)
            }, 3000);
        }
    }, [errorMessage])

    useEffect(() => {
        getFCMToken();
    }, [])

    const getFCMToken = () => {
        requestForToken().then((currentToken) => {
            formik.setFieldValue('device_token', currentToken);
        });
    }

    const formik = useFormik({
        initialValues: {
            email_id: '',
            password: '',
            device_token: ''
        },
        validationSchema: yup.object({
            password: yup.string().required('Please enter your password'),
            email_id: yup.string().email('Invalid email address').required('Please enter your email address')
        }),
        onSubmit: values => {

            setloading(true)
            api.postApi('customer-login', values).then(response => {
                toast.success('Login successfully');
                formik.resetForm()
                setloading(false)
                localStorage.setItem("user_token", response.web_auth_key);
                localStorage.setItem("user_type", 'customer');
                localStorage.setItem("user_detail", response);
                localStorage.setItem("customer_id", response.customer_id)
                localStorage.setItem("is_login", 1)
                localStorage.setItem("login", 1)
                navigate('/book-move')
            }).catch(error => {
                setloading(false)
                //  seterror(false)
                setErrorMessage(true)
                setMessage(error.message)
                //  toast.error(error.message);
            });
        }
    });
    const [passwordType, setPasswordType] = useState("password");
    const togglePassword = () => {
        if (passwordType == "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }

    const [webAuthKey, setWebAuthKey] = useState('');
    const responseFacebook = (response) => {

        if (response && response.id != undefined) {
            var postData = { login_type: 'F', provider_key: response.id, name: response.name, email_id: response.email, photo_url: response.picture.data.url }
            setloading(true);
            api.postApi('customer-sign-up', postData).then(response => {
                setstep(1)
                logout()
                if (response.phone_num) {
                    localStorage.setItem("user_token", response.web_auth_key);
                    localStorage.setItem("user_type", 'customer');
                    localStorage.setItem("customer_id", response.customer_id)
                    localStorage.setItem("is_login", 1)
                    localStorage.setItem("login", 1)
                    navigate('/book-move')
                } else {
                    setWebAuthKey(response.web_auth_key)
                    setshowModel(true)
                }
                setloading(false);
                setshowModel(true)
            }).catch(error => {
                setloading(false);
                toast.error(error.message);
            });
        }
    }

    const responseGoogle = (response) => {
        console.log(response);
        if (response && response.googleId != undefined) {
            var postData = { login_type: 'G', provider_key: response.googleId, name: response.profileObj.name, email_id: response.profileObj.email, photo_url: response.profileObj.imageUrl }
            setloading(true);
            api.postApi('customer-sign-up', postData).then(response => {
                setstep(1)
                if (response.phone_num) {
                    localStorage.setItem("user_token", response.web_auth_key);
                    localStorage.setItem("user_type", 'customer');
                    localStorage.setItem("customer_id", response.customer_id)
                    localStorage.setItem("is_login", 1)
                    localStorage.setItem("login", 1)
                    navigate('/book-move')
                } else {
                    setWebAuthKey(response.web_auth_key)
                    setshowModel(true)
                }
                setloading(false);
            }).catch(error => {
                setloading(false);
                toast.error(error.message);
            });
        }
    }

    const formik2 = useFormik({
        initialValues: {
            phone_num: '',
            country_code: ''
        },
        validationSchema: yup.object({
            phone_num: yup.number().typeError('you must specify a number').required('Please enter your phone number.'),
            country_code: yup.string().required('Please select your country.')
        }),
        onSubmit: values => {
            sentOTP();
        }

    });

    const formik3 = useFormik({
        initialValues: {
            OTP: ''
        },
        validationSchema: yup.object({
            OTP: yup.string().required('Please enter a vaild OTP.'),
        }),
        onSubmit: values => {
            ValidateOtp()
        }

    });

    const formik4 = useFormik({
        initialValues: {
            phone_num: formik2.values.phone_num ? formik2.values.phone_num : "",
            web_auth_key: webAuthKey ? webAuthKey : "",
            about_move_it: ''
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            about_move_it: yup.string().required('Please select one'),
        }),
        onSubmit: values => {
            console.log(values)
            setloading(true);
            api.postApi('update-customer-phone-number', (values)).then(response => {
                setstep(0)
                setloading(false);
                setshowModel(false)
                if (response && response.data) {
                    localStorage.setItem("user_token", response.data.web_auth_key);
                    localStorage.setItem("user_type", 'customer');
                    navigate('/book-move')

                }
            }).catch(error => {
                setloading(false);
            });
        }

    });

    const [final, setfinal] = useState('');
    const sentOTP = () => {
        if (formik2.isValid && formik2.values.phone_num) {
            setloading(true);
            if (verify && recaptchaWrapperRef) {
                verify.clear()
                recaptchaWrapperRef.innerHTML = `<div id="recaptcha-container"></div>`
            }
            verify = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
                'size': 'invisible'
            });
            auth.signInWithPhoneNumber('+' + formik2.values.country_code + formik2.values.phone_num, verify).then((result) => {
                setfinal(result);
                setstep(2)
                setloading(false);
            }).catch((err) => {
                setloading(false);
                setinvalidNumber("Please enter vaild phone number.")
                // toast.error("Please enter vaild phone number.");
            });
        } else {
            formik2.handleSubmit();
        }
    }

    const ValidateOtp = () => {
        if (formik3.isValid && formik3.values.OTP) {
            setloading(true);
            if (final == null)
                return;
            final.confirm(formik3.values.OTP).then((result) => {
                setstep(3)
                setloading(false);
            }).catch((err) => {
                setloading(false);
                setinvalidOTP("Please enter a vaild OTP.")
            })
        } else {
            formik3.handleSubmit();
        }
    }

    useEffect(() => {
        getCountry()
        getAboutMoveList();
    }, [])

    const [aboutList, setaboutList] = useState([]);
    const getAboutMoveList = () => {
        setloading(true)
        api.getApi('get-about-moveit-list').then(response => {
            if (response && response.data) {
                setaboutList(response.data)
            }
            setloading(false)
        }).catch(error => {
            setloading(false)
            toast.error(error.message);
        });
    }

    const [country, setcountry] = useState([]);
    const getCountry = () => {
        api.getApi('get-country').then(response => {
            setcountry(response)
        }).catch(error => {
        });
    }

    return (
        <>
            <MetaHelmet title="Move It | Login" description="" />
            <Toaster position="top-right" reverseOrder={false} />
            <Header></Header>
            <main>
                <section className="work white mobile-paddtop">
                    <article className="container">
                        <div className="row align-items-center d-flex align-center" data-aos="fade-left" data-aos-delay="100">
                            <div className="col-md-6">
                                <div className="contact-wrapper">
                                    <div className="title">
                                        <h3 className="text-center">Log In</h3>
                                        <div ref={ref => recaptchaWrapperRef = ref}>
                                            <div id="recaptcha-container"></div>
                                        </div>
                                    </div>
                                    {errorMessage && message ?
                                        <p className="text-danger text-center" style={{ textAlign: "initial", color: "red" }}>{message}</p>
                                        : ''}
                                    <form className="login-page " onSubmit={formik.handleSubmit} autoComplete="off">
                                        <div className="form-row">
                                            <i className="fa fa-envelope" style={{ left: '20px' }}></i>
                                            <input type="text" name="email_id" autoComplete="off" {...formik.getFieldProps("email_id")} />
                                            <span>Email</span>
                                        </div>
                                        {formik.touched.email_id && formik.errors.email_id ? <span className="text-danger">{formik.errors.email_id}</span> : null}
                                        <div className="form-row">
                                            <i className="fa fa-lock" style={{ left: '20px' }}></i>
                                            <input id="password-field" name="password" type={passwordType} autoComplete="new-password"  {...formik.getFieldProps("password")} />
                                            <span>Password</span>
                                            <div toggle="#password-field" className={(passwordType == "password" ? 'fa fa-fw fa-eye-slash field-icon toggle-password ' : 'fa fa-fw field-icon toggle-password fa-eye')} onClick={togglePassword}></div>
                                        </div>
                                        {formik.touched.password && formik.errors.password ? <span className="text-danger">{formik.errors.password}</span> : null}

                                        <div className="form-row"></div>
                                        <div className="forgot-password" ><Link to="/forgot-password" > Forgot Password?</Link></div>
                                        <div className="form-row">
                                            <button className="full" type="submit"  ><span>LOG IN</span></button>
                                        </div>
                                    </form>
                                    <div className="socials-wrapper">
                                        <div className="s-account">
                                            <h2>Login with your Social Account</h2>
                                        </div>

                                        <div className="soculbtn">
                                            <div className="facebook">
                                                <FacebookLogin
                                                    appId={config.FACEBOOK_APP_ID} //APP ID NOT CREATED YET
                                                    // fields="name,email,picture"
                                                    fields="name,email,picture"
                                                    scope="public_profile"
                                                    autoLoad={false}
                                                    callback={responseFacebook}
                                                    cssClass="facebook-button-class"
                                                />
                                            </div>

                                            <div className="twitter">
                                                <GoogleLogin
                                                    clientId={config.GOOGLE_CLIENT_ID} //CLIENTID NOT CREATED YET
                                                    buttonText="LOGIN WITH GOOGLE"
                                                    autoLoad={false}
                                                    onSuccess={responseGoogle}
                                                    onFailure={responseGoogle}

                                                    className="google-button-class"
                                                />

                                            </div>
                                        </div>


                                        {/*	<ul>
        							<GoogleLogout
                          clientId={config.GOOGLE_CLIENT_ID}
                          buttonText="Logout"
                          onLogoutSuccess={logout}
                        >
                        </GoogleLogout>
        							  <li><a href="#" className="facebook"><i className="fab fa-facebook-square"></i></a></li>
        							  <li><a href="#" className="twitter"><i className="fab fa-google"></i></a></li>
        							</ul>*/}
                                        <h6 className="text-center dont-acct">Don't have an account yet? <Link to="/signup">Signup</Link></h6>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <img src="web/img/05.jpg" alt="moveit" className="login-right-img" />
                            </div>
                        </div>
                    </article>
                </section>
            </main>

            <div className={showModel ? "modal display-block" : "modal display-none"} id="otp-model" tabIndex="-1" role="dialog" aria-labelledby="def" aria-hidden="true">
                <div className="modal-dialog modal-sm" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="myModalLabel2">
                                {step == 1 ?
                                    <>
                                        Enter Your Phone Number
                                    </>
                                    : ''}

                                {step == 2 ?
                                    <>
                                        Enter Your Code
                                    </>
                                    : ''}
                                {step == 3 ?
                                    <>
                                        How did you hear about Move It?
                                    </>
                                    : ''}


                            </h4>
                            <button type="button" className="close" onClick={() => setshowModel(false)}><span aria-hidden="true" onClick={() => setshowModel(false)}>&times;</span></button>
                        </div>
                        <div className="modal-body">
                            <form className="login-page" onSubmit={step == 1 ? formik2.handleSubmit : step == 2 ? formik3.handleSubmit : formik4.handleSubmit}>

                                {step == 1 ?
                                    <>


                                        <div className="form-row">
                                            <select className="select-text" name="country_code"  {...formik2.getFieldProps("country_code")} >
                                                <option className="option-margin" value="">Select</option>
                                                {country && country.map((option, index) => (
                                                    <option className="option-margin" value={option.id} key={index} >{option.text} </option>
                                                ))}
                                            </select>
                                        </div>
                                        {formik2.touched.country_code && formik2.errors.country_code ? <div className="text-danger">{formik2.errors.country_code}</div> : null}
                                        <div className="form-row">
                                            <i className="fa fa-phone"></i>
                                            <input type="text" autoComplete="off" name="phone_num" {...formik2.getFieldProps("phone_num")} />

                                        </div>
                                        {formik2.touched.phone_num && formik2.errors.phone_num ? <div className="text-danger">{formik2.errors.phone_num}</div> : null}
                                        {invalidNumber ?
                                            <>
                                                <div className="text-danger">{invalidNumber}</div>
                                            </>
                                            : ''}
                                        <p className="mb-0 f-14">We need your phone number so we can give updates on your moves</p>

                                        <div className="form-row">
                                            <button className="full" type="button" onClick={sentOTP} ><span>Send Code</span></button>
                                        </div>
                                    </>

                                    : ''}
                                {step == 2 ?
                                    <>
                                        <p className="mb-0 f-14">Enter verification code sent to {formik2.values.phone_num} </p>
                                        <div className="form-row">
                                            <i className="fa fa-phone"></i>
                                            <input type="text" name="OTP" {...formik3.getFieldProps("OTP")} />

                                        </div>
                                        {formik3.touched.OTP && formik3.errors.OTP ? <div className="text-danger">{formik3.errors.OTP}</div> : null}
                                        {invalidOTP && formik3.errors.OTP == '' ?
                                            <>
                                                <div className="text-danger">{invalidOTP}</div>
                                            </>
                                            : ''}
                                        <div className="form-row">
                                            <button className="full" type="button" onClick={ValidateOtp} ><span>Continue</span></button>
                                        </div>
                                    </>
                                    : ''}

                                {step == 3 ?
                                    <>
                                        <p className="mb-0 f-14">Take a mintue to complete survey</p>
                                        <div className="form-row">
                                            <select className="select-text" name="about_move_it" {...formik4.getFieldProps("about_move_it")}>
                                                <option className="option-margin" value="">Select</option>
                                                {aboutList.length > 0 && aboutList.map((option, index) => (
                                                    <option className="option-margin" value={option.value} key={index} >{option.key}</option>
                                                ))}
                                            </select>
                                            {formik4.touched.about_move_it && formik4.errors.about_move_it ? <div className="text-danger">{formik4.errors.about_move_it}</div> : null}
                                        </div>
                                        <div className="form-row">
                                            <button type="submit" className="full"  ><span>Continue</span></button>
                                        </div>
                                    </>
                                    : ''}


                                {/*<div className="form-row"></div>
        				<div className="forgot-password f-14">We need your phone number so we can give updates on your moves</div>
        				 
        				 <div className="forgot-password f-16">
        				 <br></br> Not Recevied OTP <Link  to="" >Resend OTP</Link> </div>*/}



                            </form>

                        </div>
                    </div>
                </div>
            </div>

            <Footer loading={loading}></Footer>

        </>
    );
}


export default Login;

