import React, {useState, useEffect} from "react";
import {Link } from 'react-router-dom';
import Header from './../layout/Header'; //Include Heder
import Footer from './../layout/Footer'; //Include Footer
import HelperSideBar from './../layout/HelperSideBar';
import Service from './../../service'; 
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import ProfileSection from './profileSection';
import moment from 'moment-timezone';
import PaginationComponent from '../../PaginationComponent';
import MetaHelmet from './../metaHelmet'; //Include metaHelmet

// import PaginationComponent from "react-reactstrap-pagination";
import "bootstrap/dist/css/bootstrap.min.css";


const api = new Service();
const Moves= props =>{ 
    
    const [loading, setloading] = useState(false);
    const [page_no, setpage_no] = useState(1);
    const [pagesCount, setpagesCount] = useState(0);
    const [dataArray, setdataArray] = useState([]);
    const [activeTab, setactiveTab] = useState('tab1');
    const [helperProfile,sethelperProfile] = useState({});

    const navigate = useNavigate();
    
    useEffect(() => {
       GethelperProfile();
    }, [])
    const GethelperProfile = () => {
        setloading(true)
        api.getApiHelper('get-helper-info').then(response => {
            if(response && response.is_verified==0) {
                setloading(false);
                navigate('/helper-pending')
            } else {
                sethelperProfile(response);
                setloading(false);
                GetPendingMoves();
            }
        }).catch(error => { 
            setloading(false);
            toast.error(error.message);
        });
    }
    const GetPendingMoves = (page_no=1) => {
        setloading(true)
        setpage_no(page_no)
        api.postApiHelper('get-helper-scheduled-pendding-move',{page_no:page_no,timezone:moment.tz.guess()}).then(response => {
            setdataArray(response.data);
            setpagesCount(response.totalItemsCount)
            setloading(false)
        }).catch(error => {  
            setloading(false)
            toast.error(error.message);
        }); 
    }  
    
    const GetScheduleMoves = (page_no=1) => {
        setloading(true)
        setpage_no(page_no)
        api.postApiHelper('get-helper-scheduled-move',{page_no:page_no,timezone:moment.tz.guess()}).then(response => {
            setdataArray(response.data);
            setpagesCount(response.totalItemsCount)
            setloading(false)
        }).catch(error => {  
            setloading(false)
            toast.error(error.message);
        }); 
    }  
    
    const GetCompeleteMoves = (page_no=1) => {
        setloading(true)
        setpage_no(page_no)
        api.postApiHelper('get-helper-completed-move',{page_no:page_no,timezone:moment.tz.guess()}).then(response => {
            setdataArray(response.data);
            setpagesCount(response.totalItemsCount)
            setloading(false)
        }).catch(error => {  
            setloading(false)
            toast.error(error.message);
        }); 
    }  
    
    const GetCanceledMoves = (page_no=1) => {
        setloading(true)
        setpage_no(page_no)
        api.postApiHelper('get-helper-cancel-move',{page_no:page_no,timezone:moment.tz.guess()}).then(response => {
            setdataArray(response.data);
            setpagesCount(response.totalItemsCount)
            setloading(false)
        }).catch(error => {  
            setloading(false)
            toast.error(error.message);
        }); 
    }  
    
    const changeTab = (tab) => {
        setpagesCount(0)
        setactiveTab(tab) 
        setdataArray([]); 
        if(tab == 'tab1') {
            GetPendingMoves(1);
        } else if(tab == 'tab2') {
            GetScheduleMoves(1);
        } else if(tab == 'tab3') {
            GetCompeleteMoves(1);
        } else if(tab == 'tab4') {
            GetCanceledMoves(1);
        }
    }
    
    
    const getRecord = (page) => {
        if(page!=page_no) {
            if(activeTab == 'tab1') {
                GetPendingMoves(page);
            } else if(activeTab == 'tab2') {
                GetScheduleMoves(page);
            } else if(activeTab == 'tab3') {
                GetCompeleteMoves(page);
            } else if(activeTab == 'tab4') {
                GetCanceledMoves(page);
            }
        }
    }
    
    const setNUmberFormat = (num) => {
        if(num>0) {
         return   '$'+ (Math.round(num * 100) / 100).toFixed(2);
        }
        return '$'+num;
    }
    
    return (
        <>
        <Toaster position="top-right" reverseOrder={false} />
        <MetaHelmet title="Move It | Helper Moves" description="" /> 
            <Header helperProfile={helperProfile} />
            <main>
            {helperProfile.is_verified?
            <>
                <section className="coverphoto-helper p-0"></section>
                <ProfileSection helperProfile={helperProfile} />
            
                <section className="work white p40">
                    <article className="container">
                        <div className="row">
                            <HelperSideBar helperProfile={helperProfile} />
            
                            <div className="col-md-8 col-lg-9">
                           
                                <div className="row d-flex align-items-center justify-content-center" data-aos="fade-left" data-aos-delay="100">
                                    <div className="col-12 col-md-12 col-lg-10 mb-3">
                                        <h3>My Moves</h3>
                                    </div>
                                    <div className="col-12 col-md-12 col-lg-10 text-center">
                                       <div className="helper-available-job"> 
											<h5 style={{margin: "10px"}}>It is required to download Move It helper app by clicking on below icons, to perform jobs on Move It platform.</h5>
											<div className="mb-3">
											<a href="https://apps.apple.com/in/app/move-it-helper/id1488199360"><img className="mr-2" src="web/img/app_store-btn.png" className="w130" alt="" /></a>
											<a href="https://play.google.com/store/apps/details?id=com.moveit.helper&hl=en&gl=US"><img className="" src="web/img/play_store-btn.png" className="w130" alt="" /></a>
											</div>
										</div>
                                    </div>
                                    <div className="col-md-12 col-lg-10">
                                        <div className="contact-wrapper">
                                            <div id="tsum-tabs">
                                                
                                                <input id="tab1" type="radio" name="tabs"  value="tab1" onChange={() => changeTab('tab1')}  checked={activeTab=='tab1'}  disabled={loading} />
                                                <label htmlFor="tab1">Pending</label>
                                                
                                                <input id="tab2" type="radio" name="tabs"  value="tab2" onChange={() => changeTab('tab2')}  checked={activeTab=='tab2'}  disabled={loading} />
                                                <label htmlFor="tab2">Scheduled</label>
                                                
                                                <input id="tab3" type="radio" name="tabs"  value="tab3" onChange={() => changeTab('tab3')}  checked={activeTab=='tab3'}   disabled={loading} />
                                                <label htmlFor="tab3">Complete</label>
                                                <input id="tab4" type="radio" name="tabs"  value="tab4" onChange={() => changeTab('tab4')}  checked={activeTab=='tab4'}   disabled={loading} />
                                                <label htmlFor="tab4">Canceled</label>
            
                                                <section id="content1" className="mymovesbox">
                                                    <div className="notification-scroll" id="style-4"> 
                										 <div className="row">
                											<div className="col-12 col-md-12">
                                                               {dataArray.length > 0 && dataArray.map((option, index) => (
                                                                    <div className="d-block w-100 float-right mb-2"  key={index}>
                                                                        <div className="p-3 d-flex align-items-center bg-light border-bottom osahan-post-header">
                                                                            <div className="dropdown-list-image mr-3">
                                                                                <img className="rounded-circle" src={option.photo_url} alt="" />
                                                                            </div>
                                                                            <div className="font-weight-bold mr-3 notification-hrf">
                                                                                <a href="">
                                                                                    <div className="">{option.customer_name}</div>
                                                                                    <div className="small">{option.dropoff_service_name}</div>
                                                                                     <div className="small">{option.helping_service_name}</div>
                                                                                </a>
                                                                            </div>
                                                                            <span className="ml-auto mb-auto">
                                                                               {option.helper_accept_service_type==2?
                                                                                 <>
                                                                                <div className="text-right">Muscle</div>
                                                                                <div className="text-right price-helper">{setNUmberFormat(option.total_muscle_amount)}</div>
                                                                                 </>
                                                                                :''}
                                                                                {option.helper_accept_service_type==1?
                                                                                 <>
                                                                                <div className="text-right">Pro</div>
                                                                                <div className="text-right price-helper">{setNUmberFormat(option.total_pros_amount)}</div>
                                                                                 </>
                                                                                :''}
                                                                               
                                                                                  {option.is_dropoff_address?
                                                                                <>
                                                                                <div className="small text-right">{setNUmberFormat(option.total_miles_distance)} Miles</div>
                                                                                </>
                                                                                :''}
                                                                            </span>
                                                                        </div>
                                                                        <div className="row no-gutters bg-light p-10all-side">
                                                                           {option.is_pickup_address?
                                                                            <div className="col-xl-6 col-lg-6 col-md-6 align-self-center">
                                                                                <i className="pr-1 maploc"><img src="/web/img/from_icon.png" alt=""/></i>
                                                                                <small>
                                                                                {option.is_dropoff_address? 'From' : 'Pickup Address' }
                                                                                </small>
                                                                                <br /> 
                                                                                <span className="maptxt">{option.pickup_address}</span>
                                                                            </div>
                                                                            : '' }
                                                                            {option.is_dropoff_address?
                                                                            <div className="col-xl-6 col-lg-6 col-md-6 align-self-center">
                                                                                <i className="pr-1 maploc"><img src="/web/img/to_location_icon.png" alt="" /></i><small>To</small>
                                                                                <br />
                                                                                <span className="maptxt">{option.dropoff_address}</span>
                                                                            </div>
                                                                            : '' }
                                                                        </div>
                                                                        <div className="row no-gutters bg-light p-10all-side pb-2">
                                                                            <div className="col-8 col-lg-9 align-self-center">
                                                                                <i className="far fa-calendar-alt pr-1 maploc-1"></i><small>Pickup Date/Time</small>
                                                                                <br />
                                                                                <span className="maptxt">{option.pickup_date}, {option.pickup_start_time} - {option.pickup_end_time}</span>
                                                                                {option && option.is_reconfirm==1? 
                                                                                    <>
                                                                                    <br/>
                                                                                    <span className="maptxt customer_edit_move" >Customer Edited Move</span>
                                                                                    </> 
                                                                                :''}
                                                                            </div>
                                                                            <div className="col-4 col-lg-3 align-self-center text-md-right mt-3 mt-md-0">
                                												<Link className="accpet-helpebtn" to={"/helper-move-detail/"+option.request_id} >View</Link>
                                											</div>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            	{dataArray.length == 0 && loading==false? <p className="nomoveimg"><img alt="" src="web/img/no_moves_placeholder.png" /> <br/> No Pending Moves Yet!</p> : '' }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                                <section id="content2" className="mymovesbox">
                                                    <div className="notification-scroll" id="style-4"> 
                										 <div className="row">
                											<div className="col-12 col-md-12">
                                                                {dataArray.length > 0 && dataArray.map((option, index) => (
                                                                    <div className="d-block w-100 float-right mb-2"  key={index}>
                                                                        <div className="p-3 d-flex align-items-center bg-light border-bottom osahan-post-header">
                                                                            <div className="dropdown-list-image mr-3">
                                                                                <img className="rounded-circle" src={option.photo_url} alt="" />
                                                                            </div>
                                                                            <div className="font-weight-bold mr-3 notification-hrf">
                                                                                <a href="">
                                                                                    <div className="">{option.customer_name}</div>
                                                                                    <div className="small">{option.dropoff_service_name}</div>
                                                                                    <div className="small">{option.helping_service_name}</div>
                                                                                </a>
                                                                            </div>
                                                                            <span className="ml-auto mb-auto"> 
                                                                                {option.helper_accept_service_type==2?
                                                                                 <>
                                                                                <div className="text-right">Muscle</div>
                                                                                <div className="text-right price-helper">{setNUmberFormat(option.total_muscle_amount)}</div>
                                                                                 </>
                                                                                :''}
                                                                                {option.helper_accept_service_type==1?
                                                                                 <>
                                                                                <div className="text-right">Pro</div>
                                                                                <div className="text-right price-helper">{setNUmberFormat(option.total_pros_amount)}</div>
                                                                                 </>
                                                                                :''}
                                                                                
                                                                                  {option.is_dropoff_address?
                                                                                <>
                                                                                <div className="small text-right">{setNUmberFormat(option.total_miles_distance)} Miles</div>
                                                                                </>
                                                                                :''}
                                                                            </span>
                                                                        </div>
                                                                        <div className="row no-gutters bg-light p-10all-side">
                                                                           {option.is_pickup_address?
                                                                            <div className="col-xl-6 col-lg-6 col-md-6 align-self-center">
                                                                                <i className="pr-1 maploc"><img src="/web/img/from_icon.png" alt=""/></i>
                                                                                <small>
                                                                                {option.is_dropoff_address? 'From' : 'Pickup Address' }
                                                                                </small>
                                                                                <br />
                                                                                <span className="maptxt">{option.pickup_address}</span>
                                                                            </div>
                                                                            : '' }
                                                                            {option.is_dropoff_address?
                                                                            <div className="col-xl-6 col-lg-6 col-md-6 align-self-center">
                                                                                <i className="pr-1 maploc"><img src="/web/img/to_location_icon.png" alt="" /></i><small>To</small>
                                                                                <br />
                                                                                <span className="maptxt">{option.dropoff_address}</span>
                                                                            </div>
                                                                            : '' }
                                                                        </div>
                                                                        <div className="row no-gutters bg-light p-10all-side pb-2">
                                                                            <div className="col-8 col-lg-9 align-self-center">
                                                                                <i className="far fa-calendar-alt pr-1 maploc-1"></i><small>Pickup Date/Time</small>
                                                                                <br />
                                                                                <span className="maptxt">{option.pickup_date}, {option.pickup_start_time} - {option.pickup_end_time}</span>
                                                                            </div>
                                                                            {/*<div className="col-4 col-lg-3 align-self-center text-md-right mt-3 mt-md-0">
                                												<Link className="accpet-helpebtn" to="#">Cancel Move</Link>
                                											</div>*/}
                                											<div className="col-4 col-lg-3 align-self-center text-md-right mt-3 mt-md-0">
                                												<Link className="accpet-helpebtn" to={"/helper-move-detail/"+option.request_id} >View</Link>
                                											</div>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                                {dataArray.length == 0 && loading==false? <p className="nomoveimg"><img alt="" src="web/img/no_moves_placeholder.png" /> <br/> No Scheduled Moves Yet!</p> : '' }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                                <section id="content3" className="mymovesbox">
                                                    <div className="notification-scroll" id="style-4"> 
                										 <div className="row">
                											<div className="col-12 col-md-12">
                                                                {dataArray.length > 0 && dataArray.map((option, index) => (
                                                                    <div className="d-block w-100 float-right mb-2"  key={index}>
                                                                        <div className="p-3 d-flex align-items-center bg-light border-bottom osahan-post-header">
                                                                            <div className="dropdown-list-image mr-3">
                                                                                <img className="rounded-circle" src={option.photo_url} alt="" />
                                                                            </div>
                                                                            <div className="font-weight-bold mr-3 notification-hrf">
                                                                                <a href="">
                                                                                    <div className="">{option.customer_name}</div>
                                                                                    <div className="small">{option.dropoff_service_name}</div>
                                                                                    <div className="small">{option.helping_service_name}</div>
                                                                                </a>
                                                                            </div>
                                                                            <span className="ml-auto mb-auto">
                                                                                {option.helper_accept_service_type==2?
                                                                                 <>
                                                                                <div className="text-right">Muscle</div>
                                                                                <div className="text-right price-helper">{setNUmberFormat(option.total_muscle_amount)}</div>
                                                                                 </>
                                                                                :''}
                                                                                {option.helper_accept_service_type==1?
                                                                                 <>
                                                                                <div className="text-right">Pro</div>
                                                                                <div className="text-right price-helper">{setNUmberFormat(option.total_pros_amount)}</div>
                                                                                 </>
                                                                                :''}
                                                                                {option.is_dropoff_address?
                                                                                <>
                                                                                <div className="small text-right">{setNUmberFormat(option.total_miles_distance)} Miles</div>
                                                                                </>
                                                                                :''}
                                                                            </span>
                                                                        </div>
                                                                        <div className="row no-gutters bg-light p-10all-side">
                                                                           {option.is_pickup_address?
                                                                            <div className="col-xl-6 col-lg-6 col-md-6 align-self-center">
                                                                                <i className="pr-1 maploc"><img src="/web/img/from_icon.png" alt=""/></i>
                                                                                <small>
                                                                                {option.is_dropoff_address? 'From' : 'Pickup Address' }
                                                                                </small>
                                                                                <br />
                                                                                <span className="maptxt">{option.pickup_address}</span>
                                                                            </div>
                                                                            : '' }
                                                                            {option.is_dropoff_address?
                                                                            <div className="col-xl-6 col-lg-6 col-md-6 align-self-center">
                                                                                <i className="pr-1 maploc"><img src="/web/img/to_location_icon.png" alt="" /></i><small>To</small>
                                                                                <br />
                                                                                <span className="maptxt">{option.dropoff_address}</span>
                                                                            </div>
                                                                            : '' }
                                                                        </div>
                                                                        <div className="row no-gutters bg-light p-10all-side pb-2">
                                                                            <div className="col-8 col-lg-9 align-self-center">
                                                                                <i className="far fa-calendar-alt pr-1 maploc-1"></i><small>Pickup Date/Time</small>
                                                                                <br />
                                                                                <span className="maptxt">{option.pickup_date}, {option.pickup_start_time} - {option.pickup_end_time}</span>
                                                                            </div>
                                                                            <div className="col-4 col-lg-3 align-self-center text-md-right mt-3 mt-md-0">
                                												<Link className="accpet-helpebtn" to={"/helper-move-detail/"+option.request_id} >View</Link>
                                											</div>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                                {dataArray.length == 0 && loading==false? <p className="nomoveimg"><img alt=""  src="web/img/no_moves_placeholder.png" /> <br/> No Complete Moves Yet!</p> : '' }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                                
                                                <section id="content4" className="mymovesbox">
                                                    <div className="notification-scroll" id="style-4"> 
                										 <div className="row">
                											<div className="col-12 col-md-12">
                                                                {dataArray.length > 0 && dataArray.map((option, index) => (
                                                                        <div className="d-block w-100 float-right mb-2"  key={index}>
                                                                            <div className="p-3 d-flex align-items-center bg-light border-bottom osahan-post-header">
                                                                                <div className="dropdown-list-image mr-3">
                                                                                    <img className="rounded-circle" src={option.photo_url} alt="" />
                                                                                </div>
                                                                                <div className="font-weight-bold mr-3 notification-hrf">
                                                                                    <a href="">
                                                                                        <div className="">{option.customer_name}</div>
                                                                                        <div className="small">{option.dropoff_service_name}</div>
                                                                                        <div className="small text-right">{option.helping_service_name}</div>
                                                                                    </a>
                                                                                </div>
                                                                                <span className="ml-auto mb-auto">
                                                                                     {option.is_dropoff_address?
                                                                                        <>
                                                                                        <div className="small text-right">{setNUmberFormat(option.total_miles_distance)} Miles</div>
                                                                                        </>
                                                                                        :''}
                                                                                </span>
                                                                            </div>
                                                                            <div className="row no-gutters bg-light p-10all-side">
                                                                               {option.is_pickup_address?
                                                                                <div className="col-xl-6 col-lg-6 col-md-6 align-self-center">
                                                                                    <i className="pr-1 maploc"><img src="/web/img/from_icon.png" alt=""/></i>
                                                                                    <small>
                                                                                    {option.is_dropoff_address? 'From' : 'Pickup Address' }
                                                                                    </small>
                                                                                    <br />
                                                                                    <span className="maptxt">{option.pickup_address}</span>
                                                                                </div>
                                                                                : '' }
                                                                                {option.is_dropoff_address?
                                                                                <div className="col-xl-6 col-lg-6 col-md-6 align-self-center">
                                                                                    <i className="pr-1 maploc"><img src="/web/img/to_location_icon.png" alt="" /></i><small>To</small>
                                                                                    <br />
                                                                                    <span className="maptxt">{option.dropoff_address}</span>
                                                                                </div>
                                                                                : '' }
                                                                            </div>
                                                                            <div className="row no-gutters bg-light p-10all-side pb-2">
                                                                                <div className="col-8 col-lg-9 align-self-center">
                                                                                    <i className="far fa-calendar-alt pr-1 maploc-1"></i><small>Pickup Date/Time</small>
                                                                                    <br />
                                                                                    <span className="maptxt">{option.pickup_date}, {option.pickup_start_time} - {option.pickup_end_time}</span>
                                                                                </div>
                                                                                <div className="col-4 col-lg-3 align-self-center text-md-right mt-3 mt-md-0">
                                    												<Link className="accpet-helpebtn" to={"/helper-move-detail/"+option.request_id} >View</Link>
                                    											</div>
                                                                            </div>
                                                                        </div>
                                                                ))}
                                                    	        {dataArray.length == 0 && loading==false? <p className="nomoveimg"><img alt=""  src="web/img/no_moves_placeholder.png" /> <br/> No Canceled Moves Yet!</p> : '' }
                                                    	     </div>
                                                        </div>
                                                    </div>
                                                </section>
            
                                            </div>
                                        </div>
                                        {dataArray && dataArray.length>0 ? 
                						    <div className="pagination-wrapper">
                          
                                                <PaginationComponent className="pagination_custom"
                                                  totalItems={pagesCount}
                                                  pageSize={20}
                                                  maxPaginationNumbers={3}
                                                  onSelect={(e) => getRecord(e) }
                                                />
                                            </div>
                                        : '' }
                                        
                                    </div>
                                </div> 
                            </div>
                        </div>
                    </article>
                </section>
            </>
            : '' }
            </main>
             <Footer loading={loading}></Footer>
        </>
    )
}
export default Moves;