
import React, {useState, useEffect} from "react";
import Header from './../layout/Header'; //Include Heder
import Footer from './../layout/Footer'; //Include Footer
import HelperSideBar from './../layout/HelperSideBar';
import ProfileSection from './profileSection';
import MetaHelmet from './../metaHelmet'; //Include metaHelmet
import Service from './../../service'; 
import toast, { } from 'react-hot-toast';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import { css } from "@emotion/react";
import Loader from "react-spinners/HashLoader";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: '#f0bac2';
`;

const api = new Service();

const ChangeService= props =>{   
    const navigate = useNavigate();
    const [helperProfile,sethelperProfile] = useState({});
    const [data, setdata] = useState({});
    const [loading, setloading] = useState(false);
    const [color] = useState('#f0bac2');
    const [jobStatusErrorMessage, setjobStatusErrorMessage] = useState('');
     useEffect(() => {
        GetHelperProfile();
    }, [])
       const GetHelperProfile = () => {
        setloading(true)
        api.getApiHelper('get-helper-info').then(response => {
            if(response && response.is_verified==0) {
                setloading(false);
                navigate('/helper-pending')
            } else {
                sethelperProfile(response);
                setdata(response);
                setloading(false);
            }
        }).catch(error => { 
            setloading(false);
            toast.error(error.message);
        });
    }
    
    
    const changeServiceType = (e,serviceType)  =>  { 
        toast.dismiss();
        setloading(true)
        api.postApiHelper('get-helper-job-status',{service_type:serviceType}).then(response => {
            if(response.message==true) { 
               
                if(serviceType!=2) {
                     setloading(false)
                     navigate('/helper-vehicle-info/'+serviceType)
                } else {
                    
                    api.postApiHelper('update-helper-service-type',{service_type:serviceType}).then(response => {
                        toast.success(response.message); 
                        GetHelperProfile();
                         setloading(false)
                    }).catch(error => {
                         setloading(false)
                         toast.error(error.message);
                    }); 
                }
            } else {
                setloading(false)
                setjobStatusErrorMessage("Currently you have some move in the queue, Please finish all, And Please try again.");
            }
        }).catch(error => {
             setloading(false)
             toast.error(error.message);
        });
        
    }
    return (
        <>
           
           <MetaHelmet title="Move It | Change Service" description="" /> 
          <Header helperProfile={helperProfile}/>
            <main>
            {helperProfile?.is_verified?
              <>
                <section className="coverphoto-helper p-0"></section>
                <ProfileSection helperProfile={helperProfile} />
            
                <section className="work white p40">
                    <article className="container">
                        <div className="row">
                            <HelperSideBar helperProfile={helperProfile}/>
            
                            <div className="col-md-8 col-lg-9">
                                <div className="row d-flex align-items-center justify-content-center" data-aos="fade-left" data-aos-delay="100">
                                    {data?.vehicle_info?.is_vehicle_request == 'pending' && data.vehicle_info.is_change_service == 1?
                                    <div className="col-12 col-md-12 col-lg-10">
                                       <p>{data.vehicle_info.message}</p>
                                    </div>
                                     : '' }
                                    {data?.vehicle_info?.is_vehicle_request == 'reject' && data.vehicle_info.is_change_service == 1?
                                    <div className="col-12 col-md-12 col-lg-10">
                                       <label>Request Rejected</label>
                                       <p>{data.vehicle_info.message}</p>
                                    </div>
                                     : '' }
                                    <div className="col-12 col-md-12 col-lg-10">
                                        <h3>Change Service</h3>
                                    </div>

                                    {jobStatusErrorMessage?
                                    <>
                                    <div className="col-12 col-md-12 col-lg-10">
                                        <div className="d-block w-100 mb-3  text-center"> 
											<div className="text-danger p-3 align-items-center bg-white border-1 osahan-post-header">
                                               {jobStatusErrorMessage}
											</div>
										</div>
                                    </div>
                                    </>
                                    :''}
                                    <div className="col-md-12 col-lg-10">
                                        <div className="contact-wrapper stepone-2">
                                            <form action="#" className="job-application-form login-page vechicleinfo row">
                                                
                                                <div className="col-6 col-sm-6 mb-2"> 
                                                    <input className="checkbox-tools" type="radio" name="tools" id="tool-2" value="2" checked={data?.service_type==2} readOnly/>
                                                    <label className="for-checkbox-tools" htmlFor="tool-2"  onClick={(e) => data?.service_type==2?
   '' : 
    changeServiceType(e,2) }>
                                                        <div className="cardbox">
                                                            <img src="web/img/service-1.png" alt="" />
                                                            <h5>Move It Muscle {data?.service_type==2? <span className="active-service">Current Service</span>: '' } </h5>  
                                                            <p>I don't have a pickup truck but can lift over 80 lbs to assist Move It Pros or jobs that need only muscle</p>
                                                        </div>
                                                    </label>
                                                </div>
                                                <div className="col-6 col-sm-6 mb-2" >
                                                    <input className="checkbox-tools" type="radio" name="tools" id="tool-3" value="3" checked={data?.service_type==3} readOnly />
                                                    <label className="for-checkbox-tools" htmlFor="tool-3" onClick={(e) => data?.service_type==3?
   '' : 
    changeServiceType(e,3) }>
                                                        <div className="cardbox">
                                                            <img src="web/img/service-9.jpg" alt=""/>
                                                            <h5>Move It Pro & Muscle {data?.service_type==3? <span className="active-service">Current Service</span>: '' } </h5>
                                                            <p>I have a pickup truck, cargo, box truck, or a vehicle with but can lift over 80 lbs to assist Move It Pros or jobs</p>
                                                        </div>
                                                    </label>
                                                </div>
            
                                               {/* <div className="col-md-12 col-lg-10 mt-3">
                                                    <div className="form-row">
                                                        <button className="full" type="submit"><span>SAVE CHANGES</span></button>
                                                    </div>
                                                </div>*/}
                                                
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>
                </section>
            </>
            :''}
            </main>
            <Footer/>
             <div className="sweet-loading custom-loader">
              <Loader color={color} loading={loading} css={override} size={150}  />
             </div>
        
        </>
    )
}
export default ChangeService;