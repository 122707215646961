import React, {useState, useCallback, Component, useEffect} from "react";
import { BrowserRouter as Router,Routes,Route,Link,NavLink,useNavigate} from 'react-router-dom';
import Header from './../layout/Header'; //Include Heder
import Footer from './../layout/Footer'; //Include Footer
import HelperSideBar from './../layout/HelperSideBar';
import Service from './../../service'; 
import {useFormik} from 'formik';
import toast, { Toaster } from 'react-hot-toast';

import * as yup from 'yup';
import ProfileSection from './profileSection';
import Loader from "react-spinners/HashLoader";
import moment from 'moment-timezone';
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';
import MetaHelmet from './../metaHelmet'; //Include metaHelmet


const api = new Service();
const Setting = () =>{
    
    
    
    const [helperProfile,sethelperProfile] = useState({});
    const [loading, setloading] = useState(false);
    const [startTimeList, setstartTimeList] = useState([]); 
    const [endTimeList, setendTimeList] = useState([]);
    const [start_time, setstartTime] = useState('');
    const [end_time, setendTime] = useState('');
    useEffect(() => {
             GetHelperProfile() 
    }, [])
    const GetHelperProfile = () => {
        setloading(true)
        api.getApiHelper('get-helper-info').then(response => {
            if(response && response.is_verified==0) {
                setloading(false);
                navigate('/helper-pending')
            } else {
                sethelperProfile(response);
                console.log(response.available_slots[0])
                if(response.available_slots && response.available_slots.length>0) {
                  setstartTime(response.available_slots[0].start_time)
                  setendTime(response.available_slots[0].end_time)
                //   getTimeList('06:00 AM','23:30 : PM','startTime')
                   getTimeList(response.available_slots[0].start_time,'23:30 PM','endTime')
                }
                setloading(false);
            }
        }).catch(error => { 
            setloading(false);
            toast.error(error.message);
        });
    }
    const navigate = useNavigate();
    const formik=useFormik({  
        initialValues:{
          start_time: start_time,
          end_time:end_time,
          range:helperProfile.range?helperProfile.range:0,
          data:helperProfile.notification_days?helperProfile.notification_days:[],
          is_available : helperProfile.is_available?helperProfile.is_available:0,
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            start_time : yup.string().required('Please select start time.'),
            end_time: yup.string().required('Please select end time.'),
        }),
        onSubmit:values=>{
            setloading(true);
            api.postApiHelper('update-setting-values',values).then(response => {
                setloading(false);
                toast.success(response.message); 
            }).catch(error => {
                setloading(false);
                toast.error(error.message);
            }); 
        }
    }); 
   
    const updateStartTime = (e) => {
        setstartTime(e.target.value)
        formik.setFieldValue('start_time',e.target.value)
        if(e.target.value == '00:00 AM' || e.target.value == '00:30 AM') {
            getTimeList(e.target.value,'01:00 AM','endTime',false)
        } else {
           getTimeList(e.target.value,'23:30 PM','endTime')
        }
    }
    
    const updateEndTime = (e) => {
        setendTime(e.target.value)
        formik.setFieldValue('end_time',e.target.value)
    }
    
    const getTimeList = (start_time,end_time,type='',setExtraValues=true) => {
        //Data
        let x = { 
          slotInterval: 30,
          openTime: start_time,
          closeTime: end_time
        };
        
        //Format the time
        let startTime = moment(x.openTime, "HH:mm");
        
        //Format the end time and the next day to it 
        let endTime = moment(x.closeTime, "HH:mm");
        
        if(type == 'endTime') {
            startTime.add(x.slotInterval, 'minutes'); 
        }
         console.log(startTime)
        //Times
        let allTimes = [];
        if(type == 'startTime') {
           allTimes.push({value :'', time:'Select Start Time'})
        } else if (type == 'endTime') {
           allTimes.push({value :'', time:'Select End Time'})
        }
        
        //Loop over the times - only pushes time with 30 minutes interval
        while (startTime <= endTime) {
          allTimes.push({value :startTime.format("HH:mm A"), label:startTime.format("hh:mm A")})
          
          //Add interval of 30 minutes
          startTime.add(x.slotInterval, 'minutes'); 
        }
        
            if(type == 'startTime') {
                allTimes.push({value : "00:00 AM", label :'12:00 AM'})
                allTimes.push({value : "00:30 AM", label:'12:30 AM'})
                setstartTimeList(allTimes);
            } else if (type == 'endTime') {
                if(setExtraValues) {
                    allTimes.push({value : "00:00 AM", label :'12:00 AM'})
                    allTimes.push({value : "00:30 AM", label:'12:30 AM'})
                    allTimes.push({value : "01:00 AM", label:'01:00 AM'})
                }
                setendTimeList(allTimes);
            }
        
       
    }
    
    const updateWeeksValues = (e,index) => {
        console.log(e.target.checked)
        if(e.target.checked) {
           formik.setFieldValue(`data.${index}.is_notification_on`,1)
        } else {
           formik.setFieldValue(`data.${index}.is_notification_on`,0)
        }
    } 
    
     const updateAvailable = (e) => {
        if(e.target.checked) {
           formik.setFieldValue('is_available',1) 
        } else {
          formik.setFieldValue('is_available',0) 
        }
    } 
    
    
    
    
    
    
    return (
        <>
        <Toaster position="top-right" reverseOrder={false} />
        <MetaHelmet title="Move It | Helper Setting" description="" /> 
            <Header helperProfile={helperProfile}/>
            <main>
                <section className="coverphoto-helper p-0"></section>
                <ProfileSection helperProfile={helperProfile}/>
            
                <section className="work white p40">
                    <article className="container">
                        <div className="row">
                            <HelperSideBar helperProfile={helperProfile}/>
                            <div className="col-md-8 col-lg-9">
    							<div className="row d-flex align-items-center justify-content-center" data-aos="fade-left" data-aos-delay="100">
    							<div className="col-12 col-md-12 col-lg-10">
    							
    							<div className="row d-flex align-items-center justify-content-center" data-aos="fade-left" data-aos-delay="100">
    								<div className="col-12 col-md-12 col-lg-12 mb-3">
    								<h3>Setting</h3>
    								</div>
    								<div className="col-12 col-md-12 col-lg-12 mb-3 helper-setting">
    								<div className="row d-flex align-items-center justify-content-center hp-seting" data-aos="fade-left" data-aos-delay="100">
    									<div className="col-9 col-sm-9 col-lg-10 text-left">  
    											<h5>Receive Notifications</h5>
    											<p>This will notify you when any move or delivery is nearby.</p>
    										</div>
    				
    										<div className="col-3 col-sm-3 col-lg-2">  
    											<div className="yesnobtn" style={{background:"unset"}}>
    											
    											
    											
    												<label className="switch">
    												    <input type="checkbox" onClick={(e) => updateAvailable(e) }  value="1" checked={formik.values.is_available} readOnly />
    												    <span className="slider-radio"></span>
    												</label> 
    											
    											</div>
    										</div>
    									</div>
    								</div>
    							</div>
		
    							<div className="row d-flex align-items-center justify-content-center helperdays-setting" data-aos="fade-left" data-aos-delay="100">
    									<div className="col-12 col-sm-12 col-lg-12 text-left">  
    										<h5 className="mb-0">Select Days</h5>
    		
    										<p className="mb-0">Select the days you want to work. You will be notified of available moves only on days you select.</p>
    									</div>
    									<div className="col-12 col-md-12 col-lg-12">
    									<div className="job-application-form login-page row check-helper mb-0">
    							
        										<div className="form-group check-group col-4 col-md-3 col-lg-3">
        												<div className="custom-checkbox">
        													<input type="checkbox" className="custom-control-input" id="customControlValidation11" checked={formik.values.data.length> 0  && formik.values.data[0].is_notification_on}  onChange={(e) => updateWeeksValues(e,0) } />
        													<label className="custom-control-label" htmlFor="customControlValidation11">Sunday</label>
        												</div>
        										</div>
        										<div className="form-group check-group col-4 col-md-3 col-lg-3">
        												<div className="custom-checkbox">
        													<input type="checkbox" className="custom-control-input" id="customControlValidation12" checked={formik.values.data.length> 0  &&  formik.values.data[1].is_notification_on}  onChange={(e) => updateWeeksValues(e,1) }  />
        													<label className="custom-control-label" htmlFor="customControlValidation12">Monday</label>
        												</div>
        										</div>
        										<div className="form-group check-group col-4 col-md-3 col-lg-3">
        												<div className="custom-checkbox">
        													<input type="checkbox" className="custom-control-input" id="customControlValidation13" checked={formik.values.data.length> 0  &&  formik.values.data[2].is_notification_on}  onChange={(e) => updateWeeksValues(e,2) }  />
        													<label className="custom-control-label" htmlFor="customControlValidation13">Tuesday</label>
        												</div>
        										</div>
        										<div className="form-group check-group col-4 col-md-3 col-lg-3">
        												<div className="custom-checkbox">
        													<input type="checkbox" className="custom-control-input" id="customControlValidation14" checked={formik.values.data.length> 0  &&  formik.values.data[3].is_notification_on}  onChange={(e) => updateWeeksValues(e,3) }  />
        													<label className="custom-control-label" htmlFor="customControlValidation14">Wednesday</label>
        												</div>
        										</div>
        										<div className="form-group check-group col-4 col-md-3 col-lg-3">
        												<div className="custom-checkbox">
        													<input type="checkbox" className="custom-control-input" id="customControlValidation15"  checked={formik.values.data.length> 0  &&  formik.values.data[4].is_notification_on}  onChange={(e) => updateWeeksValues(e,4) }  />
        													<label className="custom-control-label" htmlFor="customControlValidation15">Thursday</label>
        												</div>
        										</div>
        										<div className="form-group check-group col-4 col-md-3 col-lg-3">
        												<div className="custom-checkbox">
        													<input type="checkbox" className="custom-control-input" id="customControlValidation16"  checked={formik.values.data.length> 0  &&  formik.values.data[5].is_notification_on}  onChange={(e) => updateWeeksValues(e,5) }  />
        													<label className="custom-control-label" htmlFor="customControlValidation16">Friday</label>
        												</div>
        										</div>
        										<div className="form-group check-group col-4 col-md-3 col-lg-3">
        												<div className="custom-checkbox">
        													<input type="checkbox" className="custom-control-input" id="customControlValidation17" checked={formik.values.data.length> 0  &&  formik.values.data[6].is_notification_on}  onChange={(e) => updateWeeksValues(e,6) }  />
        													<label className="custom-control-label" htmlFor="customControlValidation17">Saturday</label>
        												</div>
        										</div>
    										</div>
    									</div> 
    								</div>
    		
    								<div className="row d-flex align-items-center justify-content-center" data-aos="fade-left" data-aos-delay="100">
    									<div className="col-12 col-sm-12 col-lg-12 text-left">  
    										<h5 className="mb-0">Set Availability</h5>
    										<p className="mb-0">You can add your available time slot. Work when you want to work.</p>
    									</div>
    									<div className="col-12 col-md-12 col-lg-12">
    										<div className="job-application-form login-page row check-helper">
    											<div className="form-row col-6 col-md-6 col-lg-6">
    												<div className="select-input">
    													<select className="select-text"  value={start_time} onChange={(e) => updateStartTime(e) }>
                                                           <option value=""></option>
                                                           <option value="06:00 AM">06:00 AM</option>
                                                           <option value="06:30 AM">06:30 AM</option>
                                                           <option value="07:00 AM">07:00 AM</option>
                                                           <option value="07:30 AM">07:30 AM</option>
                                                           <option value="08:00 AM">08:00 AM</option>
                                                           <option value="08:30 AM">08:30 AM</option>
                                                           <option value="09:00 AM">09:00 AM</option>
                                                           <option value="09:30 AM">09:30 AM</option>
                                                           <option value="10:00 AM">10:00 AM</option>
                                                           <option value="10:30 AM">10:30 AM</option>
                                                           <option value="11:00 AM">11:00 AM</option>
                                                           <option value="11:30 AM">11:30 AM</option
                                                           ><option value="12:00 PM">12:00 PM</option>
                                                           <option value="12:30 PM">12:30 PM</option>
                                                           <option value="13:00 PM">01:00 PM</option>
                                                           <option value="13:30 PM">01:30 PM</option>
                                                           <option value="14:00 PM">02:00 PM</option>
                                                           <option value="14:30 PM">02:30 PM</option>
                                                           <option value="15:00 PM">03:00 PM</option>
                                                           <option value="15:30 PM">03:30 PM</option>
                                                           <option value="16:00 PM">04:00 PM</option>
                                                           <option value="16:30 PM">04:30 PM</option>
                                                           <option value="17:00 PM">05:00 PM</option>
                                                           <option value="17:30 PM">05:30 PM</option>
                                                           <option value="18:00 PM">06:00 PM</option>
                                                           <option value="18:30 PM">06:30 PM</option>
                                                           <option value="19:00 PM">07:00 PM</option>
                                                           <option value="19:30 PM">07:30 PM</option>
                                                           <option value="20:00 PM">08:00 PM</option>
                                                           <option value="20:30 PM">08:30 PM</option>
                                                           <option value="21:00 PM">09:00 PM</option>
                                                           <option value="21:30 PM">09:30 PM</option>
                                                           <option value="22:00 PM">10:00 PM</option>
                                                           <option value="22:30 PM">10:30 PM</option>
                                                           <option value="23:00 PM">11:00 PM</option>
                                                           <option value="23:30 PM">11:30 PM</option>
                                                           <option value="00:00 AM">12:00 AM</option>
                                                           <option value="00:30 AM">12:30 AM</option>
    													</select>
    													<label className="select-label">Start Time</label>
    												</div>
    												  {formik.touched.start_time && formik.errors.start_time ? <div  className="col-12 col-md-12 col-lg-10 text-danger">{formik.errors.start_time}</div> : ''}
    											</div>
    											<div className="form-row col-6 col-md-6 col-lg-6">
    												<div className="select-input">
    													<select className="select-text"  value={end_time} onChange={(e) => updateEndTime(e) }>
    												        {endTimeList.length>0 && endTimeList.map((option, index) => (
                                                                <option   value={option.value} key={index} >{option.label}</option>
                                                            ))}
    													</select>
    													<label className="select-label">End Time</label>
    												</div>
    												  {formik.touched.end_time && formik.errors.end_time ? <div  className="col-12 col-md-12 col-lg-10 text-danger">{formik.errors.end_time}</div> : ''}
    											</div>  									
    										</div>
    									</div> 
    								</div>
    							
    						
    								<div className="row d-flex align-items-center justify-content-center" data-aos="fade-left" data-aos-delay="100">
    									<div className="col-12 col-sm-12 col-lg-12 text-left">  
    										<h5 className="mb-0">Distance</h5>
    										<p className="mb-0">Select you service area range</p>
    									</div>
    									<div className="col-12 col-md-12 col-lg-12">
    										<div className="job-application-form login-page row check-helper">
    											<div className="form-row col-md-12 col-lg-12">
            										<div className="d-block mb-2 w-100 float-left"> 
            											  <div className="output">
            												<output>{formik.values.range}</output><span>Miles</span>
            											  </div>
            											  <div className="range-container">
                											  <Slider
                											    min={helperProfile.min_range}
                                                                max={helperProfile.max_range}
                                                                value={formik.values.range}
                                                                onChange={(e) => formik.setFieldValue('range',e)}
                                                              />
                                                         </div>
            											  <div className="output">
            												<output>100</output><span>Miles</span>
            											  </div>
            									    </div>
            									</div>
    										
    											  
    											
    											<div className="col-md-12 col-lg-12 mt-3">
    												<div className="form-row">
    													<button className="full" type="button" onClick={() =>formik.handleSubmit()}><span>SAVE CHANGES</span></button>
    												</div>
    											</div>
    											
    										</div>
    									</div> 
    								</div> 
    
    							</div>				
    						</div>
    						
    						</div>
                            
                        </div>
                    </article>
                </section>
            </main>
            <Footer loading={loading}/>
        </>
    )
    
}

export default Setting;