import React, { useState, useEffect } from "react";
import Header from './../layout/Header'; //Include Heder
import Footer from './../layout/Footer'; //Include Footer
import ProfileSection from './profileSection';
import HelperSideBar from './../layout/HelperSideBar';
import { useNavigate } from 'react-router-dom';
import Service from './../../service';
import toast, { Toaster } from 'react-hot-toast';
import MetaHelmet from './../metaHelmet'; //Include metaHelmet

const api = new Service();
const PendingW9Form = (props) => {
    const navigate = useNavigate();
    const [helperProfile, sethelperProfile] = useState({});
    const [loading, setloading] = useState(false);
    const [step, setstep] = useState(0);
    const [rejectMessage, setrejectMessage] = useState('');


    useEffect(() => {
        GethelperProfile();
    }, [])
    const GethelperProfile = () => {
        setloading(true)
        api.getApiHelper('get-helper-info').then(response => {
            if (response && response.is_verified == 0) {
                setloading(false);
                navigate('/helper-pending')
            } else {
                sethelperProfile(response);
                if (response.w9_form_verified == 0) {
                    setstep(1)
                } else if (response.w9_form_verified == 2) {
                    api.getApiHelper('get-helper-w9-form-detail').then(response => {
                        if (response.is_verified == 2) {
                            setrejectMessage(response.message)
                        }
                    }).catch(error => {
                        setloading(false);
                        toast.error(error.message);
                    });
                    setstep(2)
                } else if (response.w9_form_verified == 1) {
                    navigate('/helper-profile')
                }
                setloading(false);
            }
        }).catch(error => {
            setloading(false);
            toast.error(error.message);
        });
    }

    const reSubmit = () => {

    }
    return (
        <>
            <Header helperProfile={helperProfile} />
            <MetaHelmet title="Move It | Helper Pending W9-Form" description="" />
            <main>
                {helperProfile.is_verified ?
                    <>
                        <section className="coverphoto-helper p-0"></section>
                        <ProfileSection helperProfile={helperProfile} />
                        <section className="work white p40">
                            <article className="container">
                                <div className="row d-flex justify-content-center">
                                    <HelperSideBar helperProfile={helperProfile} />
                                    <div className="col-lg-8">
                                        <div className="form-steps" style={{ display: "block" }}>
                                            <div className="form-card-verifycation">
                                                <div className="col-12 col-sm-12 mb-5">
                                                    {step == 1 ?
                                                        <>

                                                            <img alt="" src="web/img/pending_verification_icon.png" className="imgpnd" />
                                                            <h2 className="text-center">Pending W-9 form verification</h2>
                                                            <h4 className="text-center">Your W-9 form verification process is under review by admin. It will take 3-7 days to verify your details.</h4>
                                                            <h4 className="text-center">You will be notified by the Move It admin via email and/or app notification</h4>
                                                        </>
                                                        : ''}
                                                    {step == 2 ?
                                                        <>
                                                            <img alt="" src="web/img/pending_verification_icon.png" className="imgpnd" />

                                                            <h2 className="text-center">W-9 form Rejected</h2>

                                                            <h5 className="text-center">{rejectMessage}</h5>
                                                            <div className="col-sm-12 col-lg-12 text-center">
                                                                <button className="full" type="button" onClick={() => reSubmit()} >RE-SUBMIT</button>
                                                            </div>
                                                        </>
                                                        : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </article>
                        </section>
                    </>
                    : ''}
            </main>
            <Footer loading={loading} />
        </>
    )
}

export default PendingW9Form;