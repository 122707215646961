import React, {useState, useEffect} from "react";
import Header from './../layout/Header'; //Include Heder
import Footer from './../layout/Footer'; //Include Footer
import HelperSideBar from './../layout/HelperSideBar';
import ProfileSection from './profileSection';
import MetaHelmet from './../metaHelmet'; //Include metaHelmet

import Service from './../../service'; 
import toast, { Toaster } from 'react-hot-toast';
import 'bootstrap/dist/css/bootstrap.min.css';
import {useFormik} from 'formik';

import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
const api = new Service();

const PaymentInfo=()=>{
    const [helperProfile,sethelperProfile] = useState({});
    const [loading, setloading] = useState(false);
    
    useEffect(() => {
        GetHelperProfile();
    }, [])
    const GetHelperProfile = () => {
        setloading(true)
        api.getApiHelper('get-helper-info').then(response => {
            if(response && response.is_verified==0) {
                setloading(false);
                navigate('/helper-pending')
            } else {
                setloading(false);
                sethelperProfile(response);
                formik.setFieldValue('paypal_id',response.paypal_merchant_id);
            }
        }).catch(error => { 
            setloading(false);
            toast.error(error.message);
        });
    }
    
    const navigate = useNavigate();
    const formik=useFormik({  
        initialValues:{
          paypal_id:""
        },
        validationSchema: yup.object({
            paypal_id: yup.string().required('Please enter your email id.')
        }),
        onSubmit:values=>{
            setloading(true)
            api.postApiHelper('save-helper-paypal-id',values).then(response => {
                toast.success("Payment method has been saved successfully."); 
                GetHelperProfile();
                setloading(false)
            }).catch(error => {
                setloading(false)
                 toast.error(error.message);
                 
            }); 
        }
    }); 
    
   
    return (
        <>
        <Toaster position="top-right" reverseOrder={false} />
        <MetaHelmet title="Move It | Helper Payment Info" description="" /> 
            <Header helperProfile={helperProfile}/>
            <main>
             {helperProfile.is_verified?
               <>
                <section className="coverphoto-helper p-0"></section>
                <ProfileSection helperProfile={helperProfile} />
            
                <section className="work white p40">
                    <article className="container">
                        <div className="row">
                            <HelperSideBar helperProfile={helperProfile} />
            
                            <div className="col-md-8 col-lg-9">
                                <div className="row d-flex align-items-center justify-content-center" data-aos="fade-left" data-aos-delay="100">
                                    <div className="col-12 col-md-12 col-lg-10">
                                        <h3>Payment Info</h3>
                                    </div>
                                    <div className="col-md-12 col-lg-10">
                                        <div className="contact-wrapper">
                                            <form action="#" className="job-application-form login-page vechicleinfo row" onSubmit={formik.handleSubmit}>
                                                <div className="col-md-12">
                                                    <p>Move It uses Zelle to directly deposit your weekly earning. Add you Zelle email below to receive your earning in your Zelle Account.</p>
                                                </div>
                                                <div className="form-row col-md-6 col-lg-6">
                                                    <input type="text" placeholder="Zelle Email Address / Mobile Number" name="paypal_id" {...formik.getFieldProps("paypal_id")} />
                                                    <span>Add Payment Info</span>
                                                    {formik.touched.paypal_id && formik.errors.paypal_id ? <div  className="text-danger">{formik.errors.paypal_id}</div> : ''}
                                                </div>
                                                <div className="col-md-12 col-lg-10 mt-3">
                                                    <div className="form-row">
                                                        <button className="full" type="submit"><span>ADD PAYMENT</span></button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>
                </section>
            </>
            : '' }
            </main>
             <Footer loading={loading}/>
        </>
    )
}
export default PaymentInfo;