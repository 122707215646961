import React, {useState, useCallback, Component, useEffect} from "react";
import { BrowserRouter as Router,Routes,Route,Link,NavLink ,useParams} from 'react-router-dom';
import Header from './../layout/Header'; //Include Heder
import Footer from './../layout/Footer'; //Include Footer
import HelperSideBar from './../layout/HelperSideBar';
import ProfileSection from './profileSection';
import MetaHelmet from './../metaHelmet'; //Include metaHelmet

import Service from './../../service'; 
import toast, { Toaster } from 'react-hot-toast';
import 'bootstrap/dist/css/bootstrap.min.css';
import {useFormik} from 'formik';

import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap-button-loader';
import { css } from "@emotion/react";
import Loader from "react-spinners/HashLoader";
const override = css`
  display: block;
  margin: 0 auto;
  border-color: '#f0bac2';
`;


const api = new Service(); 
const VehicleInfo= props =>{
    
    const [helperProfile,sethelperProfile] = useState({});
    const [loading, setloading] = useState(false);
    const { service_type } = useParams();
    const [color, setcolor] = useState('#f0bac2');
    const [formEditable, setformEditable] = useState(true);

    let spinColor = '#fff';
    let variant = '';
    useEffect(() => {
        GetHelperProfile();
    }, [])
    const GetHelperProfile = () => {
        setloading(true)
        api.getApiHelper('get-helper-info').then(response => {
            if(response && response.is_verified==0) {
                setloading(false);
                navigate('/helper-pending')
            } else {
                sethelperProfile(response);
                GetHelperInfo();
            }
        }).catch(error => { 
            setloading(false);
            toast.error(error.message);
        });
        //  service_type != undefined && service_type> 0 ? setformEditable(true) : setformEditable(false)
    }
    
    const GetHelperInfo = () => {
        api.getApiHelper('get-helper-info').then(response => {
            formik.setFieldValue('vehicle_type_id',response.vehicle_info.vehicle_type_id);
            formik.setFieldValue('vehicle_year',response.vehicle_info.vehicle_year);
            formik.setFieldValue('plate_num',response.vehicle_info.plate_num);
            formik.setFieldValue('company_name',response.vehicle_info.company_name);
            formik.setFieldValue('model',response.vehicle_info.model);
            formik.setFieldValue('insurance_number',response.vehicle_info.insurance_number);
            formik.setFieldValue('insurance_policy_expiry',response.vehicle_info.insurance_policy_expiry);
            if(response.vehicle_info.vehicle_photo_url.length>0) {
               let vehicle_photo = response.vehicle_info.vehicle_photo_url.map((element) => element.photo_url )
               formik.setFieldValue('vehicle_photo',vehicle_photo);
            }
            if(response.vehicle_info.vehicle_insurance_photo_url.length>0) {
               let insurance_photo = response.vehicle_info.vehicle_insurance_photo_url.map((element) => element.photo_url )
               formik.setFieldValue('insurance_photo',insurance_photo);
            }
            setloading(false);
        }).catch(error => {
             toast.error(error.message);
        });
    }
    const navigate = useNavigate();
    const formik=useFormik({ 
        initialValues:{
          vehicle_type_id:"",
          vehicle_year:'',
          plate_num:"",
          company_name:'',
          model:"",
          insurance_number:'',
          insurance_policy_expiry:"",
          insurance_photo : [],
          vehicle_photo : [],
          service_type : service_type != undefined && service_type> 0 ? service_type : 0
        },
        validationSchema: yup.object({
          vehicle_type_id:yup.string().required('Please select vehicle type '),
          vehicle_year:yup.number().typeError('you must specify a number').required('Please select vehicle year'),
          plate_num:yup.string().required('Please enter vehicle plate number '),
          company_name:yup.string().required('Please enter vehicle company name '),
          model:yup.string().required('Please enter vehicle model number '),
          insurance_number:yup.string().required('Please enter vehicle insurance number '),
          insurance_policy_expiry:yup.string().required('Please select vehicle insurance expiry date '),
          insurance_photo :yup.array().min(1, 'Please click atleast one image of the insurance'),
          vehicle_photo : yup.array().min(4, 'Your need to upload your vehicle front, left side, right side and back side photos.')
        }),
        onSubmit:values=>{
            console.log(values)
            setloading(true);
            if(service_type!=undefined && service_type> 0) {
                api.postApiHelper('update-helper-service-type',values).then(response => {
                    setloading(false);
                    toast.success(response.message); 
                    
                    navigate('/helper-charge-service')
                }).catch(error => {
                    setloading(false);
                    toast.error(error.message);
                }); 
            } else {
                api.postApiHelper('update-helper-vehicle-info-request',values).then(response => {
                    setloading(false);
                    toast.success(response.message); 
                    GetHelperInfo();
                }).catch(error => {
                    setloading(false);
                     toast.error(error.message);
                }); 
            }
        }
    });
    
    const updateInsurancePhoto=(e,index)=> {
        console.log(e)
       let totalFiles = e.target.files.length
       if(totalFiles < 4) {
            setloading(true);
            var formData = new FormData();
            for (let i = 0; i < e.target.files.length; i++) {
              formData.append('image[]', e.target.files[i])
            }
            formData.append("folder_name",'helper_insurance_photo');
            api.postApi('upload-images',formData,true).then(response => { 
                formik.setFieldValue(`insurance_photo.${index}`,response.data[0]);  
                setloading(false);
            }).catch(error => {
                 setloading(false);
                 toast.error(error.message);
            });
        } 
    }
    
    const updateVehiclePhoto=(e,index)=> {
        console.log(e)
       let totalFiles = e.target.files.length
       if(totalFiles < 4) {
            setloading(true);
            var formData = new FormData();
            for (let i = 0; i < e.target.files.length; i++) {
              formData.append('image[]', e.target.files[i])
            }
            formData.append("folder_name",'vehicle_image');
            api.postApi('upload-images',formData,true).then(response => { 
                formik.setFieldValue(`vehicle_photo.${index}`,response.data[0]);
                 setloading(false);
            }).catch(error => {
                 setloading(false);
                 toast.error(error.message);
            });
        } 
    }
    
    return (
        <>
             <Header helperProfile={helperProfile}/>
             <MetaHelmet title="Move It | Helper Vehicle Info" description="" /> 

            <main>
             {helperProfile?.is_verified?
              <>
                <section className="coverphoto-helper p-0"></section>
                <ProfileSection helperProfile={helperProfile}/>
            
                <section className="work white p40">
                    <article className="container">
                        <div className="row">
                            <HelperSideBar helperProfile={helperProfile} />
               
                            <div className="col-md-8 col-lg-9">
                                
                            <div className="row d-flex align-items-center justify-content-center" data-aos="fade-left" data-aos-delay="100">
                                    <div className={formEditable?"col-12 col-md-12 col-lg-10":"col-6 col-md-6 col-lg-5"}>
            						 <h3>Vehicle Info</h3>
            						</div>
            						{service_type == undefined && formEditable==false?
            						<>
            						<div className="col-6 col-md-6 col-lg-5">
            						    <Link to=""  className="admoves"><i className="fas fa-truck-moving"></i> Edit Vehicle</Link>
            						</div>
                                    </>
                                    :''}
                                    
                                    <div className="col-md-12 col-lg-10">
                                        <div className="contact-wrapper">
                                                <form action="#" className="job-application-form login-page vechicleinfo row" >

                                                <div className="form-row col-md-6 col-lg-6">
                                                   <div className="select-input">
                                                       <select  className="select-text"  name="vehicle_type_id" {...formik.getFieldProps("vehicle_type_id")} readOnly={formEditable==false} >
                                                       <option value="" >Select Vehicle Type</option>
                                                       <option value="1">Pickup truck</option>
                                                       <option value="2">Cargo van</option>
                                                       <option value="3">Box truck</option> 
                                                       <option value="4">Vehicle w/a trailer</option>
                                                       </select>
                                                    <label className="select-label">Vehicle Type</label>
                                                    </div>
                                                     {formik.touched.vehicle_type_id && formik.errors.vehicle_type_id ? <div  className="text-danger">{formik.errors.vehicle_type_id}</div> : ''}
                                                </div>
                                                <div className="form-row col-md-6 col-lg-6">
                                                    <div className="select-input">
                                                     <select  className="select-text"  name="vehicle_year" {...formik.getFieldProps("vehicle_year")}  readOnly={formEditable==false} >
                                                     <option value="" >Select Vehicle Year</option>
                                                       <option value="1990" >1990</option>
                                                       <option value="1991">1991</option>
                                                       <option value="1992">1992</option>
                                                       <option value="1993">1993</option>
                                                       <option value="1994">1994</option>
                                                       <option value="1995">1995</option>
                                                       <option value="1996">1996</option>
                                                       <option value="1997">1997</option>
                                                       <option value="1998">1998</option>
                                                       <option value="1999">1999</option>
                                                       <option value="2000">2000</option>
                                                       <option value="2001">2001</option>
                                                       <option value="2002">2002</option>
                                                       <option value="2003">2003</option>
                                                       <option value="2004">2004</option>
                                                       <option value="2005">2005</option>
                                                       <option value="2006">2006</option>
                                                       <option value="2007">2007</option>
                                                       <option value="2008">2008</option>
                                                       <option value="2009">2009</option>
                                                       <option value="2010">2010</option>
                                                       <option value="2011">2011</option>
                                                       <option value="2012">2012</option>
                                                       <option value="2013">2013</option>
                                                       <option value="2014">2014</option>
                                                       <option value="2015">2015</option>
                                                       <option value="2016">2016</option>
                                                       <option value="2017">2017</option>
                                                       <option value="2018">2018</option>
                                                       <option value="2019">2019</option>
                                                       <option value="2020">2020</option>
                                                       <option value="2021">2021</option>
                                                       <option value="2022">2022</option>
                                                       </select>
                                                      <label className="select-label">Vehicle Year</label>
                                                    </div>
                                                     {formik.touched.vehicle_year && formik.errors.vehicle_year ? <div  className="text-danger">{formik.errors.vehicle_year}</div> : ''}
                                                </div>
                                                <div className="form-row col-md-6 col-lg-6">
                                                    <input  type="text"  name="plate_num" {...formik.getFieldProps("plate_num")}   readOnly={formEditable==false} />
                                                    <span>Vehicle Plate Number</span>
                                                     {formik.touched.plate_num && formik.errors.plate_num ? <div  className="text-danger">{formik.errors.plate_num}</div> : ''}
                                                </div>
                                                <div className="form-row col-md-6 col-lg-6">
                                                    <input  type="text"  name="company_name" {...formik.getFieldProps("company_name")}  readOnly={formEditable==false} />
                                                    <span>Vehicle Make</span>
                                                     {formik.touched.company_name && formik.errors.company_name ? <div  className="text-danger">{formik.errors.company_name}</div> : ''}
                                                </div>
                                                <div className="form-row col-md-6 col-lg-6">
                                                    <input  type="text"  name="model" {...formik.getFieldProps("model")} readOnly={formEditable==false} />
                                                    <span>Vehicle Model</span>
                                                     {formik.touched.model && formik.errors.model ? <div  className="text-danger">{formik.errors.model}</div> : ''}
                                                </div>
                                                <div className="form-row col-md-6 col-lg-6">
                                                    <input  type="text"  name="insurance_number" {...formik.getFieldProps("insurance_number")}  readOnly={formEditable==false} />
                                                    <span>Insurance Policy Number</span>
                                                     {formik.touched.insurance_number && formik.errors.insurance_number ? <div  className="text-danger">{formik.errors.insurance_number}</div> : ''}
                                                </div>
                                                <div className="form-row col-md-6 col-lg-6">
                                                    <input  type="date"  name="insurance_policy_expiry" {...formik.getFieldProps("insurance_policy_expiry")}  readOnly={formEditable==false} />
                                                    <span>Policy Expiry Date</span>
                                                     {formik.touched.insurance_policy_expiry && formik.errors.insurance_policy_expiry ? <div  className="text-danger">{formik.errors.insurance_policy_expiry}</div> : ''}
                                                </div>
                                            </form>
                                        </div>
                                    </div>
            
                                    <div className="col-12 col-md-12 col-lg-10">
                                        <h5>Insurance Photos</h5>
                                        {formik.values.insurance_photo.length>0 && formik.values.insurance_photo.map((option, index) => (
                                            <div className="vechicle-insurance-pic" key={index}>
                                                <div className="avatar-upload">
                                                    <div className="avatar-edit">
                                                        <input  type="file" id={'imageUpload_insurance'+index} accept=".png, .jpg, .jpeg" onChange={(e) => updateInsurancePhoto(e,index)} />
                                                        <label htmlFor={'imageUpload_insurance'+index}></label>
                                                    </div>
                                                    <div className="avatar-preview">
                                                        <div  style={{backgroundImage: "url("+option+")"}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        
                                        {formEditable && 3-formik.values.insurance_photo.length > 0 && Array.from(Array(3-formik.values.insurance_photo.length), (e, i) => {
                                    //   {[...Array(2-formik.values.insurance_photo.length)].map((e, i) => {
                                        return <div className="vechicle-insurance-pic" key={i}>
            								<div className="avatar-upload">
            									<div className="avatar-edit">
            										<input type='file' id={'insurenace_imageUpload_'+i} accept=".png, .jpg, .jpeg" onChange={(s) => updateInsurancePhoto(s,formik.values.insurance_photo.length)} />
            										<label htmlFor={'insurenace_imageUpload_'+i} ></label>
            									</div>
            									<div className="avatar-preview">
            										<div  style={{backgroundImage: "url(web/img/07.jpeg)" }}>
            										</div>
            									</div>
            								</div>	
            							</div>
                                       })}
                                       
                                    </div>
            
                                    {formik.touched.insurance_photo && formik.errors.insurance_photo ? <div  className="col-12 col-md-12 col-lg-10 text-danger">{formik.errors.insurance_photo}</div> : ''} 
                                    <div className="col-12 col-md-12 col-lg-10 mt-3">
                                        <h5>Vehicle Photos</h5>
                                        {formik.values.vehicle_photo.length>0 && formik.values.vehicle_photo.map((option, index) => (
                                            <div className="vechicle-insurance-pic" key={index}>
                                                <div className="avatar-upload">
                                                    <div className="avatar-edit">
                                                        <input  type="file" id={'imageUpload_vehicle'+index} accept=".png, .jpg, .jpeg" onChange={(e) => updateVehiclePhoto(e,index)} />
                                                        <label htmlFor={'imageUpload_vehicle'+index} ></label>
                                                    </div>
                                                    <div className="avatar-preview">
                                                        <div style={{backgroundImage: "url("+option+")"}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        
                                         {formEditable && 4-formik.values.vehicle_photo.length > 0 && Array.from(Array(4-formik.values.vehicle_photo.length), (e, i) => {
                                    //   {[...Array(2-formik.values.insurance_photo.length)].map((e, i) => {
                                        return <div className="vechicle-insurance-pic" key={i}>
            								<div className="avatar-upload">
            									<div className="avatar-edit">
            										<input type='file' id={'vehicle_photo_imageUpload_'+i} accept=".png, .jpg, .jpeg" onChange={(s) => updateVehiclePhoto(s,formik.values.vehicle_photo.length)} />
            										<label htmlFor={'vehicle_photo_imageUpload_'+i} ></label>
            									</div>
            									<div className="avatar-preview">
            										<div  style={{backgroundImage: "url(web/img/07.jpeg)" }}>
            										</div>
            									</div>
            								</div>	
            							</div>
                                       })}
                                    </div>
                                     {formik.touched.vehicle_photo && formik.errors.vehicle_photo ? <div  className="col-12 col-md-12 col-lg-10 text-danger">{formik.errors.vehicle_photo}</div> : ''} 
                                     {formEditable?
                                     <>
                                    <div className="col-md-12 col-lg-10 mt-3">
                                        <div className="form-row">
                                            <button className="full" type="button" onClick={() => formik.handleSubmit() }><span>SAVE CHANGES</span></button>
                                        </div>
                                    </div>
                                    </>
                                    : '' }
                                    
                                </div>
                                
                            </div>
                        </div>
                    </article>
                </section>
                </>
                : '' }
            </main>
            <Footer/>
             <div className="sweet-loading custom-loader">
              <Loader color={color} loading={loading} css={override} size={150}  />
             </div>
        </>
    )
}
export default VehicleInfo;