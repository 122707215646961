import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import Header from './layout/Header'; //Include Heder
import Footer from './layout/Footer'; //Include Footer
import Service from './../service';
import toast, { Toaster } from 'react-hot-toast';
import React, { useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import MetaHelmet from './metaHelmet'; //Include metaHelmet
import { useNavigate } from 'react-router-dom';
import parse from 'html-react-parser';

const google = window.google;
const api = new Service();
var map;
const CityDetail = (props) => {

	const location = useLocation();

	const isVegasPage = location.pathname === '/cities-las-vegas-nv';

	const [config, setconfig] = useState(props.config);
	const setCurrentLocation = (response) => {
		if ('geolocation' in navigator) {
			navigator.geolocation.getCurrentPosition((position) => {
				map = new google.maps.Map(document.getElementById("map_canvas"), {
					zoom: 7,
					center: { lat: parseFloat(response.latitude), lng: parseFloat(response.longitude) },
				});
				var latlng = new google.maps.LatLng(parseFloat(response.latitude), parseFloat(response.longitude));
				var start = new google.maps.Marker({
					position: { lat: parseFloat(response.latitude), lng: parseFloat(response.longitude) },
					map: map,
					title: response.name,
					draggable: false,
					// icon: icon,
					// animation: google.maps.Animation.DROP,
				});
				let infowindow = new google.maps.InfoWindow({
					content: response.name,
				});
				start.addListener("click", () => {
					infowindow.open({
						anchor: start,
						map: map,
						shouldFocus: false,
					});
				});
				var radius = 100;
				var sunCircle = {
					strokeColor: "#ea4335",
					strokeOpacity: 0.8,
					strokeWeight: 2,
					fillColor: "#ea4335",
					fillOpacity: 0.35,
					map: map,
					center: latlng,
					radius: (radius * 1609.3) // in meters
				};

				new google.maps.Circle(sunCircle);

				var latlngbounds = new google.maps.LatLngBounds();
				for (var i = 0; i < latlng.length; i++) {
					latlngbounds.extend(latlng[i]);
				}
				map.setCenter(latlngbounds.getCenter());
				map.fitBounds(latlngbounds);
			});
		}
	}

	useEffect(() => {
		GetCityDetail()

	}, [])
	const { city_name } = useParams();

	useEffect(() => {
		GetCityDetail()
	}, [city_name])
	//    console.log(city_name)
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [cityDetail, setcityDetail] = useState({});
	const [Active, setActive] = useState([]);
	const [Deactive, setDeactive] = useState([]);

	const GetCityDetail = () => {
		console.log(city_name)
		api.getApi('get-cities-detail', { name: 'cities-' + city_name }).then(response => {
			console.log(response.name)
			if (response && response.name && response.name != undefined) {
				setcityDetail(response)
				setCurrentLocation(response)
				getCityList();
			} else {
				navigate('/404')
			}

		}).catch(error => {
			toast.error(error.message);
		});
	}

	const getCityList = () => {
		api.getApi('location', {}).then(response => {
			if (response.data) {
				setActive(response.data.Active)
				setDeactive(response.data.Deactive)
			}
		}).catch(error => {
			toast.error(error.message);
		});
	}

	return (
		<>
			{cityDetail ?
				<MetaHelmet title={cityDetail.meta_title} description={cityDetail.meta_description} />
				: ''}
			<Toaster position="top-right" reverseOrder={false} />
			<Header></Header>
			<main>
				<section className="features-3 topimg-city" style={{ backgroundImage: "url(" + cityDetail.image + ")" }}>
					<div className="container">
						<div className="row">
							{cityDetail ?
								<div className="col-md-9">
									<div className="citiesbg-opcity" id="style-4">
										{cityDetail.seo_description ?
											<>
												{parse("" + cityDetail.seo_description + "")}
											</>
											: <>

												<h6>On demand moving, delivery & <br /> junk removal services in <b>& {cityDetail.name}</b></h6>
												<p className="addextra-text">Move It helpers in {cityDetail.name} are ready to help you with any store pick up and delivery, small move, junk removal, and more. </p>
												<p className="addextra-text">Same day furniture delivery, moving appliances, or just extra muscle to help with those heavy items, {cityDetail.name} movers and helpers are ready when you need. </p>
											</>}
									</div>
								</div>
								: ''}
						</div>
					</div>
					<div className="shapeimg" style={{ backgroundImage: "url(" + cityDetail.image + ")" }}></div>
				</section>

				<section className="">
					<article>
						<div className="grid grid-center margin-bottom-lg text-center">
							<div className="unit-2-1 unit-1-1-md">
								<h1 className="flourish text"><span className="flourish-square"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 150" width="150" height="150" preserveAspectRatio="xMidYMid meet" style={{ width: "100%", height: "100%", transform: "translate3d(0px, 0px, 0px)" }}><defs><clipPath id="__lottie_element_147"><rect width="150" height="150" x="0" y="0"></rect></clipPath></defs><g clipPath="url(#__lottie_element_147)"><g style={{ display: "block" }} transform="matrix(1,0,0,1,459,133.5)" opacity="1"><g opacity="1" transform="matrix(1,0,0,1,0,0)"><path strokeLinecap="butt" strokeLinejoin="miter" fillOpacity="0" strokeMiterlimit="4" stroke="rgb(255,149,162)" strokeOpacity="1" strokeWidth="10" d=" M-317,-65 C-317,-65 -317,-125 -317,-125 C-317,-125 -451,-125 -451,-125 C-451,-125 -451,8 -451,8 C-451,8 -394,8 -394,8"></path></g></g></g></svg></span>How Move It Works</h1>
							</div>
						</div>

						<div className="vc_row wpb_row vc_inner row cspt-steps-counter cspt-row-inner cspt-bg-color-yes cspt-bg-color-transparent cspt-zindex-zero">

							<div className="vc_column_container col-sm-6 col-md-4 col-lg-4 cspt-column-inner cspt-bg-color-yes cspt-bg-color-transparent cspt-zindex-zero">
								<div className="vc_column-inner "><div className="wpb_wrapper">
									<div className="cspt-ihbox cspt-ihbox-style-5">
										<div className="cspt-ihbox-icon">
											<div className="cspt-ihbox-icon-wrapper">
												<span className="numberstep">01</span>
												<small>Step</small>
											</div>
										</div>
										<div className="cspt-ihbox-contents">
											<div className="cspt-ihbox-heading"><h2 className="vc_custom_heading">Enter Your Details</h2></div>
											<div className="cspt-ihbox-content">Tell us when, where, and what you need delivered or moved</div>
										</div>
									</div>
								</div>
								</div>
							</div>


							<div className="vc_column_container col-sm-6 col-md-4 col-lg-4 cspt-column-inner cspt-bg-color-yes cspt-bg-color-transparent cspt-zindex-zero">
								<div className="vc_column-inner ">
									<div className="wpb_wrapper">
										<div className="cspt-ihbox cspt-ihbox-style-5 ">
											<div className="cspt-ihbox-icon">
												<div className="cspt-ihbox-icon-wrapper">
													<span className="numberstep">02</span>
													<small>Step</small>
												</div>
											</div>

											<div className="cspt-ihbox-contents">
												<div className="cspt-ihbox-heading"><h2 className="vc_custom_heading">Get Your Guaranteed Price</h2></div>
												<div className="cspt-ihbox-content">Select how many helpers you need and get an instant price quote.</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="vc_column_container col-sm-6 col-md-4 col-lg-4 cspt-column-inner cspt-bg-color-yes cspt-bg-color-transparent cspt-zindex-zero">
								<div className="vc_column-inner ">
									<div className="wpb_wrapper">
										<div className="cspt-ihbox cspt-ihbox-style-5 ">
											<div className="cspt-ihbox-icon">
												<div className="cspt-ihbox-icon-wrapper">
													<span className="numberstep">03</span>
													<small>Step</small>
												</div>
											</div>

											<div className="cspt-ihbox-contents">
												<div className="cspt-ihbox-heading"><h2 className="vc_custom_heading">Book Your Move It Pros and/or Muscles</h2></div>
												<div className="cspt-ihbox-content">Get connected to and scheduled with Move It Pros and Muscles right away.</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>


					</article>
				</section>




				<div id="map_canvas" style={{ width: "100%", height: map ? "400px" : '' }}></div>



				<section className="pb-lg-9 pb-7 pt-7 nabkimg-point">
					<div className="container">
						<div className="grid grid-center margin-bottom-lg text-center">
							<div className="unit-2-1 unit-1-1-md">
								<h3 className="flourish text"><span className="flourish-square"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 150" width="150" height="150" preserveAspectRatio="xMidYMid meet" style={{ width: "100%", height: "100%", transform: "translate3d(0px, 0px, 0px)" }}><defs><clipPath id="__lottie_element_147"><rect width="150" height="150" x="0" y="0"></rect></clipPath></defs><g clipPath="url(#__lottie_element_147)"><g style={{ display: "block" }} transform="matrix(1,0,0,1,459,133.5)" opacity="1"><g opacity="1" transform="matrix(1,0,0,1,0,0)"><path strokeLinecap="butt" strokeLinejoin="miter" fillOpacity="0" strokeMiterlimit="4" stroke="rgb(255,149,162)" strokeOpacity="1" strokeWidth="10" d=" M-317,-65 C-317,-65 -317,-125 -317,-125 C-317,-125 -451,-125 -451,-125 C-451,-125 -451,8 -451,8 C-451,8 -394,8 -394,8"></path></g></g></g></svg></span>Local Partners</h3>
							</div>
						</div>


						<div className="row">
							<div className="col-6 col-md-3">
								<img src="/web/img/citydetail/p-logo-1.png" alt="" className="img-width-partner" />
							</div>
							<div className="col-6 col-md-3">
								<img src="/web/img/citydetail/p-logo-2.jpeg" alt="" className="img-width-partner" />
							</div>
							<div className="col-6 col-md-3">
								<img src="/web/img/citydetail/p-logo-3.jpg" alt="" className="img-width-partner" />
							</div>
							<div className="col-6 col-md-3">
								<img src="/web/img/citydetail/p-logo-4.png" alt="" className="img-width-partner" />
							</div>
							<div className="col-6 col-md-3">
								<img src="/web/img/citydetail/p-logo-5.png" alt="" className="img-width-partner" />
							</div>
							<div className="col-6 col-md-3">
								<img src="/web/img/citydetail/p-logo-6.png" alt="" className="img-width-partner" />
							</div>

							<div className="col-6 col-md-3">
								<img src="/web/img/citydetail/p-logo-7.png" alt="" className="img-width-partner" />
							</div>
							<div className="col-6 col-md-3">
								<img src="/web/img/citydetail/p-logo-8.jpeg" alt="" className="img-width-partner" />
							</div>
							<div className="col-6 col-md-3">
								<img src="/web/img/citydetail/p-logo-9.png" alt="" className="img-width-partner" />
							</div>
							<div className="col-6 col-md-3">
								<img src="/web/img/citydetail/p-logo-10.jpeg" alt="" className="img-width-partner" />
							</div>
							<div className="col-6 col-md-3">
								<img src="/web/img/citydetail/p-logo-11.png" alt="" className="img-width-partner" />
							</div>
							<div className="col-6 col-md-3">
								<img src="/web/img/citydetail/p-logo-12.svg" alt="" className="img-width-partner" />
							</div>
						</div>

					</div>
				</section>


				<section className="slate cspt-steps-counter">
					<article>
						<div className="grid-center margin-bottom-sm text-center">
							<div className="unit-2-1 unit-1-1-md mb-5">
								<h3 className="flourish text text-left whychoosepage"><span className="flourish-square z-dx1"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 150" width="150" height="150" preserveAspectRatio="xMidYMid meet" style={{ width: "100%", height: "100%", transform: "translate3d(0px, 0px, 0px)" }}><defs><clipPath id="__lottie_element_147"><rect width="150" height="150" x="0" y="0"></rect></clipPath></defs><g clipPath="url(#__lottie_element_147)"><g style={{ display: "block" }} transform="matrix(1,0,0,1,459,133.5)" opacity="1"><g opacity="1" transform="matrix(1,0,0,1,0,0)"><path strokeLinecap="butt" strokeLinejoin="miter" fillOpacity="0" strokeMiterlimit="4" stroke="rgb(255,149,162)" strokeOpacity="1" strokeWidth="10" d=" M-317,-65 C-317,-65 -317,-125 -317,-125 C-317,-125 -451,-125 -451,-125 C-451,-125 -451,8 -451,8 C-451,8 -394,8 -394,8"></path></g></g></g></svg></span> <b>Why Choose Move It </b> <br />
									<small>Why Move It is leading the way for small move & delivery service</small></h3>
							</div>
						</div>



						<div className="row mb-5">
							<div className="col-md-4 col-lg-4">
								<div className="themestek-ihbox-inner">
									<div className="themestek-ihbox-icon">
										<div className="why-imgicons"><img src="/web/img/delivery-time.png" alt="" /></div>
									</div>
									<div className="themestek-ihbox-contents">
										<div className="themestek-vc_general">
											<h5 className="themestek-custom-heading ">On Demand Scheduling</h5>
										</div>
										<div className="themestek-cta3-content-wrapper">Set a time that works for you. Whether it is right away or schedule in advance, Move It will be there for you.</div>
									</div>
								</div>
							</div>
							<div className="col-md-4 col-lg-4">
								<div className="themestek-ihbox-inner">
									<div className="themestek-ihbox-icon">
										<div className="why-imgicons"><img src="/web/img/bank.png" alt="" /></div>
									</div>
									<div className="themestek-ihbox-contents">
										<div className="themestek-vc_general">
											<h5 className="themestek-custom-heading ">Guaranteed Pricing</h5>
										</div>
										<div className="themestek-cta3-content-wrapper">Get a guaranteed quote when you book. Save up to 50-80% compared to traditional moving companies.</div>
									</div>
								</div>
							</div>
							<div className="col-md-4 col-lg-4">
								<div className="themestek-ihbox-inner">
									<div className="themestek-ihbox-icon">
										<div className="why-imgicons"><img src="/web/img/booking.png" alt="" /></div>
									</div>
									<div className="themestek-ihbox-contents">
										<div className="themestek-vc_general">
											<h5 className="themestek-custom-heading ">Quick and Easy</h5>
										</div>
										<div className="themestek-cta3-content-wrapper">Our in app and online technology makes booking very easy. Request a Move It Pro or Muscle in less than a minute.</div>
									</div>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col-md-4 col-lg-4">
								<div className="themestek-ihbox-inner">
									<div className="themestek-ihbox-icon">
										<div className="why-imgicons"><img src="/web/img/feedback.png" alt="" /></div>
									</div>
									<div className="themestek-ihbox-contents">
										<div className="themestek-vc_general">
											<h5 className="themestek-custom-heading ">Customer Reviewed</h5>
										</div>
										<div className="themestek-cta3-content-wrapper">All Move It Pros & Muscles are reviewed by customers just like you. You can review what others have rated before you accept.</div>
									</div>
								</div>
							</div>
							<div className="col-md-4 col-lg-4">
								<div className="themestek-ihbox-inner">
									<div className="themestek-ihbox-icon">
										<div className="why-imgicons"><img src="/web/img/protection.png" alt="" /></div>
									</div>
									<div className="themestek-ihbox-contents">
										<div className="themestek-vc_general">
											<h5 className="themestek-custom-heading ">You are Protected</h5>
										</div>
										<div className="themestek-cta3-content-wrapper">Every item delivered or moved is backed by our top of the line, limited commercial insurance policy.</div>
									</div>
								</div>
							</div>
							<div className="col-md-4 col-lg-4">
								<div className="themestek-ihbox-inner">
									<div className="themestek-ihbox-icon">
										<div className="why-imgicons"><img src="/web/img/award.png" alt="" /></div>
									</div>
									<div className="themestek-ihbox-contents">
										<div className="themestek-vc_general">
											<h5 className="themestek-custom-heading ">Superior Service</h5>
										</div>
										<div className="themestek-cta3-content-wrapper">Move It helpers have a commitment to excellence. Always striving for 100% customer satisfaction.</div>
									</div>
								</div>
							</div>
						</div>


					</article>
				</section>


				{isVegasPage && (
					<>
<section class="work white servicw-sliderbox paddFifty">
   <article>
      <div class="callout">
         <div data-aos="fade-left" data-aos-delay="100" class="aos-init aos-animate">
            <h2>Hire Movers in Las Vegas: Affordable Options for Every Move</h2>
            <p>Moving doesn't have to break the bank! Affordable movers in Las Vegas offer a variety of services to fit your budget and moving needs. Whether you are looking for apartment movers or office movers, we can meet every need. Our furniture movers are strong and careful and ensure your belongings arrive safely.</p>
			<p>Small moves in Las Vegas are our specialty, too. Get small movers ataffordable rates, perfect for dorm relocations or downsizing.</p>
			<p>So don't stress about the logistics of your next move. Hire movers in Las Vegas you can trust. Contact Move It today for a free quote!</p>
         </div>
      </div>
   </article>
</section>

<section class="work white servicw-sliderbox bgColorCities paddFifty">
   <article>
      <div class="calloutCities">
         <div data-aos="fade-left" data-aos-delay="100" class="aos-init aos-animate">
            <h2>Declutter and De-stress with Junk Removal in Las Vegas</h2>
            <p>Feeling overwhelmed by unwanted clutter? Move It can help! Whether you're renovating your home, tackling a spring cleaning project, or simply need to get rid of some old furniture, our junk removal servicein Las Vegas is here to assist you. We offer a same-day junk removal service for your convenience, so you won't have to wait around for your unwanted items to be hauled away.</p>
			<p>Our team of experienced and professional junk removers will efficiently and safely remove any unwanted items from your property. Call Move It today for affordable junk removal today and enjoy our experience and affordability.</p>
         </div>
      </div>
   </article>
</section>

<section class="work white servicw-sliderbox paddFifty ">
   <article>
      <div class="callout">
         <div data-aos="fade-left" data-aos-delay="100" class="aos-init aos-animate">
            <h2>Need to Move on Short Notice? Same Day Movers in Las Vegas  Can Help!</h2>
            <p>Life can be unpredictable, and sometimes, a last-minute move throws a wrench in your plans. Don't fret! Same-day movers in Las Vegas are here to save the day. We understand that unexpected situations arise, and our same-day moving service is designed to handle even the most time-sensitive relocations.</p>
			<p>Whether you need a same-day pickup and delivery for a small apartment or a full-service move across town, our flexible solutions fit your needs and budget, ensuring a stress-free moving experience. Contact us today for a free quote!</p>
         </div>
      </div>
   </article>
</section>

<section class="work white servicw-sliderbox bgColorCities paddFifty">
   <article>
      <div class="calloutCities">
         <div data-aos="fade-left" data-aos-delay="100" class="aos-init aos-animate">
            <h2>Don't Sweat the Heavy Lifting: Hire Movers in Las Vegas</h2>
            <p>Moving to a new place in Las Vegas can be exciting, but the physical act of moving can be daunting. Moving labor services can be your secret weapon for a smooth and stress-free relocation. These services provide experienced movers to assist with all the heavy lifting and loading/unloading of your belongings.</p>
			<p>Move It offers moving labor in Las Vegas to fit your needs and budget. With our help, you can focus on the excitement of your new home and leave the heavy lifting to the professionals.</p>
         </div>
      </div>
   </article>
</section>


<section class="work white servicw-sliderbox paddFifty">
   <article>
      <div class="callout">
         <div data-aos="fade-left" data-aos-delay="100" class="aos-init aos-animate">
            <h2>Looking for Reliable Commercial Movers in Las Vegas? We've Got You Covered!</h2>
            <p>Whether you're embarking on a new chapter in your life or your business is expanding, a smooth and stress-free move is essential. Move It provides exceptional residential moving services in Las Vegas that fit your specific needs. Our experienced team is dedicated to ensuring your relocation, big or small, is handled with the utmost care and efficiency.</p>
			<p>Moving your office or business requires a unique approach. Our expertise as a commercial moving company understands the importance of minimizing downtime for your business. We work efficiently to relocate your equipment, furniture, and files, ensuring a seamless transition to your new location.</p>
         </div>
      </div>
   </article>
</section>

<section class="work white servicw-sliderbox bgColorCities paddFifty">
   <article>
      <div class="calloutCities">
         <div data-aos="fade-left" data-aos-delay="100" class="aos-init aos-animate">
            <h2>Simplify Your Life with On-Demand Delivery in Las Vegas</h2>
            <p>Our on-demand delivery services in Las Vegas provide a convenient and efficient way to get what you need, right when you need it. Whether you forgot groceries, need furniture moved, or require important documents picked up, these services connect you with local drivers who can handle the task for you.</p>
			<p>The pickup and delivery service offered on-demand streamlines your errands and frees up your valuable time. Imagine the ease of requesting a pickup for online purchases or sending important documents across town. So ditch the stress and embrace the convenience –explore available options.</p>
         </div>
      </div>
   </article>
</section>
</>)}


				<section className="detail-cties-img">
					<article>
						<div className="grid-center margin-bottom-sm text-center">
							<div className="unit-2-1 unit-1-1-md mb-5">
								<h3 className="flourish text text-left whychoosepage"><span className="flourish-square z-dx1"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 150" width="150" height="150" preserveAspectRatio="xMidYMid meet" style={{ width: "100%", height: "100%", transform: "translate3d(0px, 0px, 0px)" }}><defs><clipPath id="__lottie_element_147"><rect width="150" height="150" x="0" y="0"></rect></clipPath></defs><g clipPath="url(#__lottie_element_147)"><g style={{ display: "block" }} transform="matrix(1,0,0,1,459,133.5)" opacity="1"><g opacity="1" transform="matrix(1,0,0,1,0,0)"><path strokeLinecap="butt" strokeLinejoin="miter" fillOpacity="0" strokeMiterlimit="4" stroke="rgb(255,149,162)" strokeOpacity="1" strokeWidth="10" d=" M-317,-65 C-317,-65 -317,-125 -317,-125 C-317,-125 -451,-125 -451,-125 C-451,-125 -451,8 -451,8 C-451,8 -394,8 -394,8"></path></g></g></g></svg></span> <b>Move It Cities </b></h3>
							</div>
						</div>

						<div className="row grid-center text-center justify-content-center">
							<div className="col-md-8 ">
								<div className="live__map_wrap">
									<Link to="" className="loc_map-mark" data-id="" title="Denver, CO" style={{ top: "40%", left: "32.5%" }}>
										<img src="web/img/google-maps.svg" data-src="web/img/google-maps.svg" alt="" />
										<div className='pulse'></div>
									</Link>
									<Link to="" className="loc_map-mark" data-id="" title="Las Vegas, NV" style={{ top: "49%", left: "13.5%" }}>
										<img src="web/img/google-maps.svg" data-src="web/img/google-maps.svg" alt="" />
										<div className='pulse'></div>
									</Link>
									<Link to="" className="loc_map-mark sandngo" data-id="" title="San Diego, CA" style={{ top: "57%", left: "7.9%" }}>
										<img src="web/img/google-maps.svg" data-src="web/img/google-maps.svg" alt="" />
										<div className='pulse'></div>
									</Link>
									<Link to="" className="loc_map-mark" data-id="" title="Tucson, AZ" style={{ top: "60%", left: "20%" }}>
										<img src="web/img/google-maps.svg" data-src="web/img/google-maps.svg" alt="" />
										<div className='pulse'></div>
									</Link>
									<Link to="" className="loc_map-mark" data-id="" title="Phoenix, AZ" style={{ top: "58%", left: "19.5%" }}>
										<img src="web/img/google-maps.svg" data-src="web/img/google-maps.svg" alt="" />
										<div className='pulse'></div>
									</Link>



									<Link to="" className="loc_map-mark" data-id="" title="Albuquerque" style={{ top: "47%", left: "25.5%" }}>
										<img src="web/img/map-pin1.svg" data-src="web/img/map-pin1.svg" alt="" style={{ width: "15px" }} />
									</Link>
									<Link to="" className="loc_map-mark" data-id="" title="Salt Lake City, UT" style={{ top: "26%", left: "23.5%" }}>
										<img src="web/img/map-pin1.svg" data-src="web/img/map-pin1.svg" alt="" style={{ width: "15px" }} />
									</Link>

									<Link to="" className="loc_map-mark" data-id="" title="Austin, TX" style={{ top: "70%", left: "47%" }}>
										<img src="web/img/map-pin1.svg" data-src="web/img/map-pin1.svg" alt="" style={{ width: "15px" }} />
									</Link>
									<Link to="" className="loc_map-mark" data-id="" title="Dallas, TX" style={{ top: "66%", left: "47%" }}>
										<img src="web/img/map-pin1.svg" data-src="web/img/map-pin1.svg" alt="" style={{ width: "15px" }} />
									</Link>
									<Link to="" className="loc_map-mark" data-id="" title="San Jose, CA" style={{ top: "38%", left: "1%" }}>
										<img src="web/img/map-pin1.svg" data-src="web/img/map-pin1.svg" alt="" style={{ width: "15px" }} />
									</Link>


									<Link to="" className="loc_map-mark" data-id="" title="Los Angeles, CA" style={{ top: "51%", left: "4.5%" }}>
										<img src="web/img/map-pin1.svg" data-src="web/img/map-pin1.svg" alt="" style={{ width: "15px" }} />
									</Link>
									<Link to="" className="loc_map-mark m-bile" data-id="" title="Seattle, WA" style={{ top: "8%", left: "7%" }}>
										<img src="web/img/map-pin1.svg" data-src="web/img/map-pin1.svg" alt="" style={{ width: "15px" }} />
									</Link>
									<Link to="" className="loc_map-mark" data-id="" title="Chicago, IL" style={{ top: "33%", left: "64.5%" }}>
										<img src="web/img/map-pin1.svg" data-src="web/img/map-pin1.svg" alt="" style={{ width: "15px" }} />
									</Link>
									<Link to="" className="loc_map-mark" data-id="" title="Houston, TX" style={{ top: "71.5%", left: "52.4%" }}>
										<img src="web/img/map-pin1.svg" data-src="web/img/map-pin1.svg" alt="" style={{ width: "15px" }} />
									</Link>

									<Link to="" className="loc_map-mark" data-id="" title="Atlanta, GA" style={{ top: "57%", left: "73.5%" }}>
										<img src="web/img/map-pin1.svg" data-src="web/img/map-pin1.svg" alt="" style={{ width: "15px" }} />
									</Link>
									<Link to="" className="loc_map-mark mia-bile" data-id="" title="Miami, FL" style={{ top: "81%", left: "84%" }}>
										<img src="web/img/map-pin1.svg" data-src="web/img/map-pin1.svg" alt="" style={{ width: "15px" }} />
									</Link>
									<Link to="" className="loc_map-mark" data-id="" title="San Francisco, CA" style={{ top: "36%", left: "0%" }}>
										<img src="web/img/map-pin1.svg" data-src="web/img/map-pin1.svg" alt="" style={{ width: "15px" }} />
									</Link>
									<Link to="" className="loc_map-mark" data-id="" title="Boston, MA" style={{ top: "23.5%", left: "93.5%" }}>
										<img src="web/img/map-pin1.svg" data-src="web/img/map-pin1.svg" alt="" style={{ width: "15px" }} />
									</Link>
									<Link to="" className="loc_map-mark" data-id="" title="New York, NY" style={{ top: " 28%", left: "89%" }}>
										<img src="web/img/map-pin1.svg" data-src="web/img/map-pin1.svg" alt="" style={{ width: "15px" }} />
									</Link>
									<Link to="" className="loc_map-mark" data-id="" title="Long Island, NY" style={{ top: " 28%", left: "94%" }}>
                                        <img src="web/img/map-pin1.svg" data-src="web/img/map-pin1.svg" alt="" style={{ width: "15px" }} />
                                    </Link>
									<Link to="" className="loc_map-mark" data-id="" title="Nashville, TN" style={{ top: "51.25%", left: "68%" }}>
										<img src="web/img/map-pin1.svg" data-src="web/img/map-pin1.svg" alt="" style={{ width: "15px" }} />
									</Link>
									<Link to="" className="loc_map-mark" data-id="" title="Dallas, TX" style={{ top: "66%", left: "47%" }}>
										<img src="web/img/map-pin1.svg" data-src="web/img/map-pin1.svg" alt="" style={{ width: "15px" }} />
									</Link>
									<Link to="" className="loc_map-mark" data-id="" title="Charlotte, NC" style={{ top: "52.5%", left: "80%" }}>
										<img src="web/img/map-pin1.svg" data-src="web/img/map-pin1.svg" alt="" style={{ width: "15px" }} />
									</Link>
									<Link to="" className="loc_map-mark" data-id="" title="Indianapolis, IN" style={{ top: "39.65%", left: "68%" }}>
										<img src="web/img/map-pin1.svg" data-src="web/img/map-pin1.svg" alt="" style={{ width: "15px" }} />
									</Link>
									<Link to="" className="loc_map-mark" data-id="" title="St. Louis, MO" style={{ top: "41.65%", left: "58%" }}>
										<img src="web/img/map-pin1.svg" data-src="web/img/map-pin1.svg" alt="" style={{ width: "15px" }} />
									</Link>
									<Link to="" className="loc_map-mark" data-id="" title="Orange County, CA" style={{ top: "52.5%", left: "6.5%" }}>
										<img src="web/img/map-pin1.svg" data-src="web/img/map-pin1.svg" alt="" style={{ width: "15px" }} />
									</Link>
									<Link to="" className="loc_map-mark" data-id="" title="Cleveland, OH" style={{ top: "33.5%", left: "75%" }}>
										<img src="web/img/map-pin1.svg" data-src="web/img/map-pin1.svg" alt="" style={{ width: "15px" }} />
									</Link>
									<Link to="" className="loc_map-mark" data-id="" title="Minneapolis, MN" style={{ top: "23.5%", left: "54.5%" }}>
										<img src="web/img/map-pin1.svg" data-src="web/img/map-pin1.svg" alt="" style={{ width: "15px" }} />
									</Link>
									<Link to="" className="loc_map-mark" data-id="" title="Philadelphia, PA" style={{ top: "34%", left: "87.2%" }}>
										<img src="web/img/map-pin1.svg" data-src="web/img/map-pin1.svg" alt="" style={{ width: "15px" }} />
									</Link>
									<Link to="" className="loc_map-mark" data-id="" title="Kansas City, MO" style={{ top: "40.65%", left: "50%" }}>
										<img src="web/img/map-pin1.svg" data-src="web/img/map-pin1.svg" alt="" style={{ width: "15px" }} />
									</Link>
									<Link to="" className="loc_map-mark" data-id="" title="Boise, ID" style={{ top: "19%", left: "13%" }}>
										<img src="web/img/map-pin1.svg" data-src="web/img/map-pin1.svg" alt="" style={{ width: "15px" }} />
									</Link>

									<Link to="" className="loc_map-mark" data-id="" title="Little Rock, AR" style={{ top: "51.65%", left: "53%" }}>
										<img src="web/img/map-pin1.svg" data-src="web/img/map-pin1.svg" alt="" style={{ width: "15px" }} />
									</Link>
									<img className="map_img" src="web/img/old-moveit-map.svg" data-src="web/img/old-moveit-map.svg" alt="" />
								</div>

							</div>
						</div>


						<div className="row">

							<div className="col-md-12">
								<div className="servingbox">
									<img src="web/img/google-maps.svg" alt="" /> <span className="f-26"> Available Now </span>
									<ul className="citylist nobg">
										{Active.length > 0 && Active.map((option, index) => (
											<li key={index}>
												{config.PRODUCTION_MODE ?
													<>
														<Link to={option.location_name_alias}>{option.name}</Link>
													</>
													: option.name}
											</li>
										))}

									</ul>
								</div>
							</div>


							<div className="col-md-12">
								<div className="servingbox ">
									<img src="web/img/map-pin1.svg" alt="" /> <span className="f-26"> Coming Soon </span>
									<ul className="citylist nobg">
									{Deactive.length > 0 && Deactive.map((option, index) => (
                                        <li key={index}>
                                            {option.city_id === 38 && <div className="comingSoonBlink"></div>}
                                            <>
                                                {option.city_id === 38 ? (
                                                    <Link to={option.location_name_alias}>{option.name}</Link>
                                                ) : (
                                                    option.name
                                                )}
                                            </>
                                        </li>
                                    ))}
									</ul>
								</div>
							</div>

						</div>


					</article>
				</section>






			</main>
			<Footer loading={loading}></Footer>
		</>
	);
}
export default CityDetail;

