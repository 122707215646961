import React from 'react';
import { Link } from 'react-router-dom';


import Header from './layout/Header'; //Include Heder
import Footer from './layout/Footer'; //Include Footer
import MetaHelmet from './metaHelmet'; //Include metaHelmet


function termsCondition() {
    return (
        <>
         <MetaHelmet title="Terms & Conditions - Move It" description="These Terms & Condition apply to Your access and use of the mobile application, website and other online products and services of Move It." />
        <Header></Header>
            <main>
                <section className="hero clean tandc" style={{backgroundImage:`url("web/img/trms-condition.jpg")`}}>
                    <article>
                        <div className="grid">
                            <div className="unit-2-3 unit-1-1-md">
                                <h1 className="h2 f-46">Move It Terms & Conditions</h1>
                            </div>
                        </div>
                    </article>
                </section>

                <section className="slate term-page-content">
                    <div className="container">
                        <div className="row">
                    
                          <div className="col-md-12 mb-5">
                    
                              <h2>  MOVE IT TERMS & CONDITIONS </h2>
                    
                              <p>Welcome to Move It, LLC, the technology platform that connects You (“User”, “You” with vehicle owners (each, an "Independent Vehicle Owner") who will pick up Your stuff and move it wherever You want.</p>
                              <p>These Terms & Condition ("Terms") (apply to Your access and use of the mobile application (the "App"), website ("Site") and other online products and services (collectively, the "Move It Services") of Move It.</p>
                    
                              <h3><ins>Accepting these Terms</ins></h3>
                    		  
                              <p>BY DOWNLOADING THE APP OR OTHERWISE ACCESSING OR USING THE MOVE IT SERVICES, YOU AGREE TO BE BOUND BY ALL OF THE TERMS BELOW. Please read all of the terms before You use the Move It Services. If a term does not make sense to You, please let us know. If You don't agree to all of the terms below, You may not use the Move It Services.</p>
                    
                              <h4 >Modifications:</h4>
                    		  
                              <p>We reserve the right to change these Terms from time to time. For example, we may need to change these Terms if we come out with a new feature. If we make changes, we will notify You by revising the date at the top of the policy and, in some cases, we will provide You with additional notice (such as adding a statement to our homepage or sending You an email notification). We encourage You to review these Terms periodically to stay informed about our practices.</p>
                              <p>You understand and agree that Move It may change these Terms at any time without prior notice; provided that Move It will endeavor to provide You with prior notice of any material changes. You may read a current, effective copy of these Terms at any time by selecting the appropriate link on the Site. The revised Terms will become effective at the time of posting. Any use of the Move It Services after such date will constitute Your acceptance of such revised terms and conditions. If any change to these Terms is not acceptable to You, then Your sole remedy is to stop accessing, browsing and otherwise using the Move It Services. The terms of these Terms will govern any updates Move It provides to You that replace and/or supplement any portion of the Move It Services, unless the upgrade is accompanied by a separate license or revised End User License Agreement (“EULA”), in which case the terms of that license or revised EULA will govern. Notwithstanding the preceding sentences of this Section no revisions to these Terms will apply to any dispute between You and Move It that arose prior to the effective date of such revision.</p>
                    
                              <h3>Use of the Move It Services</h3>
                              <p>MOVE IT DOES NOT PROVIDE TRANSPORTATION OR SERVICES. WE ARE NOT A TRANSPORTATION CARRIER OR A MOVING OR HAULING COMPANY OF HOUSEHOLD GOODS, FURNISHING OR OF FREIGHT CARRIER. IT IS UP TO USERS AND DELIVERY PROFESSIONALS TO OFFER AND PROVIDE THE PICK-UP, CARRY, AND DELIVERY SERVICES THAT MAY BE SCHEDULED THROUGH USE OF THE MOVE IT SERVICES. MOVE IT OFFERS INFORMATION AND A PLATFORM TO FACILITATE ARRANGEMENT OF PICK-UP, CARRY, AND DELIVERY SERVICES, BUT DOES NOT, AND DOES NOT INTEND TO, PROVIDE TRANSPORTATION SERVICES OR SERVICES OR ACT IN ANY WAY AS A TRANSPORTATION OR CARRIER OR A MOVING OR HAULING COMPANY OF HOUSEHOLD GOODS, FURNISHING OR OF FREIGHT CARRIER. WE HAVE NO RESPONSIBILITY OR LIABILITY FOR ANY PICK-UP, CARRY, OR DELIVERY SERVICES PROVIDED TO YOU THROUGH THE SERVICE.</p>
                              <p>Through the Move It Services, You can submit a request (a "Request") for an Independent Vehicle Owner to pick up the item(s) You specify (each, an "Item") for loading, unloading, moving, hauling, packing, lifting, assembly or disassembly (the "Services"). You will receive a price quote for the fee that will apply to completing Your Request (the "Services Fee"). Once You submit the payment information for the Request, Your Request will be sent out to our network of Independent Vehicle Owners. You will receive a notification through the Move It Services when an Independent Vehicle Owner has accepted Your Request, and You will be able to communicate with that Independent Vehicle Owner through the Move It Services to confirm and coordinate certain logistics involved with completing Your Request.</p>
                              <p>You represent and warrant that the Items related to any Request are Your personal property and You have all right, title and permission to request and allow the loading, unloading, moving, packing, or lifting of any Items, and that the performance of the Services by an Independent Vehicle Owner will not result in the violation of any third party's ownership or privacy rights.</p>
                    
                              <h4 >Registration:</h4>
                              <p>When You create an account You must maintain the security of Your password and accept all risk that someone may access Your account without Your permission. If You discover or suspect any Move It Services security breaches, please let us know as soon as possible. You represent and warrant to us that all information that You provide in connection with Your account is accurate, truthful, current and complete. Move It reserves the right to deny any account at our discretion.</p>
                    
                              <h4 >Requesting Services:</h4>
                              <p>In order to request  Services, you will have to specify certain information about the Services, which may include: the pick-up point, the destination, the items you are requesting to be lifted or moved (“Items”), contact information and the date and time of pick-up. Using the address you provide, Move It will use commercially reasonable efforts to connect you with an Independent Vehicle Owner to perform the Services for you and will provide them applicable details regarding the Services. If no Independent Vehicle Owners are available, Move It will notify you that there are no Independent Vehicle Owners available to perform the Services, at which point we will have no further obligation to attempt to connect you to an Independent Vehicle Owners for the applicable Services.</p>
                    
                              <h4 >Prohibited Items:</h4>
                              <p>YOU MAY NOT, UNDER ANY CIRCUMSTANCES, HAVE ANY OF THE FOLLOWING TRANSPORTED: hazardous materials, accounts, bills, debts, evidence of debt, letters of credit, passports, documents, railroad or other tickets, notes, money, securities, currency, bullion, precious stones, jewelry and/or other similar valuable articles, paintings, statuary and other works of art, manuscripts, mechanical drawings, live animals, tobacco, cigars, cigarettes, non-ferrous metal in scrap and/or ingot form, or furs and skins. </p>
                              <p>AN INDEPENDENT VEHICLE OWNER HAS THE RIGHT TO OPEN AND INSPECT ANY BOX AND MAY REFUSE TO PERFORM THE SERVICES FOR ANY REQUEST TO OR FROM ANY LOCATION, OR TO INTERCEPT, HOLD, OR RETURN ANY ITEMS, WHEN, AMONG OTHER REASONS, THE INDEPENDENT VEHICLE OWNER, IN HIS OR HER SOLE REASONABLE DISCRETION, DETERMINES THAT IT IS UNSAFE OR ECONOMICALLY OR OPERATIONALLY IMPRACTICABLE TO FULFILL THE REQUEST, OR THAT THE MOVING SERVICE IS BEING USED IN VIOLATION OF FEDERAL, STATE, OR LOCAL LAW, OR FOR FRAUDULENT PURPOSES. THE MOVE IT SERVICES ARE NOT INTENDED TO BE USED FOR THE DELIVERY OF ANY PROHIBITED GOODS AND MOVE IT WILL COOPERATE FULLY WITH ANY LAW ENFORCEMENT INVESTIGATION REGARDING ANY PROHIBITED GOODS DELIVERED THROUGH USE OF THE SERVICE.</p>
                    
                              <h4 >Payment for Services:</h4>
                              <p>You must provide credit card information to submit a Request. You are responsible for paying the applicable Services Fee for any completed Services. Once You submit a Request, we will place a temporary hold on the credit card account You provided in an amount equal to 100% of the applicable Services Fee. Once You and the Independent Vehicle Owner have verified that the Services are completed, You will have the option of paying an additional gratuity (a "Tip") to the Independent Vehicle Owner, and Your credit card account will be charged for the sum of the Services Fee, any Tip, and the fees for any additional services You may request.</p>
                              <p>The estimate for Services initially charged to Your card will be for the estimated number of hours and distance required to fulfill Your Request.  Upon arrival at Your initial destination or at the end of the Independent Vehicle Owner fulfilling the Request, if it is determined by the Independent Vehicle Owner that the Services will take more or less hours or more or less mileage than initially estimated the amount charged to Your card will be adjusted accordingly.  Alternatively, if You and the Independent Vehicle Owner negotiate a different Services Fee, when Move It is notified of the adjustment, Move It will adjust the payment accordingly.
                              </p>
                              <p>You agree to make all payments to the Independent Vehicle Owner through the Move It Services and will not pay any Independent Vehicle Owner in cash or means other than directly through the Move It Services.</p>
                    
                              <h4 >Canceling a Request:</h4>
                              <p>If You cancel any Request: (a) before an Independent Vehicle Owner accepts it, or (b) after it has been accepted by an Independent Vehicle Owner but more than 24 hours before the Services are to be performed, You will not be charged, and the hold will be lifted on Your credit card. If You cancel any Request after it has been accepted by an Independent Vehicle Owner and less than 24 hours before the Services are to be performed, Your credit card account will be charged 10% (a minimum of $10.00) of the applicable Services Fee.
                              </p>
                    
                              <h4 >Right to Use the Move It Services:</h4>
                              <p>On the condition that You fully comply with these Terms, Move It grants You a limited, nonexclusive, non-transferable and revocable license to access and use the Move It Services for Your own personal, non-commercial use. However, the App may only be used on mobile devices that You own or control. The terms of this license will also govern any upgrades provided by Move It that replace and/or supplement the original App, unless the upgrade is accompanied by a separate license, in which case the terms of that license will govern.</p>
                              <p>Except as expressly authorized by these Terms, You may not (a) modify, disclose, alter, translate or create derivative works of the Move It Services, (b) license, sublicense, resell, distribute, lease, rent, lend, transfer, assign or otherwise dispose of the Move It Services, (c) disassemble, decompile or reverse engineer any of the software components of the Move It Services, (d) copy, frame or mirror any part of the Move It Services, (e) interfere with or disrupt the integrity or performance of the Move It Services, or (f) attempt to gain unauthorized access to the Move It Services or its related systems or networks.</p>
                    
                              <h4 >Prohibited Use of the Move It Services:</h4>
                                <p>You may not post or otherwise make available on or through the Move It Services any of the following:</p>
                                <ul>
                                  <li><p>Private information of any other persons (including names, email addresses, phone numbers, Social Security numbers and financial information);</p> </li>
                                  <li><p>Content that is libelous, defamatory, abusive, offensive or hateful;</p> </li>
                                  <li><p>Content that is obscene, pornographic, indecent or sexually explicit, depicts graphic, excessive or gratuitous violence;</p> </li>
                                  <li><p>Content that is illegal, harmful or offensive or that would encourage, solicit, foster, glorify or provide instructions for any criminal or civil offense;</p> </li>
                                  <li><p>Content that may infringe, misappropriate or violate any intellectual property rights, rights of privacy, rights of publicity or any other rights of others;</p> </li>
                                  <li><p>Viruses, corrupted data or other harmful, disruptive or destructive files or code;</p> </li>
                                  <li><p>Content that, in Move It's judgment, is objectionable, may restrict or inhibit another from enjoying the Move It Services or may expose Move It or users of the Move It Services to harm or liability of any type; and/or</p> </li>
                                  <li><p>Content that You are contractually or legally required to keep confidential.</p> </li>
                                </ul>
                                <p>Also, You may not do any of the following in connection with the Move It Services or other users:</p>
                                <ul>
                                  <li><p>Use the Move It Services in any manner that could interfere with, disrupt, negatively affect or inhibit other users from fully enjoying the Move It Services or that could damage, disable, overburden or impair the functioning of the Move It Services;</p> </li>
                                  <li><p>Collect any personal information about other users;</p> </li>
                                  <li><p>Intimidate, threaten, stalk, bully or otherwise harass other users;</p> </li>
                                  <li><p>Post spam or commercial messages through the Move It Services;</p> </li>
                                  <li><p>Create an account or submit a Request if You are not over 18 years of age;</p> </li>
                                  <li><p>Use the Move It Services for any illegal or unauthorized purpose or to engage in, encourage or promote any activity that is unlawful or that violates these Terms; or</p> </li>
                                  <li><p>Circumvent or attempt to circumvent any filtering, security measures, rate limits or other features designed to protect the Move It Services, its users, or third parties.</p> </li>
                                </ul>
                                <p>Your use of the Move It Services is at Your own risk. Move It is not responsible or liable for the conduct of, or Your interactions with, any other users (whether online or offline) or for any related damage or harm. As a provider of interactive services, Move It is not liable for the content of any Requests. Although we have no obligation to screen, edit or monitor Requests, we reserve the right, and have the discretion, to screen, edit or remove any Requests at any time, for any reason and without notice.
                                </p>
                    
                                <h4 >Disputes between You and an Independent Vehicle Owner:</h4>
                                <p>The Move It Services is merely a neutral means of connecting You with Independent Vehicle Owners. We do not represent or warrant that any Independent Vehicle Owner will meet Your expectations or instructions in performing any Services. Any dispute that You may have regarding the performance of any Services, including any dispute related to the time, place, and manner of doing so, is between You and the applicable Independent Vehicle Owner. Move It is not responsible for the replacement or repair of any of Your personal property that may be damaged by an Independent Vehicle Owner while performing the Services. </p>
                                <p>Please report any suspected illegal or unethical behavior by an Independent Vehicle Owner during the performance of any Services to: (email).</p>
                    
                                <h4 >Reporting and Removal:</h4>
                                <p>Move It users may report content to Move It that they believe violates these Terms, and Move It may remove such content, suspend or terminate the account of the user who posted or otherwise made available such content and/or take additional action to enforce these Terms against such user. </p>
                                <p>Also, in accordance with the Digital Millennium Copyright Act (DMCA) and other applicable law, Move It has adopted a policy of terminating, in appropriate circumstances and at Move It 's sole discretion, subscribers or account holders who are deemed to be repeat infringers. Move It may also at its sole discretion limit access to the Move It Services or terminate the accounts of any users who infringe any intellectual property rights of others, whether or not there is any repeat infringement.  </p>
                    
                                <p className="mt-4">If You believe that anything on the Move It Services infringes upon any copyright that You own or control, You may file a notification with Move It as set forth below: </p>
                                <p>Move It, LLC <br/> (Address)</p>
                                <p>Please see 17 U.S.C. § 512(c)(3) for the requirements of a proper notification. If You knowingly misrepresent in Your notification that the material or activity is infringing, You may be liable for any damages, including costs and attorneys' fees, incurred by Move It or the alleged infringer as the result of Move It relying upon such misrepresentation in removing or disabling access to the material or activity claimed to be infringing.</p>
                    
                                <h4 >Move It’s Rights:</h4>
                                <p>As between You and Move It, all information, materials and content of the Move It Services, including text, graphics, data, formatting, graphs, designs, HTML, look and feel, photographs, music, sounds, images, software, videos, designs, typefaces, source and object code, format, queries, algorithms and other content is owned by Move It or is used with permission. When You create, share, link to, or otherwise make available any Requests, You grant us a nonexclusive, royalty-free, perpetual, irrevocable and fully sublicensable right to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, perform and display such Requests throughout the world in any manner or media, on or off the Move It Services. Move It reserves all rights not expressly set forth in these Terms. You hereby irrevocably waive in favor of Move It any and all moral rights that You may possess in or to any Requests.
                                </p>
                    
                                <h4 >Feedback:</h4>
                                <p>Any suggestions, comments or other feedback You give us about the Move It Services (the "Feedback") will constitute our confidential information. We are free to use, disclose, reproduce, license, distribute and exploit this Feedback as we see fit, without compensation to You or any obligation or restriction because of any intellectual property rights or otherwise.</p>
                    
                                <h4 >Location Information:</h4>
                                <p>The Move It Services collects location information and it will be used and disclosed as set forth in the Privacy Policy.</p>
                                <p>By accepting these Terms or using the App You affirmatively consent to Move It's collection, use, disclosure and storage of Your location information. You may revoke Your consent with respect to Move It's collection, use, disclosure and storage of Your location information at any time by deleting the App from Your mobile device, or by changing the privacy settings on Your mobile device. Please know that if You revoke Your consent by doing one of the foregoing or You delete or deactivate Your account, we may retain certain information as required by law or for legitimate business purposes. We may also retain cached or archived copies of information about You for a certain period.</p>
                                <p>If You consent to our collection of location information and You do not subsequently stop the collection of this location information, Move It will continue to collect this location information. If You consent to our collection of location information, subsequently stop the collection of this location information and later consent to the collection of this location information, Move It will resume the collection of location information.</p>
                    
                                <h3 ><ins>Disclaimers</ins></h3>
                                <p>EXCEPT AS REQUIRED OTHERWISE OF MOVE IT BY APPLICABLE LAW, THE MOVE IT SERVICES AND ANY OTHER SERVICE AND CONTENT INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THE SERVICE (INCLUDING REQUESTS) ARE PROVIDED TO YOU ON AN "AS IS," "AS AVAILABLE" BASIS WITHOUT ANY REPRESENTATIONS OR WARRANTIES OF ANY KIND. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, WE DISCLAIM AND EXCLUDE ANY AND ALL OTHER WARRANTIES, CONDITIONS, AND REPRESENTATIONS (EXPRESS OR IMPLIED, ORAL OR WRITTEN), AND ALL OTHER TERMS WHICH MAY BE IMPLIED INTO THESE TERMS BY LAW, WITH RESPECT TO THE MOVE IT SERVICES AND CONTENT INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THE MOVE IT SERVICES (INCLUDING REQUESTS) AND THE SERVICES PERFORMED BY INDEPENDENT VEHICLE OWNERS.</p>
                    
                                <h4 >Limitation of Liability:</h4>
                                <p>TO THE EXTENT NOT PROHIBITED BY LAW, IN NO EVENT WILL MOVE IT BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INCIDENTAL, SPECIAL, INDIRECT, CONSEQUENTIAL, EXEMPLARY, OR PUNITIVE DAMAGES WHATSOEVER INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROPERTY, PHYSICAL LOSSES, DEATH OR ANY OTHER DAMAGES OR LOSSES, ARISING OUT OF OR RELATED TO THESE TERMS, THE MOVE IT SERVICES (INCLUDING THE APP AND ANY REQUEST OR BID), SERVICES PERFORMED BY INDEPENDENT VEHICLE OWNERS, AND/OR THIRD-PARTY SERVICES OR MATERIALS, HOWEVER CAUSED, REGARDLESS OF THE THEORY OF LIABILITY (CONTRACT, WARRANTY, TORT (INCLUDING NEGLIGENCE, WHETHER ACTIVE, PASSIVE OR IMPUTED), PRODUCT LIABILITY, STRICT LIABILITY OR OTHER THEORY) AND EVEN IF MOVE IT HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THIS LIMITATION MAY NOT APPLY TO YOU.</p>
                                <p>FOR ANY LOSS OR DAMAGE THAT IS NOT EXCLUDED UNDER THESE TERMS, THE TOTAL LIABILITY OF MOVE IT AND ITS DIRECTOR, EMPLOYEES, AGENTS, AFFILIATES AND INVESTORS WILL NOT EXCEED THE FEE TO DOWNLOAD THE APP (IF ANY) AS OPPOSED TO ANY OTHER FEES/COSTS INCLUDING, BUT NOT LIMITED TO, ANY FEES ASSOCIATED WITH YOUR DEVICE.
                                </p>
                    
                                <h4>Indemnification:</h4>
                                <p>You will defend us from and against any actual or threatened suits, actions, proceedings (at law or in equity), and claims, and indemnify us for any and all damages, payments, deficiencies, fines, judgments, settlements, liabilities, losses, costs, and expenses (including reasonable attorneys' fees, costs, penalties, interest, and disbursements) arising from or related to any of Your conduct with respect to the Move It Services or Your violation (or alleged violation) of these Terms or the rights of any third party by You or any person using Your Move It account.
                                </p>
                    
                                <h4>Third Party Software:</h4>
                                <p>The software You download consists of a package of components, including certain third-party software provided under separate third party license terms. Your use of this third party software in conjunction with the App in a manner consistent with the terms of these Terms is permitted, however, You may have broader rights under the applicable third-party license terms, and nothing in these Terms is intended to impose further restrictions on Your use of this third-party software.
                                </p>
                    
                                <h4>Changes to the Move It Services:</h4>
                                <p>Move It reserves the right in our discretion to review, improve, change or discontinue, temporarily or permanently, the Move It Services and/or any features, information, materials or content on the Move It Services with or without providing notice to You. Move It will not be liable to You or any third party for any changes or discontinuance of the Move It Services or any part of the Move It Services.</p>
                    
                                <h4>Consent to Electronic Communications:</h4>
                    			
                                <p>By using the Move It Services, You agree that we may communicate with You electronically regarding Your use of the Move It Services and that any notices, agreements, disclosures or other communications that we send to You electronically will satisfy any legal communication requirements, including that the communications be in writing. To withdraw Your consent from receiving electronic notice, please notify us at (email).</p>
                    
                                <h4>Suspension and Termination:</h4>
                    			
                                <p>Move It may suspend or terminate Your rights to access or use the Move It Services (including the App) for any reason or for no reason at all and with or without notice at Move It's discretion. Suspension or termination may include restricting access to and use of the App. All of the terms of these Terms (excluding the license grant) will survive any termination or suspension. </p>
                                <p className="mt-3">Governing Law; Arbitration</p>
                                <p>PLEASE READ THE FOLLOWING PARAGRAPHS CAREFULLY BECAUSE THEY REQUIRE YOU TO ARBITRATE DISPUTES WITH MOVE IT AND LIMIT THE MANNER IN WHICH YOU CAN SEEK RELIEF FROM MOVE IT.</p>
                                <p>This Arbitration Provision is governed by the Nevada Uniform Arbitration Act of 2000 (“NAA”).  This Arbitration Provision applies to any dispute arising out of or related to these Terms or termination of these Terms and survives after the Terms terminate. Nothing contained in this Arbitration Provision shall be construed to prevent or excuse You from utilizing any informal procedure for resolution of complaints established in these Terms (if any), and this Arbitration Provision is not intended to be a substitute for the utilization of such procedures.  If it is determined by a court of law that this provision cannot be arbitrated pursuant to the NAA then the Federal Arbitration Act, 9 U.S.C. § 1 et seq. (the “FAA”) shall govern.
                                </p>
                                <p>The interpretation of these Terms shall be governed by the laws of the State of Nevada without regard to the choice or conflicts of law provisions of any jurisdiction. You hereby agree and consent to the personal and exclusive jurisdiction of the Courts of the County of Clark, State of Nevada as to all suits, actions and proceedings arising out of or relating to these Terms and You further waive any claim that such suit, action or proceedings is brought in an improper or inconvenient forum.  Any disputes, actions, claims or causes of action arising out of or in connection with this Agreement or the Company Services that are not subject to arbitration shall be subject to the exclusive jurisdiction of the state and federal courts located in the City of Las Vegas, County of Clark, State of Nevada. However, neither the choice of law provision regarding the interpretation of this Agreement nor the forum selection provision is intended to create any other substantive right to non-Nevadans to assert claims under Nevada law whether that be by statute, common law, or otherwise. The failure of Company to enforce any right or provision in this Agreement shall not constitute a waiver of such right or provision unless acknowledged and agreed to by Company in writing. You and Move It agree to resolve any dispute that is in arbitration on an individual basis only, and not on a class, collective action, or representative basis (“Class Action Waiver”). The Arbitrator shall have no authority to consider or resolve any claim or issue any relief on any basis other than an individual basis. The Arbitrator shall have no authority to consider or resolve any claim or issue any relief on a class, collective, or representative basis.</p>
                                <p>You have the sole right to reject arbitration as a means of resolving disputes with us at any time within thirty (30) days following your execution of these Terms. To reject arbitration you must give us written notice of your rejection of  arbitration provision by U.S. Mail, return receipt requested. Your notice to us opting out of this arbitration provision should include your name, address as well as a statement that you do not wish to resolve disputes with us through arbitration. You hereby agree and acknowledge that you may only opt out of this arbitration provision in the manner described above and it is not sufficient to notify us of your intent to opt out of this arbitration provision via telephone or any other means. You can only reject this arbitration provision until the thirtieth (30th) day following your signature on these terms; thereafter, you will be bound by the terms of this arbitration provision.</p>
                                <p className="mt-3">An arbitrator shall decide whether a condition precedent to arbitrability has been fulfilled and whether a contract containing a valid agreement to arbitrate is enforceable.  If a party to a judicial proceeding challenges the existence of, or claims that a controversy is not subject to, an agreement to arbitrate, the arbitral proceeding may continue pending final resolution of the issue by the court, unless the court otherwise orders.</p>
                                <p>WHETHER TO AGREE TO ARBITRATION IS AN IMPORTANT BUSINESS DECISION. IT IS YOUR DECISION TO MAKE, AND YOU SHOULD NOT RELY SOLELY UPON THE INFORMATION PROVIDED IN THIS AGREEMENT AS IT IS NOT INTENDED TO CONTAIN A COMPLETE EXPLANATION OF THE CONSEQUENCES OF ARBITRATION.  YOU SHOULD TAKE REASONABLE STEPS TO CONDUCT FURTHER RESEARCH AND TO CONSULT WITH OTHERS — INCLUDING BUT NOT LIMITED TO AN ATTORNEY —</p>
                                <p>REGARDING THE CONSEQUENCES OF YOUR DECISION, JUST AS YOU WOULD WHEN MAKING ANY OTHER IMPORTANT BUSINESS OR LIFE DECISION.</p>
                                <p>BY ENTERING YOUR NAME AND ADDRESS BELOW, YOU EXPRESSLY ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD, AND TAKEN STEPS TO THOUGHTFULLY CONSIDER THE CONSEQUENCES OF THIS ARBITRATION AGREEMENT, THAT YOU AGREE TO BE BOUND BY THE TERMS AND CONDITIONS OF THE ARBITRATION AGREEMENT, AND THAT YOU ARE LEGALLY COMPETENT TO ENTER INTO THE ARBITRATION AGREEMENT WITH COMPANY.</p>
                                <p>Signed: ____________________________, this ___ day of ______, 20__.</p>
                                <p>I, ______________ (NAME) OF _______________________________________(ADDRESS), DO HEREBY ACKNOWLEDGE THAT I HAVE READ THIS ARBITRATION AGREEMENT AND AFFIRMATIVELY AGREED TO AND GIVE THIS SPECIFIC AUTHORIZATION TO SUBMIT TO ARBITRATION ANY DISPUTE ARISING BETWEEN THE PARTIES TO THIS AGREEMENT, THAT I AM BOUND TO THE SAME.</p>
                    
                                <h3 ><ins>Miscellaneous</ins></h3>
                                <p>Enforcement of these Terms is solely at Move It's discretion. Failure to enforce any part of these Terms in some instances does not constitute a waiver of our right to enforce the same or other part of these Terms in other instances. If any provision of these Terms is or becomes unlawful, void or otherwise unenforceable (including the warranty disclaimers and liability limitations above), then that provision will be deemed superseded by a valid, enforceable provision that most closely matches the intent of the original provision, and the remaining provisions of these Terms will continue in full force and effect. The section headings of these Terms are for reference purposes only and will not affect the meaning or interpretation of these Terms.</p>
                                <p>These Terms (and the other policies and terms referred to above) make up the entire agreement between You and us regarding the Move It Services and the Services, and they supersede any prior agreements that may have been made.</p>
                                <p className="mt-4">Contact Us <br/> If You have any questions about these Terms, please email us at (email) or send a letter to:</p>
                                <p className="mt-3">Move It, LLC</p>
                                <p>___________________________</p>
                                <p>___________________________</p>
                                <p>Notice for California Users <br/> If You are a California resident, You may have these Terms mailed to You electronically by sending a letter to the address above with Your electronic mail address and a request for these Terms. Under California Civil Code Section 1789.3, California Website users are entitled to the following specific consumer rights notice: The Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs may be contacted in writing at 1625 N. Market Blvd., Suite S-202, Sacramento, California 95834, or by telephone at (800) 952-5210.</p>
                                <p>Notice Regarding Apple <br/> Apple, Inc. and Other Third-Party Beneficiaries. You acknowledge and agree that Apple, Inc. ("Apple") and its subsidiaries are third-party beneficiaries of this Agreement and further that (a) upon Your acceptance of the terms and conditions of this Agreement, Apple will have the right (and will be deemed to have accepted the right) to enforce this Agreement against You as a third-party; (b) this Agreement is conducted between You and Move It only, and not Apple; (c) Move It, and not Apple, is solely responsible for the Software and the content thereof; (d) Apple has no obligation whatsoever to furnish any maintenance and support services with respect to the Software; (e) to the maximum extent permitted by applicable law, Apple will have no warranty obligation with respect to the Software; and Move It, and not Apple, will be responsible for any claims, losses, liabilities, damages, costs or expenses attributable to any failure of the Software to conform to any applicable warranty; (f) in the event of any third party claim that the Software or Your possession and use of that Software infringes that third party's intellectual property rights, Apple will not be responsible for the investigation, defense, settlement and discharge of any such intellectual property infringement claim; and (g) Apple will not be responsible for addressing any of Your claims or any third party claims relating to the Software or Your possession and/or use of the Software, including, but not limited to: (i) product liability claims; (ii) any claim that the Software fails to conform to any applicable legal or regulatory requirement; and (iii) claims arising under consumer protection or similar legislation.</p>
                    
                          </div>
                        </div>
                      </div>
                </section>
                 <Link className="section red padd-50" to="/contact-us">
                    <article className="text-center">
                        <h6 className="h2">Still have  <strong> questions? </strong></h6>
                        <span className="button outline inverse">Contact Us <i className="fa fa-chevron-right"></i></span>
                    </article>
                </Link>
            </main>
            
        <Footer></Footer>
        </> 
    ); 
}

export default termsCondition;
