
import Header from './../layout/Header'; //Include Heder
import Footer from './../layout/Footer'; //Include Footer
import Service from './../../service';
import {useFormik} from 'formik';
import * as yup from 'yup';
import React, {useState} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import toast, { Toaster } from 'react-hot-toast';
import MetaHelmet from './../metaHelmet'; //Include metaHelmet
import alertConfirm, { Button } from "react-alert-confirm";


const api = new Service();
const DeleteHelperAccount = (props) => {
    const [loading, setLoading] = useState(false);
    const Formik=useFormik({
        initialValues:{
          email_id:'',
          message: '',
        },
        validationSchema: yup.object({
            email_id: yup.string().email('Invalid email address').required('Please enter your email address'),
            message : yup.string().required('Please enter your message.'),
        }),
    
        onSubmit:values=>{
            // setLoading(true)
            alertConfirm({
                title: "Are you sure you to delete your account?",
                // content: Component(option, deleteType),
                style: { position: "fixed" },
                className: "setconfirm",
                footer(dispatch) {
                    return (
                        <>
                            <Button className="yesnobtn" onClick={() => dispatch('ok')}><span>Yes</span></Button>
                            <Button className="yesnobtn-no" onClick={() => dispatch('no')} styleType="primary">
                                <span>NO</span>
                            </Button>
                        </>
                    );
                },
                async closeBefore(action, close) {
                    if (action == 'no') {
                        close();
                    } else {
                        api.postApiHelper('delete-account',values).then(response => {
                            if(response){
                                close();
                                toast.success(response.message);
                            }
                            Formik.resetForm()
                            setLoading(false)
                        }).catch(error => {
                            close();
                            setLoading(false)
                            toast.error(error.message);
                        });
                    }
                }
            });
            
        }
    
      });

    //   const Component = (option, deleteType) => {
    //     // Here it's ReactElement
    //     if (deleteType == 'upcoming' && option.is_cancellection_charge == 1 && option.job_started == false && option.job_on_the_way == false) {
    //         return <div>Are you sure you want to cancel this move? <br />
    //             There will be a 20% cancellation fee for canceling within 24 hours of scheduled service.
    //         </div>

    //     } else if (deleteType == 'upcoming' && option.job_started == false && option.job_on_the_way == false) {
    //         return <div>Are you sure you want to cancel this move? </div>
    //     } else if (deleteType == 'upcoming' && (option.job_started == true || option.job_on_the_way == true)) {
    //         return <div>Are you sure you want to cancel this move?  <br />
    //             There will be a 50% cancellation fee for canceling after your helper(s) are in their way to you location or started service.
    //         </div>
    //     } else if (deleteType == 'pending') {
    //         return <div>Are you sure you to cancel this pending move?</div>
    //     } else if (deleteType == 'draft') {
    //         return <div>Are you sure you to delete this draft?</div>
    //     }
    // }
    
    return (
        <>
        <MetaHelmet title="Delete Account - Move It" description="If you need help with anything or have questions / concerns please don't hesitate to ask. You can send us a message and also send an email." />
         <Toaster position="top-right" reverseOrder={false} />
        <Header></Header>
            <main>
                <section className="hero clean bg-pod conus-img" style={{backgroundImage: `url("web/img/c-us.jpg")`}}>
                    <article>
                        <div className="grid">
                            <div className="unit-2-3 unit-1-1-md">
                                <h1 className="h2 f-46">Delete Account</h1>
                                {/* <h2 className="h5 mb-2">How can we help you?</h2> */}
                            </div>
                        </div>
                    </article>
                </section>
    
    
                <section className="slate">
                    <article>
                        <div className="grid grid-lg">
                            <div className="col-md-12 mb-5">
    						  {/* <h5>Our goal at Move It is to provide 100% customer satisfaction. If you need help with anything or have questions / concerns please don't hesitate to ask.</h5> */}
    						  </div>
    						
    						<div id="contact" className="unit-3-5 unit-1-1-md margin-bottom">
                                <form onSubmit={Formik.handleSubmit}>
                                  
                                    <label>
                                        <span>Email Address*</span>
                                        <input type="email_id" name="email_id" {...Formik.getFieldProps("email_id")} placeholder="Enter your email address..." / >
                                        {Formik.touched.email_id && Formik.errors.email_id ? <span  className="text-danger">{Formik.errors.email_id}</span> : null}
                                    </label>
                                    
                                    <label>
                                        <span>Message</span>
                                        <textarea rows="5" name="message" {...Formik.getFieldProps("message")}  placeholder="Enter your message..."></textarea>
                                         {Formik.touched.message && Formik.errors.message ? <span  className="text-danger">{Formik.errors.message}</span> : null}
                                    </label>
                                   
                                    <div className="">
                                        <button className="full" type="submit"><span>Submit</span></button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </article>
                </section>
             
            </main>
            
        <Footer loading={loading}></Footer>
        </> 
    ); 
}

export default DeleteHelperAccount;
