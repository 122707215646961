
import Header from './layout/HeaderMoveitLicense';
import Footer from './layout/FooterMoveitLicense';
import Service from './../service';
import React, { useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import toast, { Toaster } from 'react-hot-toast';
import MetaHelmet from './metaHelmet'; //Include metaHelmet
import { useNavigate } from 'react-router-dom';
import { Link} from 'react-router-dom';

const api = new Service();
const MoveLicensingOurServices = (props) => {

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
   

    return (
        <>
            <MetaHelmet title="Move It Licensing" description="If you need help with anything or have questions / concerns please don't hesitate to ask. You can send us a message and also send an email." />
            <Toaster position="top-right" reverseOrder={false} />
            <Header></Header>
            <main>

            <section className="hero clean faq-andimg" style={{ backgroundImage: `url('web/img/04.jpg')` }}>
				<article>
					<div className="grid">
						<div className="unit-2-3 unit-1-1-md">
							<h1 className="h2 f-46">Move It Licensing Services</h1>
						</div>
					</div>
				</article>
			</section>

            <section className="movelicensingFirst">
              <div className="container">
                <div className="row">
                    <div className="col-md-6">
                       <div className="mv_aboutus2"><img className="rellax" src="web/img/about-2.jpg" alt="Move It - On Demand Delivery & Move" data-rellax-speed="1" /></div>
                    </div>
                    
                    <div className="col-md-6">
                          <h2>Expand Your Business Nationally and Internationally</h2>
                          {/*<p>Truck and Muscle, Anytime You Need It ™</p>*/}
                          <h4 className="moveitliceningHead">Move It ™ LICENSING will help you scale your business quickly, easily, and affordably.</h4>
                          <img  src="./web/img/license.png" className="ShildImg" alt="Move It - On Demand Delivery & Move"/>
                     </div>
                  </div>
               </div>
           </section>



		   <section className="pro-muscles pt-0">
        		  <article>
        			<h2 className="margin-bottom-md text-center"><span className="flourish text"><span className="flourish-square"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 150" width="150" height="150" preserveAspectRatio="xMidYMid meet" style={{width:"100%", height: "100%", transform: "translate3d(0px, 0px, 0px)"}}><defs><clipPath id="__lottie_element_147"><rect width="150" height="150" x="0" y="0"></rect></clipPath></defs><g clipPath="url(#__lottie_element_147)"><g style={{display: "block"}} transform="matrix(1,0,0,1,459,133.5)" opacity="1"><g opacity="1" transform="matrix(1,0,0,1,0,0)"><path strokeLinecap="butt" strokeLinejoin="miter" fillOpacity="0" strokeMiterlimit="4" stroke="rgb(232,154,167)" strokeOpacity="1" strokeWidth="10" d=" M-317,-65 C-317,-65 -317,-125 -317,-125 C-317,-125 -451,-125 -451,-125 C-451,-125 -451,8 -451,8 C-451,8 -394,8 -394,8"></path></g></g></g></svg></span> Our Services</span></h2>
        			
                  			
        			<div className="row d-flex align-items-center justify-content-center highlights">
        				<div className="col-md-12 col-lg-9">
        				
        				  <div className="row d-flex align-items-center no-gutters highlight">
        					<div className="col-md-6">
        						<img src="/web/img/citydetail/1Licensing-Opportunities.jpg" alt=""/>
        					</div>
        					<div className="col-md-6">
        						<div className="promuscle">
        						   <h3>Licensing Opportunities</h3>
        						   <p>Move It is going nationwide. Current license owners are extremely happy with the return they are receiving. Inquire about an available territory today.</p>
        						</div>   
        					</div>
        				  </div>	
        				  <div className="row d-flex align-items-center no-gutters  highlight">
        					<div className="col-md-6">
        						<img src="/web/img/citydetail/1Marketingstrategies.png" alt=""/>
        					</div>
        					<div className="col-md-6">
        						<div className="promuscle">
        						   <h3>Marketing Strategies</h3>
        						   <p>Founder/CEO Michael Eshragh owned and operated a Fortune 1000 advertising firm for 16 years. Move It’s marketing strategies are very complex and successful.</p>
        						</div>   
        					</div>
        				  </div>
        				  <div className="row d-flex align-items-center no-gutters highlight">
        					<div className="col-md-6">
        						<img src="/web/img/citydetail/1Consulting-services.png" alt=""/>
        					</div>
        					<div className="col-md-6">
        						<div className="promuscle">
        						   <h3>Consulting Services</h3>
        						   <p>Move It team will guide you through every step of the process. We will break down numbers and license conditions. We will be with you every step of the way to guarantee a fruitful collaboration.</p>
        						</div>   
        					</div>
        				  </div>
						  <div className="row d-flex align-items-center no-gutters  highlight">
        					<div className="col-md-6">
        						<img src="/web/img/citydetail/1Scalefast.jpg" alt=""/>
        					</div>
        					<div className="col-md-6">
        						<div className="promuscle">
        						   <h3>Scale Fast</h3>
        						   <p>Your new territory will go to market and be live within 45-60 days. Earning immediately. Scaling fast enough that current license owners are inquiring about connecting territories.</p>
        						</div>   
        					</div>
        				  </div>
						  <div className="row d-flex align-items-center no-gutters highlight">
        					<div className="col-md-6">
        						<img src="/web/img/citydetail/1Brandingexcellence.png" alt=""/>
        					</div>
        					<div className="col-md-6">
        						<div className="promuscle">
        						   <h3>Branding Excellence</h3>
        						   <p>Move Its brand is quickly becoming recognizable regionally. The trademarked logos and slogans of the Move It brand will excel nationwide. With a flawless customer review rating on Google and App Store reviews, Move It strives for 100% customer satisfaction, and it shows.</p>
        						</div>   
        					</div>
        				  </div>
						  <div className="row d-flex align-items-center no-gutters  highlight">
        					<div className="col-md-6">
        						<img src="/web/img/citydetail/1Referralprogram.png" alt=""/>
        					</div>
        					<div className="col-md-6">
        						<div className="promuscle">
        						   <h3>Referral Program</h3>
        						   <p>On top of earning on every job completed in your territory, you can earn another 10% with our referral program. Every lead that buys a territory, you will receive a commission.</p>
        						</div>   
        					</div>
        				  </div>
        					
        				</div>	
        			</div>	
        		  </article>
        		</section>


           {/*<section className="slate why-pro-muscles licendingBgServices">
        		  <article>
        			 <div className="grid-center margin-bottom-sm mb-5">
        				<div className="unit-2-1 unit-1-1-md mb-5">
        					<h3 className="flourish text becomepromuscle"><span className="flourish-square zin1"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 150" width="150" height="150" preserveAspectRatio="xMidYMid meet"  style={{width: "100%",height: "100%", transform: "translate3d(0px, 0px, 0px)" }}><defs><clipPath id="__lottie_element_147"><rect width="150" height="150" x="0" y="0"></rect></clipPath></defs><g clipPath="url(#__lottie_element_147)"><g style={{display: "block"}} transform="matrix(1,0,0,1,459,133.5)" opacity="1"><g opacity="1" transform="matrix(1,0,0,1,0,0)"><path strokeLinecap="butt" strokeLinejoin="miter" fillOpacity="0" strokeMiterlimit="4" stroke="rgb(255,149,162)" strokeOpacity="1" strokeWidth="10" d=" M-317,-65 C-317,-65 -317,-125 -317,-125 C-317,-125 -451,-125 -451,-125 C-451,-125 -451,8 -451,8 C-451,8 -394,8 -394,8"></path></g></g></g></svg></span>Our Services</h3>
        				</div>
        			</div>  
        			   
        			<div className="row mb-5 mt-5">
        			   <div className="col-md-6 col-lg-6">
        			       <div className="themestek-ihbox-inner">
        						<div className="themestek-ihbox-icon">
        							<div className="circleImgLicense"><img src="web/img/citydetail/licensing.png" alt=""/></div>		
        						</div>
        						<div className="themestek-ihbox-contents">
        							<div className="themestek-vc_general">
        								<h5 className="themestek-custom-heading ">Licensing Opportunities</h5>
        							</div>	
        				           <div className="themestek-cta3-content-wrapper">Move It is going nationwide. Current license owners are extremely happy with the return they are receiving. Inquire about an available territory today.</div>			
        						</div>
        					</div>
        			   </div>
        			   <div className="col-md-6 col-lg-6">
        			       <div className="themestek-ihbox-inner">
        						<div className="themestek-ihbox-icon">
        							<div className="circleImgLicense"><img src="web/img/citydetail/promotion.png" alt=""/></div>		
        						</div>
        						<div className="themestek-ihbox-contents">
        							<div className="themestek-vc_general">
        								<h5 className="themestek-custom-heading ">Marketing Strategies</h5>
        							</div>	
        				           <div className="themestek-cta3-content-wrapper">Founder/CEO Michael Eshragh owned and operated a Fortune 1000 advertising firm for 16 years. Move It’s marketing strategies are very complex and successful.</div>			
        						</div>
        					</div>
        			   </div>
        			</div>

        			<div className="row mb-5">
        			   <div className="col-md-6 col-lg-6">
        			       <div className="themestek-ihbox-inner">
        						<div className="themestek-ihbox-icon">
        							<div className="circleImgLicense"><img src="web/img/citydetail/discussion.png" alt=""/></div>		
        						</div>
        						<div className="themestek-ihbox-contents">
        							<div className="themestek-vc_general">
        								<h5 className="themestek-custom-heading ">Consulting Services</h5>
        							</div>	
        				           <div className="themestek-cta3-content-wrapper">Move It team will guide you through every step of the process. We will break down numbers and license conditions. We will be with you every step of the way to guarantee a fruitful collaboration.</div>			
        						</div>
        					</div>
        			   </div>
        			   <div className="col-md-6 col-lg-6">
        			       <div className="themestek-ihbox-inner">
        						<div className="themestek-ihbox-icon">
        							<div className="circleImgLicense"><img src="web/img/citydetail/budget.png" alt=""/></div>		
        						</div>
        						<div className="themestek-ihbox-contents">
        							<div className="themestek-vc_general">
        								<h5 className="themestek-custom-heading ">Scale Fast</h5>
        							</div>	
        				           <div className="themestek-cta3-content-wrapper">Your new territory will go to market and be live within 45-60 days. Earning immediately. Scaling fast enough that current license owners are inquiring about connecting territories.</div>			
        						</div>
        					</div>
        			   </div>
        			</div>

                    <div className="row mb-5">
        			   <div className="col-md-6 col-lg-6">
        			       <div className="themestek-ihbox-inner">
        						<div className="themestek-ihbox-icon">
        							<div className="circleImgLicense"><img src="web/img/citydetail/brand-image.png" alt=""/></div>		
        						</div>
        						<div className="themestek-ihbox-contents">
        							<div className="themestek-vc_general">
        								<h5 className="themestek-custom-heading ">Branding Excellence</h5>
        							</div>	
        				           <div className="themestek-cta3-content-wrapper">Move Its brand is quickly becoming recognizable regionally. The trademarked logos and slogans of the Move It brand will excel nationwide. With a flawless customer review rating on Google and App Store reviews, Move It strives for 100% customer satisfaction, and it shows.</div>			
        						</div>
        					</div>
        			   </div>
        			   <div className="col-md-6 col-lg-6">
        			       <div className="themestek-ihbox-inner">
        						<div className="themestek-ihbox-icon">
        							<div className="circleImgLicense"><img src="web/img/citydetail/connection.png" alt=""/></div>		
        						</div>
        						<div className="themestek-ihbox-contents">
        							<div className="themestek-vc_general">
        								<h5 className="themestek-custom-heading ">Referral Program</h5>
        							</div>	
        				           <div className="themestek-cta3-content-wrapper">On top of earning on every job completed in your territory, you can earn another 10% with our referral program. Every lead that buys a territory, you will receive a commission.</div>			
        						</div>
        					</div>
        			   </div>
        			</div>


                 </article>
        	</section>*/}

           <section className="bookconsutancy">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h2>Book A Free Consultation</h2>
                                <p>We will be glad to answer your questions. We are looking forward to work with you.</p>
                                <Link to="/request-more-info"  className="moreInfoBtn" style={{background: "#ff95a2"}}>Request More Information</Link>
                            </div>
                        </div>
                    </div>
                </section>

            </main>
            {/* <Modal modal="ContactUsThankYou" show={serviceChargeModal} handleClose={hideServiceChargeModal}></Modal> */}

            <Footer loading={loading}></Footer>
        </>
    );
}

export default MoveLicensingOurServices;
