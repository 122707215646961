import { useNavigate} from 'react-router-dom';
import Header from './../layout/Header'; //Include Heder
import Footer from './../layout/Footer'; //Include Footer
import Service from './../../service'; 
import toast, { Toaster } from 'react-hot-toast';
import React, {useState} from "react";
// import 'bootstrap/dist/css/bootstrap.min.css';
import MetaHelmet from './../metaHelmet'; //Include metaHelmet

import {useFormik} from 'formik';
import * as yup from 'yup';

const api = new Service();

const ForgotPassword = () => {
    
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [data, setdata] = useState('');
    const [step, setstep] = useState(1);
    const [emailId, setemailId] = useState('');
    
    const formik=useFormik({
        initialValues:{
            email_id:'',
            checkEmail:''
        },
        validationSchema: yup.object({
            email_id: yup.string().email('Invalid email address.').required('Please enter your email address.'),
            checkEmail: yup.boolean().oneOf([true],'Email address does not exist.'),
        }),
        onSubmit:values=>{
            setLoading(true);
            api.postApiHelper('send-helper-reset-password-code',{email_id:values.email_id}).then(response => {
                setstep(2)
                setemailId(values.email_id)
                setdata(response.web_auth_key)
                toast.success('Please Check otp send your mail-id.');
                formik.resetForm();
                setLoading(false);
            }).catch(error => {
                setLoading(false);
                toast.error(error.message);
            });
        }
    });
    
    const checkEmailExist = (email_id) => {
            formik.setFieldValue('email_id', email_id);
            api.postApiHelper('check-helper-email-address',{email_id:email_id}).then(response => {
                if(response){
                    formik.setFieldValue('checkEmail', response);
                } else {
                    formik.setFieldValue('checkEmail', false);
                }
                return response;
            }).catch(error => {
                 formik.setFieldValue('checkEmail', false);
            });
    };
    
    const stepOneSubmit = () => {
        if(formik.isValid && formik.values.email_id){
            api.postApiHelper('check-helper-email-address',{email_id:formik.values.email_id}).then(response => {
                if(response){
                    formik.handleSubmit(); 
                    formik.setFieldValue('checkEmail', response)
                } else {
                   formik.setFieldValue('checkEmail', false)
                }
            }).catch(error => {
                formik.handleSubmit();
            });
        }  else {
            formik.handleSubmit();
        }
    }
    
     const formik2 =useFormik({
        initialValues:{
            forgot_pass_code:'',
            otp_valid : true,
        },
        validationSchema: yup.object({
            forgot_pass_code: yup.string().required('Otp is required.'),
            otp_valid: yup.boolean().oneOf([true],'Otp is not valid.'),
        }),
        onSubmit:values=>{
            setLoading(true);
            api.postApiHelper('validate-helper-reset-password-code',{forgot_pass_code:values.forgot_pass_code,auth_key:data}).then(response => {
                if(response) {
            console.log(response);
                    setstep(3)
                    formik2.setFieldValue('otp_valid',true);
                }  else {
                    formik2.setFieldValue('otp_valid',false);
                }
                setLoading(false);
            }).catch(error => {
                setLoading(false);
                toast.error(error.message);
            });
        }
    });
    
    const formik3=useFormik({
        initialValues:{
            password:"",
            confirmPassword: "",
        },
        validationSchema: yup.object({
            password: yup.string().required('Please enter password'),
            confirmPassword: yup.string().required('Please enter password').when("password", {
                is: val => (val && val.length > 0 ? true : false),
                then: yup.string().oneOf(
                  [yup.ref("password")],
                  "Password is not match!"
                )
              })
        }),
        onSubmit:values=>{
            setLoading(true)
            let postData = {...values , auth_key:data};
            api.postApiHelper('reset-helper-password',postData).then(response => {
                toast.success('Password Reset Successfully.');
                formik3.resetForm();
                setLoading(false);
                navigate('/helper-login') 
            }).catch(error => {
                 setLoading(false);
                 toast.error(error.message);
            });
        }
    });
    const [passwordType, setPasswordType] = useState("password");
    const togglePassword =()=>{
      if(passwordType==="password"){
        setPasswordType("text")
        return;
      }
      setPasswordType("password")
    }

    return (
        <>
        <Toaster position="top-right" reverseOrder={false} />
        <MetaHelmet title="Move It | Helper Forgot Password" description="" />
        <Header></Header>
            <main>
                 <section className="work white mobile-paddtop">
                       <article className="container">
                          <div className="row d-flex align-items-center justify-content-center" data-aos="fade-left" data-aos-delay="100">
        					<div className="col-md-6"> 
        					
        					 {step==1?
         					  <div className="contact-wrapper lpage" id="forgot-password">
        						  <div className="title">
        						        <h3 className="text-center">Forgot Password</h3>
        						  </div>
        						  <form className="login-page" onSubmit={formik.handleSubmit}>
							        <div className="form-row">
        							  <i className="fa fa-envelope"></i>
        							    <input type="email_id" name="email_id" onBlur={(e) => checkEmailExist(e.target.value) } />
        							    <span>Email</span>
        							</div>
        						
        							{formik.touched.email_id && formik.errors.email_id ? <span  className="text-danger">{formik.errors.email_id}</span> : formik.touched.checkEmail && formik.errors.checkEmail ?  <span  className="text-danger">{formik.errors.checkEmail}</span>:null}
        							
        							<div className="form-row"></div>
        							 <h6 className="text-center dont-acct">Code will be sent to your registered email to set new password</h6>
        							<div className="form-row">
        							  <button className="full" type="button" onClick={() => stepOneSubmit() } ><span>SEND CODE</span></button>
        							</div>
        						  </form>
        					   </div>
        					   : '' }
        					  {step==2?
        					   <div className="contact-wrapper lpage" id="forgot-password-code">
        						  <div className="title">
        						    <h3 className="text-center">Verification Code</h3>
        						  </div>
        						  <form className="login-page" onSubmit={formik2.handleSubmit}>
        							<p >{emailId}</p>
        							<div className="form-row">
        							    <i className="fa fa-shield"></i>
        							     <input type="text" name="forgot_pass_code" {...formik2.getFieldProps("forgot_pass_code")} />
        							    <span>Verify Code</span>
        							</div>
        							{formik2.touched.forgot_pass_code && formik2.errors.forgot_pass_code ? <span  className="text-danger">{formik2.errors.forgot_pass_code}</span> : null}
        							
        							{formik2.values.otp_valid==false?<span className="text-danger">Please Enter Valid Code.</span> : null }
        							<div className="form-row"></div>
        							<div className="form-row">
        							    <button className="full" type="submit"  ><span>CONTINUE</span></button>
        							</div>
        							{/*<h6 className="text-center dont-acct">I didn't get a code<a href=""> Re-Send</a></h6>*/}
        							
        						  </form>
        					   </div>
        					   : '' }
        					   
        					   {step==3?
        					   <div className="contact-wrapper lpage" id="reset-password">
        						  <div className="title">
        						    <h3 className="text-center">Set Your New Password</h3>
        						  </div>
        						  <form className="login-page" onSubmit={formik3.handleSubmit}>
        						  
        							<div className="form-row">
        							    <i className="fa fa-lock" style={{left: '20px'}}></i>
        							     <input type="password" name="password" {...formik3.getFieldProps("password")}/>
        							    <span>New Password</span>
        							</div>
        							{formik3.touched.password && formik3.errors.password ? <span  className="text-danger">{formik3.errors.password}</span> : null}
        							<div className="form-row">
        							  <i className="fa fa-lock" style={{left: '20px'}}></i>
        							  <input type={passwordType} name="confirmPassword"  {...formik3.getFieldProps("confirmPassword")}  />
        							  <span>Confirm Password</span>
        							  <div toggle="#password-field" className={(passwordType==="password"? 'fa fa-fw field-icon toggle-password fa-eye-slash' : 'fa fa-fw fa-eye field-icon toggle-password')} onClick={togglePassword}></div>
        							</div>
        							{formik3.touched.confirmPassword && formik3.errors.confirmPassword ? <span  className="text-danger">{formik3.errors.confirmPassword}</span> : null}
        							<div className="form-row"></div>
        							<div className="form-row">
        							    <button className="full" type="submit" ><span>RESET</span></button>
        							</div>
        						  </form>
        					   </div>
        					   : '' }
        					   
        					   
        				    </div>	
        				
            				<div className="col-md-6"> 
            				  <img src="/web/img/about-1.jpg" alt="moveit" className="login-right-img" />
            				</div>	
                       </div>
                    </article>
                </section>
            </main>
     
        <Footer loading={loading}></Footer>
            
        </> 
    ); 
}


export default ForgotPassword;

