import React, { useState, useCallback, Component, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Link, NavLink } from 'react-router-dom';
import Header from './../layout/Header'; //Include Heder
import Footer from './../layout/Footer'; //Include Footer
import HelperSideBar from './../layout/HelperSideBar';
import ProfileSection from './profileSection';
import toast, { Toaster } from 'react-hot-toast';
import MetaHelmet from './../metaHelmet'; //Include metaHelmet

import Service from './../../service';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';

import * as yup from 'yup';
import moment from 'moment-timezone';

const api = new Service();

const W9form = () => {
    const navigate = useNavigate();
    const [helperProfile, sethelperProfile] = useState({});
    const [w9FormDetail, setw9FormDetail] = useState({});
    const [loading, setloading] = useState(false);
    const [limitedLiability, setlimitedLiability] = useState(false);
    const [validSsn, setvalidSsn] = useState(false);
    const [validSei, setvalidSei] = useState(false);
    const [step, setstep] = useState(0);
    const [iframeUrl, setiframeUrl] = useState("");
    const [rejectMessage, setrejectMessage] = useState('');

    useEffect(() => {
        if (Object.keys(w9FormDetail).length) {
            formik.resetForm();
        } else {
            GetHelperProfile()
        }
    }, [w9FormDetail])
    const GetHelperProfile = () => {
        setloading(true)
        api.getApiHelper('get-helper-info').then(response => {
            if (response && response.is_verified == 0) {
                setloading(false);
                navigate('/helper-pending')
            } else {
                sethelperProfile(response);
                setloading(false);
                getHelperW9Form(response)
            }
        }).catch(error => {
            setloading(false);
            toast.error(error.message);
        });
    }
    const getHelperW9Form = (helperInfo) => {
        api.getApiHelper('get-helper-w9-form-detail').then(response => {
            if (helperInfo.w9_form_verified === 0 && helperInfo.w9_form_status === 1 || helperInfo.w9_form_verified === 1) {
                setiframeUrl(response.fw9Form + '?web_auth_key=' + localStorage.getItem("user_token") + '&timezone=' + moment.tz.guess())
                setstep(2)
            } else {
                if (response.is_verified === 2) {
                    setrejectMessage(response.message)
                } else {
                    setrejectMessage('')
                }
                if (response.limited_liability_company) {
                    setlimitedLiability(true)
                } else {
                    setlimitedLiability(false)
                }
                setw9FormDetail(response)
                setstep(1)
            }
        }).catch(error => {
            setloading(false);
            toast.error(error.message);
        });
    }

    const formik = useFormik({
        initialValues: {
            name: w9FormDetail.name ? w9FormDetail.name : "",
            business_name: w9FormDetail.business_name ? w9FormDetail.business_name : "",
            address: w9FormDetail.address ? w9FormDetail.address : "",
            city: w9FormDetail.city ? w9FormDetail.city : "",
            state: w9FormDetail.state ? w9FormDetail.state : "",
            zipcode: w9FormDetail.zipcode ? w9FormDetail.zipcode : "",
            account_number: w9FormDetail.account_number ? w9FormDetail.account_number : "",
            account_number_2: w9FormDetail.account_number_2 ? w9FormDetail.account_number_2 : "",
            requester_name_address: w9FormDetail.requester_name_address ? w9FormDetail.requester_name_address : "",
            payee_code: w9FormDetail.payee_code ? w9FormDetail.payee_code : "",
            reporting_code: w9FormDetail.reporting_code ? w9FormDetail.reporting_code : "",
            ss_number: w9FormDetail.ss_number ? w9FormDetail.ss_number : "",
            sei_number: w9FormDetail.sei_number ? w9FormDetail.sei_number : "",
            individual: w9FormDetail.individual ? w9FormDetail.individual : 0,
            c_corporation: w9FormDetail.c_corporation ? w9FormDetail.c_corporation : 0,
            s_corporation: w9FormDetail.s_corporation ? w9FormDetail.s_corporation : 0,
            partnership: w9FormDetail.partnership ? w9FormDetail.partnership : 0,
            trust: w9FormDetail.trust ? w9FormDetail.trust : 0,
            limited_liability_company: w9FormDetail.limited_liability_company ? w9FormDetail.limited_liability_company : 0,
            tax_classification: w9FormDetail.tax_classification ? w9FormDetail.tax_classification : "",
            other: w9FormDetail.other ? w9FormDetail.other : 0,
            seven_box_valid: w9FormDetail.individual || w9FormDetail.c_corporation || w9FormDetail.s_corporation || w9FormDetail.partnership || w9FormDetail.trust || w9FormDetail.limited_liability_company || w9FormDetail.other ? true : false,
            ss_and_sei_number_valid: w9FormDetail.sei_number || w9FormDetail.ss_number ? true : false,

        },
        enableReinitialize: true,
        validationSchema: yup.object({
            name: yup.string().required('Please enter name.'),
            address: yup.string().required('Please enter address.'),
            city: yup.string().required('Please enter city name.'),
            state: yup.string().required('Please enter state name.'),
            zipcode: yup.string().required('Please enter zipcode.'),
            ss_number: setvalidSsn ? yup.string().min(9, 'Please enter valid ssn number.').max(9, 'Please enter valid ssn number.') : yup.string(),
            sei_number: setvalidSei ? yup.string().min(9, 'Please enter valid sei number.').max(9, 'Please enter valid sei number.') : yup.string(),
            seven_box_valid: yup.boolean().oneOf([true], 'Please federal tax classification.'),
            ss_and_sei_number_valid: yup.boolean().oneOf([true], 'Please enter SSN or EIN number.'),
            tax_classification: limitedLiability ? yup.string().required('Please enter ax classification.') : yup.string()
        }),
        onSubmit: values => {
            setloading(true);
            api.postApiHelper('save-helper-w9-form-detail', values).then(response => {
                setloading(false);
                toast.success(response.message);
                setiframeUrl(w9FormDetail.fw9Form + '?web_auth_key=' + localStorage.getItem("user_token") + '&timezone=' + moment.tz.guess())
                setstep(2)
            }).catch(error => {
                setloading(false);
                toast.error(error.message);
            });
        }
    });

    const removeSelectedValue = (fieldName = '') => {
        formik.setFieldValue('individual', 0);
        formik.setFieldValue('c_corporation', 0);
        formik.setFieldValue('s_corporation', 0);
        formik.setFieldValue('partnership', 0);
        formik.setFieldValue('trust', 0);
        formik.setFieldValue('limited_liability_company', 0);
        formik.setFieldValue('other', 0);
        if (fieldName) {
            formik.setFieldValue(fieldName, 1);
            formik.setFieldValue('seven_box_valid', true);
        }
        if ('limited_liability_company' == fieldName) {
            setlimitedLiability(true)
        } else {
            setlimitedLiability(false)
        }
    }

    const setSsnAndSeiNumber = (e, fieldName) => {
        formik.setFieldValue(fieldName, e.target.value);
        if (formik.values.ss_number != '') {
            setvalidSsn(true)
            formik.setFieldValue('ss_and_sei_number_valid', true);
        } else {
            setvalidSsn(false)
        }
        if (formik.values.sei_number != '') {
            setvalidSei(true)
            formik.setFieldValue('ss_and_sei_number_valid', true);
        } else {
            setvalidSei(false)
        }
    }

    const submitForReviews = () => {
        setloading(true);
        api.postApiHelper('final-submission-w9-form', {}).then(response => {
            setloading(false);
            toast.success(response.message);
            navigate('/helper-pending-w9-form')
        }).catch(error => {
            setloading(false);
            toast.error(error.message);
        });
    }
    return (
        <>
            <Toaster position="top-right" reverseOrder={false} />
            <MetaHelmet title="Move It | Helper W9-Form" description="" />
            <Header helperProfile={helperProfile} />
            <main>
                {helperProfile.is_verified ?
                    <>
                        <section className="coverphoto-helper p-0"></section>
                        <ProfileSection helperProfile={helperProfile} />

                        <section className="work white p40">
                            <article className="container">
                                <div className="row">
                                    <HelperSideBar helperProfile={helperProfile} />

                                    <div className="col-md-8 col-lg-9">
                                        <div className="row d-flex align-items-center justify-content-center" data-aos="fade-left" data-aos-delay="100">
                                            <div className="col-12 col-md-12 col-lg-10" style={{ textAlign: "center" }}>
                                                <h3>Form W-9</h3>
                                                {step == 1 ?
                                                    <>
                                                        <h6>Request for Taxpayer Identification Number & Certification</h6>
                                                        {rejectMessage ?
                                                            <>
                                                                <div className="" >
                                                                    <h2 className="text-center">W-9 form Rejected</h2>
                                                                    <h5 className="text-center">{rejectMessage}</h5>
                                                                </div>
                                                            </>
                                                            : ''}
                                                    </>
                                                    : ''}
                                            </div>
                                            {step == 1 ?
                                                <>
                                                    <div className="col-md-12 col-lg-10" >
                                                        <div className="contact-wrapper add-addressw0form">
                                                            <form action="#" className="job-application-form login-page vechicleinfo row">
                                                                <div className="form-row col-md-6 col-lg-6">
                                                                    <input type="text" placeholder="Name (as shown on your income tax return)" name="name" {...formik.getFieldProps("name")} />
                                                                    <span>Name</span>
                                                                    {formik.touched.name && formik.errors.name ? <div className="col-12 col-md-12 col-lg-10 text-danger">{formik.errors.name}</div> : ''}
                                                                </div>

                                                                <div className="form-row col-md-6 col-lg-6">
                                                                    <input type="text" placeholder="Business name/disregarded entity name" name="business_name" {...formik.getFieldProps("business_name")} />
                                                                    <span>Business Name</span>
                                                                </div>
                                                                <div className="form-row col-md-12 col-lg-12">
                                                                    <p className="mb-0">3. Check appropriate box for federal tax classification of the person whose name is entered on line 1. check only one of the following seven boxes.</p>
                                                                </div>
                                                                <div className="form-row col-md-12 col-lg-12">
                                                                    <div className="radio-btn-w-9form">
                                                                        <div className="job-application-form">
                                                                            <div className="form-group radio-group">
                                                                                <div className="custom-radio">
                                                                                    <input type="radio" name="salary" className="custom-control-input" id="radio1" onClick={() => removeSelectedValue('individual')} checked={formik.values.individual} readOnly />
                                                                                    <label className="custom-control-label" htmlFor="radio1">Individual/sole proprietor or single member LLC </label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-group radio-group">
                                                                                <div className="custom-radio">
                                                                                    <input type="radio" name="salary" className="custom-control-input" id="radio2" onClick={() => removeSelectedValue('c_corporation')} checked={formik.values.c_corporation} readOnly />
                                                                                    <label className="custom-control-label" htmlFor="radio2">C Corporation</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-group radio-group">
                                                                                <div className="custom-radio">
                                                                                    <input type="radio" name="salary" className="custom-control-input" id="radio3" onClick={() => removeSelectedValue('s_corporation')} checked={formik.values.s_corporation} readOnly />
                                                                                    <label className="custom-control-label" htmlFor="radio3">S Corporation</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-group radio-group">
                                                                                <div className="custom-radio">
                                                                                    <input type="radio" name="salary" className="custom-control-input" id="radio4" onClick={() => removeSelectedValue('partnership')} checked={formik.values.partnership} readOnly />
                                                                                    <label className="custom-control-label" htmlFor="radio4">Partnership</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-group radio-group">
                                                                                <div className="custom-radio">
                                                                                    <input type="radio" name="salary" className="custom-control-input" id="radio5" onClick={() => removeSelectedValue('trust')} checked={formik.values.trust} readOnly />
                                                                                    <label className="custom-control-label" htmlFor="radio5">Trust/Estate</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-group radio-group">
                                                                                <div className="custom-radio">
                                                                                    <input type="radio" name="salary" className="custom-control-input" id="radio6" onClick={() => removeSelectedValue('limited_liability_company')} checked={formik.values.limited_liability_company} readOnly />
                                                                                    <label className="custom-control-label" htmlFor="radio6">Limited Liabilty Company</label>
                                                                                </div>
                                                                            </div>

                                                                            {formik.touched.seven_box_valid && formik.errors.seven_box_valid ? <div className="col-12 col-md-12 col-lg-10 text-danger">{formik.errors.seven_box_valid}</div> : ''}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {limitedLiability ?
                                                                    <div className="form-row col-md-6 col-lg-6">
                                                                        <input type="text" placeholder="Tax Classification)" name="tax_classification" {...formik.getFieldProps("tax_classification")} />
                                                                        <span>Tax Classification</span>
                                                                        {formik.touched.tax_classification && formik.errors.tax_classification ? <div className="col-12 col-md-12 col-lg-10 text-danger">{formik.errors.tax_classification}</div> : ''}
                                                                    </div>
                                                                    : ''}
                                                                <div className="col-md-12 col-lg-12 mb-2">
                                                                    <p className="mb-0">
                                                                        <small>
                                                                            <b>Note</b>: Check the appropriate box in the line above for the tax classification of the single member owner. Do not check LLC if the LLC is classified as a single member LLC that is
                                                                            disregarded from the owner unless the owner of the LLC is another LLC that is not disregarded form the owner for, U.S. federal tax purposes. Otherwise a single member LLC that is disregarded
                                                                            from the owner should check the appropriate box for the tax classification od it's owner.
                                                                        </small>
                                                                    </p>
                                                                </div>
                                                                <div className="col-md-12 col-lg-12 mb-3">
                                                                    <div className="radio-btn-w-9form">
                                                                        <div className="job-application-form">
                                                                            <div className="form-group radio-group">
                                                                                <div className="custom-radio">
                                                                                    <input type="radio" name="salary" className="custom-control-input" id="radio7" onClick={() => removeSelectedValue('other')} checked={formik.values.other} readOnly />
                                                                                    <label className="custom-control-label" htmlFor="radio7">Other</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="form-row col-md-12 col-lg-12">
                                                                    <p className="mb-0">Exemptions (codes apply only to certain entities, not individuala, see instructions on page 3)</p>
                                                                </div>

                                                                <div className="form-row col-md-6 col-lg-6">
                                                                    <input type="text" placeholder="Exempt Payee code(if any)" name="payee_code" {...formik.getFieldProps("payee_code")} />
                                                                    <span>Payee Code</span>
                                                                </div>
                                                                <div className="form-row col-md-6 col-lg-6">
                                                                    <input type="text" placeholder="Exempt from FATCA reporting code(if any)" name="reporting_code" {...formik.getFieldProps("reporting_code")} />
                                                                    <span>Reporting Code</span>
                                                                </div>
                                                                <div className="form-row col-md-12 col-lg-12 addressfleld">
                                                                    <div className="form-group check-group">
                                                                        <textarea className="form-control" id="inputText" placeholder="" name="address" {...formik.getFieldProps("address")} ></textarea>
                                                                        <label htmlFor="inputText">Address</label>
                                                                    </div>
                                                                    {formik.touched.address && formik.errors.address ? <div className="col-12 col-md-12 col-lg-10 text-danger">{formik.errors.address}</div> : ''}
                                                                </div>
                                                                <div className="form-row col-md-6 col-lg-4">
                                                                    <input type="text" placeholder="Enter City" name="city" {...formik.getFieldProps("city")} />
                                                                    <span>City</span>
                                                                    {formik.touched.city && formik.errors.city ? <div className="col-12 col-md-12 col-lg-10 text-danger">{formik.errors.city}</div> : ''}
                                                                </div>
                                                                <div className="form-row col-md-6 col-lg-4">
                                                                    <input type="text" placeholder="Enter State" name="state" {...formik.getFieldProps("state")} />
                                                                    <span>State</span>
                                                                    {formik.touched.state && formik.errors.state ? <div className="col-12 col-md-12 col-lg-10 text-danger">{formik.errors.state}</div> : ''}
                                                                </div>
                                                                <div className="form-row col-md-6 col-lg-4">
                                                                    <input type="text" placeholder="Enter Zip Code" name="zipcode" {...formik.getFieldProps("zipcode")} />
                                                                    <span>Zip Code</span>
                                                                    {formik.touched.zipcode && formik.errors.zipcode ? <div className="col-12 col-md-12 col-lg-10 text-danger">{formik.errors.zipcode}</div> : ''}
                                                                </div>
                                                                <div className="form-row col-md-12 col-lg-12">
                                                                    <p className="mb-0">List account number(s) here (optional)</p>
                                                                </div>
                                                                <div className="form-row col-md-6 col-lg-6">
                                                                    <input type="text" placeholder="Enter Account" name="account_number" {...formik.getFieldProps("account_number")} />
                                                                    <span>Enter Account (optional)</span>
                                                                </div>
                                                                <div className="form-row col-md-6 col-lg-6">
                                                                    <input type="text" placeholder="Enter Account" name="account_number_2" {...formik.getFieldProps("account_number_2")} />
                                                                    <span>Enter Account (optional)</span>
                                                                </div>
                                                                <div className="form-row col-md-12 col-lg-12 addressfleld">
                                                                    <div className="form-group check-group">
                                                                        <textarea className="form-control" id="inputText" placeholder="" name="requester_name_address" {...formik.getFieldProps("requester_name_address")} ></textarea>
                                                                        <label htmlFor="inputText">Requester's name & address (optional)</label>
                                                                    </div>
                                                                </div>
                                                                <div className="form-row col-md-12 col-lg-12">
                                                                    <p className="mb-0">Taxpayer Identification Number (TIN)</p>
                                                                </div>
                                                                <div className="col-md-12 col-lg-12 mb-2">
                                                                    <p className="mb-0">
                                                                        <small>
                                                                            Enter Your TIN in the appropriate box. The TIN provided must match the name given on line I to avoid backup with holding. For individuals, this is generally your social security number. (SSN)
                                                                            However, for a resident alien, sole proprietor, or disregarded entity, see instructions for Part, I later For other entities It is your employer Identification number (EIN). If you do not have
                                                                            a number, see How to get a TI, later
                                                                        </small>
                                                                    </p>

                                                                    <p className="mb-0">
                                                                        <small><b>Note:</b> If the account is in more than one name see the instructions for line. I also see What Name and Number To Give the Requester for guidelines on whose numberto enter.</small>
                                                                    </p>
                                                                </div>
                                                                <div className="form-row col-md-8 col-lg-8">
                                                                    <input type="text" placeholder="Enter Security Number" max="9" value={formik.values.ss_number} onChange={(e) => setSsnAndSeiNumber(e, 'ss_number')} />
                                                                    <span>Social Security Number</span>
                                                                    {formik.touched.ss_number && formik.errors.ss_number ? <div className="col-12 col-md-12 col-lg-10 text-danger">{formik.errors.ss_number}</div> : ''}
                                                                </div>
                                                                <div className="form-row col-md-12 col-lg-10">
                                                                    <p className="fancy-title mt-3"><span>OR</span></p>
                                                                </div>

                                                                <div className="form-row col-md-8 col-lg-8">
                                                                    <input type="text" placeholder="Enter Employer Identification Number" value={formik.values.sei_number} onChange={(e) => setSsnAndSeiNumber(e, 'sei_number')} />
                                                                    <span>Social Employer Identification Number</span>
                                                                    {formik.touched.sei_number && formik.errors.sei_number ? <div className="col-12 col-md-12 col-lg-10 text-danger">{formik.errors.sei_number}</div> : ''}
                                                                </div>
                                                                {formik.touched.ss_and_sei_number_valid && formik.errors.ss_and_sei_number_valid ? <div className="col-12 col-md-12 col-lg-10 text-danger">{formik.errors.ss_and_sei_number_valid}</div> : ''}
                                                            </form>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12 col-lg-10 mt-3">
                                                        <div className="form-row">
                                                            <button className="full" type="button" onClick={() => formik.handleSubmit()}><span>SUBMIT</span></button>
                                                        </div>
                                                    </div>
                                                </>
                                                : ''}

                                            {step == 2 ?
                                                <>
                                                    <div className="col-md-12 col-lg-12" style={{ height: "500px" }}>
                                                        <iframe src={iframeUrl} title="" style={{ height: "100%" }}></iframe>
                                                    </div>

                                                    {helperProfile.w9_form_verified === 0 || helperProfile.w9_form_verified === 2 ?
                                                        <>
                                                            <div className="col-md-12 col-lg-12 mt-3" style={{ height: "20%", textAlign: "center" }}>
                                                                <button className="full" type="button" onClick={() => submitForReviews()} ><span>Submit For Review</span></button>
                                                            </div>
                                                        </>
                                                        : ''}
                                                </>
                                                : ''}
                                        </div>
                                    </div>


                                </div>
                            </article>
                        </section>
                    </>
                    : ''}
            </main>

            <Footer />
        </>
    )
}
export default W9form;