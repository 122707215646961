import Header from './../layout/Header'; //Include Heder
import Footer from './../layout/Footer'; //Include Footer
import Service from './../../service'; 
import toast, { Toaster } from 'react-hot-toast';
import React, {useState,useEffect} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import MetaHelmet from './../metaHelmet'; //Include metaHelmet

const api = new Service();
const BookTypes=()=>{
    const navigate = useNavigate();
    
    const [MoveType, setMoveType] = useState([]);
    useEffect(() => {
       GetMoveType();
    }, [])
    const GetMoveType = () => {
        api.getApi('get-all-move-type').then(response => {
            setMoveType(response.move_type);
        }).catch(error => {
             toast.error(error.message);
        });
    }
    
    const handleProceed = (e) => {
        navigate('/book-move/'+e.id)
    };
    return (
        <>
        <Toaster position="top-right" reverseOrder={false} />
        <MetaHelmet title="Move It | Helping Service" description="" />
        <Header></Header>
        <main>
            <div className="ugf-wrapper stepone">
                <div className="ugf-content-block">
                    <div className="content-block">
                      <h1>On Demand Delivery & Moving Service <br></br> Truck & Muscle, Anytime You Need It™</h1>
            		  <p>Move It is there for any of your small move, store / home pick up and delivery, junk removal, or just extra muscle around the house needs</p>
            		  
                    </div>
                </div>
                <div className="container movetype-book">
                    <div className="row">
                      <div className="col-lg-7 offset-lg-6 steppd-0">
                        <div className="ufg-job-application-wrapper pt60">
                          <div className="form-steps active">
                           <h3 className="border-bottom">Select Move Type</h3>
                            <form action="#" className="job-application-form">
                              
            				<div className="row">	
            				    {MoveType.length>0 && MoveType.map((option, index) => (
            					<div className="col-6 col-md-6 col-lg-4 mb-2" key={index} onClick={(e) => { handleProceed(option); }}>
            						<input className="checkbox-tools" type="radio" name="move_type_id" id="move_type_id" value={option.id}/>
            						<label className="for-checkbox-tools" htmlFor="tool-1">
            							<div className="cardbox">
            								<img src={option.image_url} alt="" />
            									<h5>{option.name}</h5>
            									<p>{option.description}</p>
            							</div>
            						</label>
                					
            					</div>
            					))}
            				</div> 
            			
                           </form>
                          </div>
                        </div>
                       </div>
                    </div>
                </div>
            </div>
        </main>
        <Footer></Footer>
        </> 
    ); 
}
export default BookTypes;

