import ReactDOM from 'react-dom';
import { BrowserRouter as Router,Routes,Route,Link,NavLink,generatePath,useHistory } from 'react-router-dom';
import Header from './../layout/Header'; //Include Heder
import Footer from './../layout/Footer'; //Include Footer
import HelperSideBar from './../layout/HelperSideBar';
import Service from './../../service'; 
import toast, { Toaster } from 'react-hot-toast';
import React, {useState, useCallback, Component, useEffect} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import {useFormik} from 'formik';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import ProfileSection from './profileSection';
import moment from 'moment-timezone';
// const config = require('./../../../../config.json');
import MetaHelmet from './../metaHelmet'; //Include metaHelmet
import ImageViewer from 'react-simple-image-viewer';
import parse from 'html-react-parser';
import socket from './../../socket';

var api = new Service();
let newFile = [];
const HelperSupport = props =>{

    const navigate = useNavigate();
    const [loading, setloading] = useState(false);
    const [helperProfile,sethelperProfile] = useState({});

    const [dataArray, setdataArray] = useState([]);
    const [pagesCount, setpagesCount] = useState(0);
    const [chatArray, setchatArray] = useState([]);
    const [message, setmessage] = useState('');
    const [chatDetail, setchatDetail] = useState({});
    const [chatResponse, setchatResponse] = useState({});
    const [pageno, setpageno] = useState(0);
    const [newMessage, setnewMessage] = useState(0);
    const [countData,setcountData] =  useState(0);
    const [helperId,sethelperId] =  useState(0);
    
    const [currentImage, setCurrentImage] = useState('');
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    
    const openImageViewer = (index) => {
        console.log(index)
        setCurrentImage(index);
        setIsViewerOpen(true);
    } 
    
    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };
    
    useEffect(() => {
        GetHelperProfile();
    }, [])
    const GetHelperProfile = () => {
        setloading(true)
        api.getApiHelper('get-helper-info').then(response => {
            if(response && response.is_verified==0) {
                setloading(false);
                navigate('/helper-pending')
            } else {
                sethelperId(response.helper_id)
                getChatMessage();
                sethelperProfile(response);
            }
        }).catch(error => { 
            setloading(false);
            toast.error(error.message);
        });
    }
     
    useEffect(() => {
        console.log(newMessage)
        if(newMessage) {
            if(newMessage.helper_id == helperId) {
                socket.emit('is-read-message-helper-admin',newMessage)
                setchatArray([...chatArray,newMessage]) 
                api.getApiHelper('update-admin-chat-count').then(response => { 
                  let countValue = countData+1;
                  setcountData(countValue);
                  setloading(false)
                }).catch(error => {
                     
                });
            } else {
                let countValue = countData+1;
                setcountData(countValue);
            }
        } else {
            GetHelperProfile();
          
        }
    }, [newMessage])
     
    useEffect(() => {
        setScroll()
        chatArray.forEach(function(file) {
            if(file.msg_type==2) {
                newFile[file.chat_id] = file.image_url;
            }
        });
        
    }, [chatArray.length])
     
    socket.on('send-message-helper-admin',(data)=> setnewMessage(data));
    
    // const GethelperProfile = () => {
    //     api.getApiHelper('get-helper-profile').then(response => { 
    //         sethelperId(response.data.helper_id)
    //         getChatMessage();
    //     }).catch(error => {
    //          toast.error(error.message);
    //     });
    // }
     
    const setScroll  = () => {
        var objDiv = document.getElementById("chat-conversation");
        if(objDiv){
            objDiv.scrollTop = objDiv.scrollHeight;
        }
         
    }
    
    const getChatMessage = (page_no=1) => {
        if(loading==false) {
            setloading(true)
            setpageno(page_no)
            api.postApiHelper('get-admin-chat',{page_no:page_no, timezone:moment.tz.guess()}).then(response => {
                setchatResponse(response)
                //  socket.emit('is-read-message-helper-admin',{data:{helper_id:helperId}})
                if(response && response.data.length>0) {
                    if(page_no==1) {
                        setchatArray(response.data.reverse())
                        var objDiv = document.getElementById("chat-conversation");
                        if(objDiv){
                           objDiv.scrollTop= objDiv.scrollHeight;
                        }
                    } else {
                        var objDiv = document.getElementById("chat-conversation");
                        setchatArray(response.data.reverse().concat(chatArray)) 
                        console.log(objDiv.scrollTop)
                        if(objDiv){
                           objDiv.scrollTop=300
                        }
                    }
                }
                setloading(false)
            }).catch(error => {
                setloading(false)
                toast.error(error.message);
            });
        }
    }
    
    const sendMessage = (msg_type=1,image_url='') => {
        console.log(message)
        
        if(message != '' || image_url!='') {
            setloading(true)
            api.postApiHelper('send-message-to-admin',{message:message,msg_type:msg_type,sent_by:'C',image_url:image_url,timezone:moment.tz.guess()}).then(response => {
                if(response && response.data) {
                  socket.emit('send-message-helper-admin', response.data);
                }
                setmessage('')
            }).catch(error => { 
                setloading(false)
                 toast.error(error.message);
            });
        }
    }
    
    
    const getScroll = (event) => {
        var objDiv = document.getElementById("chat-conversation");
        if(objDiv.scrollTop < 5 && chatResponse.next_page) {
            let page_no  = pageno +1;
            getChatMessage(page_no)
        }
    }
    
    const acceptedFiles  = e => {
       if(e.target.files.length) {
           setloading(true);
           var formData = new FormData();
            for (let i = 0; i < e.target.files.length; i++) {
              formData.append('image[]', e.target.files[i])
            }
            formData.append("folder_name",'helper_admin_chat');
            api.postApi('upload-images',formData,true).then(response => { 
                sendMessage(2,response.data[0])
                setloading(false);
                e.target.value = ''
            }).catch(error => {
                setloading(false);
                toast.error(error.message);
                e.target.value = ''
            }); 
       } else {
           
       }
    }
    
    
    
    return (
        <>
         <MetaHelmet title="Move It | Helper Support" description="" />
        <Toaster position="top-right" reverseOrder={false} />
        <Header helperProfile={helperProfile} />
            <main>
            {helperProfile.is_verified? 
            <>
                <section className="coverphoto-helper p-0"></section>
                 <ProfileSection helperProfile={helperProfile} />
            <section className="work white p40">
                <article className="container">
                    <div className="row">
                    <HelperSideBar helperProfile={helperProfile} />
                        <div className="col-md-8 col-lg-9">
                            <div className="row d-flex align-items-center justify-content-center" data-aos="fade-left" data-aos-delay="100">
                                <div className="col-12 col-md-12 col-lg-10">
                                    
                                </div>
        
                                <div className="col-md-12 col-lg-10">
                                      <div className="row">
                                         <div className="col-sm-12">
                                            <div className="home-page__content messages-page">
                                                <div className="container-fluid h-100 p-0">
                                                    <div className="row px-0 h-100">
                                                    
                                                        <div className="chat col-12 col-md-12 col-lg-12 col-xl-12 px-0 pl-md-1">
                                                            <div className="chat__container">
                                                                <div className="chat__wrapper py-2 pt-mb-2 pb-md-3">
                                                                    <div className="chat__messaging messaging-member--online pb-2 pb-md-2 pl-2 pl-md-4 pr-2">
                                                                        <div className="chat__previous d-flex d-md-none">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon svg-icon--previous" viewBox="0 0 45.5 30.4" >
                                                                                <path
                                                                                    d="M43.5,13.1H7l9.7-9.6A2.1,2.1,0,1,0,13.8.6L.9,13.5h0L.3,14v.6c0,.1-.1.1-.1.2v.4a2,2,0,0,0,.6,1.5l.3.3L13.8,29.8a2.1,2.1,0,1,0,2.9-2.9L7,17.2H43.5a2,2,0,0,0,2-2A2.1,2.1,0,0,0,43.5,13.1Z"
                                                                                    fill="#f68b3c"
                                                                                />
                                                                            </svg>
                                                                           
                                                                        </div>
                                                                        <div className="chat__notification d-flex d-md-none chat__notification--new">
                                                                            <span>1</span>
                                                                        </div> 
                                                                        <div className="chat__infos pl-2 pl-md-0">
                                                                            <div className="chat-member__wrapper" data-online="true">
                                                                                {/*<div className="chat-member__avatar">
                                                                                    <img src="/web/img/logo.png" alt="Jenny Smith" loading="lazy" style={{borderRadius: '100%',border: '2px solid #f2c2ca'}}/>
                                                                                </div>*/}
                                                                                <div className="chat-member__details">
                                                                                    <h3 className="text-left">Move It helper Support</h3>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="chat__content pt-4 px-3" onScroll={(e) => getScroll(e)} id="chat-conversation">
                                                                        <ul className="chat__list-messages">
                                                                            {chatArray.length > 0 && chatArray.map((option, index) => (
                                												<li  key={index} >
                                												     {option.sent_by=='A'? (
                                												      <>
                                    												  <div className="chat__bubble chat__bubble--you" >
                                    											    	{option.msg_type==2? 
                                    												    <img src={option.image_url} style={{width: "150px", height: "100px" }} onClick={ () => openImageViewer(option.chat_id) }  />
                                    													: <p>{parse(option.message)}</p> }
                                    												  </div>
                                    												  <div className="chat__time chat__time__left">{option.created_datetime}</div>
                                    												  </>
                                    												  ) : (
                                    												  <>
                                    												  
                                    												  <div className="chat__bubble chat__bubble--me" >
                                    												    {option.msg_type==2? 
                                    												    <img src={option.image_url} style={{width: "150px", height: "100px" }} onClick={ () => openImageViewer(option.chat_id) } />
                                    													: <p>{parse(option.message)}</p> }
                                    												  </div>
                                    												  <div className="chat__time">{option.created_datetime}</div>
                                    												  </>
                                    												  )
                                												     }
                                												      
                                												     
                                												</li>
                                												
                                												
                                												
                                											 ))}
                                                                            
                                                                        </ul>
                                                                    </div>
                                                                    <div className="chat__send-container px-2 px-md-3 pt-1 pt-md-3">
                                                                        <div className="custom-form__send-wrapper">
                                                                            <input type="text" value={message}  className="form-control custom-form d-block" id="message" placeholder="Type  Message" onChange={(e) => setmessage(e.target.value) } onKeyDown={e => e.key === 'Enter' && sendMessage()}  autoComplete="off" />
                                                                            <div className="custom-form__send-img">
                                                                               <label htmlFor="image_url"> <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon svg-icon--send-img" viewBox="0 0 45.7 45.7" > 
                                                                                    <path
                                                                                        d="M6.6,45.7A6.7,6.7,0,0,1,0,39.1V6.6A6.7,6.7,0,0,1,6.6,0H39.1a6.7,6.7,0,0,1,6.6,6.6V39.1h0a6.7,6.7,0,0,1-6.6,6.6ZM39,4H6.6A2.6,2.6,0,0,0,4,6.6V39.1a2.6,2.6,0,0,0,2.6,2.6H39.1a2.6,2.6,0,0,0,2.6-2.6V6.6A2.7,2.7,0,0,0,39,4Zm4.7,35.1Zm-4.6-.4H6.6a2.1,2.1,0,0,1-1.8-1.1,2,2,0,0,1,.3-2.1l8.1-10.4a1.8,1.8,0,0,1,1.5-.8,2.4,2.4,0,0,1,1.6.7l4.2,5.1,6.6-8.5a1.8,1.8,0,0,1,1.6-.8,1.8,1.8,0,0,1,1.5.8L40.7,35.5a2,2,0,0,1,.1,2.1A1.8,1.8,0,0,1,39.1,38.7Zm-17.2-4H35.1l-6.5-8.6-6.5,8.4C22,34.6,22,34.7,21.9,34.7Zm-11.2,0H19l-4.2-5.1Z"
                                                                                        fill="#f68b3c"
                                                                                    />
                                                                                </svg>
                                                                                </label>
                                                                                 <input type="file" id="image_url" style={{display:"none"}}  onChange={acceptedFiles}  />
                                                                            </div>
         
                                                                            <button type="button" disabled={loading} className="custom-form__send-submit"  onClick={() => sendMessage()} >
                                                                                <i className="fas fa-paper-plane fa-fw"></i>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
            </section>
            {isViewerOpen && (
            <ImageViewer
              src={newFile}
              currentIndex={currentImage}
              disableScroll={ false }
              closeOnClickOutside={ true }
              onClose={ closeImageViewer } 
            />
            )}
        </>
            : '' }
            </main>
        <Footer loading={loading} />
        </>
        )
    
}
export default HelperSupport;

