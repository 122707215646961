import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route, Link, NavLink, generatePath, useHistory } from 'react-router-dom';

// import OwlCarousel from 'react-owl-carousel';
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';
// import { Accordion, Card, Button } from 'react-bootstrap';

import Header from './layout/Header'; //Include Heder
import Footer from './layout/Footer'; //Include Footer
import MetaHelmet from './metaHelmet'; //Include metaHelmet

const options = {
	margin: 30,
	responsiveClass: true,
	nav: false,
	autoplay: true,
	smartSpeed: 1000,

};

function faqProMuscles() {
	return (
		<>
			<MetaHelmet title="Frequently Asked Questions, Help, FAQ,  - Move It" description="Find answers to your questions about Move It Pro & Muscle, pricing and services." />
			<Header></Header>
			<main>
				<section className="hero clean faq-andimg" style={{ backgroundImage: `url("web/img/05.jpg")` }}>
					<article>
						<div className="grid">
							<div className="unit-2-3 unit-1-1-md">
								<h1 className="h2 f-46">Frequently Asked Question</h1>
								<h2 className="h5 mb-2">Move It Pro & Muscle</h2>
							</div>
						</div>
					</article>
				</section>

				<section className="slate faq-accordian">
					<div className="container">
						<div className="row">
							<div className="col-md-12 mb-5">
								<div className="tabs">
									<div className="tab">
										<input type="radio" id="rd1" name="rd" />
										<label className="tab-label" htmlFor="rd1">What is Move It?</label>
										<div className="tab-content">
											Move It is an on-demand moving and delivery platform to help with all your big and heavy lifting. Think of us as the Uber and Lyft of moving and delivery. We connect you with local truck owners (called "Move It Pros") and local labor (called “Move it Muscle”) who are ready to help with your moving, delivery, and hauling needs.
										</div>
									</div>
									<div className="tab">
										<input type="radio" id="rd2" name="rd" />
										<label className="tab-label" htmlFor="rd2">How it works</label>
										<div className="tab-content">
											At some point everyone needs help moving things or just getting that couch or TV home from the store they just bought or even a storage unit run. Maybe some junk they need out of garage and they don’t have the truck, muscle, and/or time. When customers place an order, our app offers the jobs to helpers (aka Pros & Muscles), who earn money by picking up and delivering, moving, or hauling the items.
										</div>
									</div>

									<div className="tab">
										<input type="radio" id="rd3" name="rd" />
										<label className="tab-label" htmlFor="rd3">What will I be doing?</label>
										<div className="tab-content">
											Every Move It Pro and Muscle is their own independent enterprise contractor that chooses when they want to help others with delivering and moving needs.
										</div>
									</div>
									<div className="tab">
										<input type="radio" id="rd4" name="rd" />
										<label className="tab-label" htmlFor="rd4">What is a Move It Pro?</label>
										<div className="tab-content">
											Move It Pros are helpers who have a pickup truck, cargo van, boxed truck, or a vehicle w/a trailer. They are willing and able to help carry, lift, move, and deliver between two destinations.
										</div>
									</div>
									<div className="tab">
										<input type="radio" id="rd5" name="rd" />
										<label className="tab-label" htmlFor="rd5">What is a Move It Muscle?</label>
										<div className="tab-content">
											Move It Muscle are helpers who are pretty much labor. They help carry and lift items on an hourly basis. They have transportation so they can travel between destinations if needed.
										</div>
									</div>
									<div className="tab">
										<input type="radio" id="rd6" name="rd" />
										<label className="tab-label" htmlFor="rd6">How much can I make?</label>
										<div className="tab-content">
											Depending on the city you work in this varies.<br /> <br />
											Move It Pros can make $80+/hr <br />
											Move It Muscles make a minimum of $26+/hr <br />
											Helpers keep 100% of their tips. Earn anywhere from $26-$80+ / hr

										</div>
									</div>
									<div className="tab">
										<input type="radio" id="rd7" name="rd" />
										<label className="tab-label" htmlFor="rd7">Can I be both a Move It Pro & Muscle?</label>
										<div className="tab-content">
											Short answer, Absolutely. We encourage it so you can see as many jobs as possible.  <br /> <br />
											However, if you do not have a pickup truck, cargo van, boxed truck, or a vehicle w/a trailer you can ONLY sign up as a muscle
										</div>
									</div>
									<div className="tab">
										<input type="radio" id="rd8" name="rd" />
										<label className="tab-label" htmlFor="rd8">How do I get paid?</label>
										<div className="tab-content">
											Move It pays via direct deposit on a weekly basis. No long waits. Get paid on the last weeks activity. We use ZELLE. Free, quick, easy, secure way to paid directly to you. Signup process for Zelle account is through your bank and is quick and easy as well. Most all banks have Zelle. YOU MUST HAVE A ZELLE ACCOUNT TO BE PAID.
										</div>
									</div>
									<div className="tab">
										<input type="radio" id="rd9" name="rd" />
										<label className="tab-label" htmlFor="rd9">What are the requirements to be a Move It Pro or Muscle?</label>
										<div className="tab-content">
											-	Must be atleast 18 years of age <br />
											-	Move It Pros must have a clean pickup truck, cargo van, box truck or vehicle w/trailer model year 2000 or newer that is reliable and is capable of safely moving items in perfect condition. <br />
											-	Have valid driver’s license and auto insurance <br />
											-	Move It Muscles need to have transportation to get to and from Move It job destinations <br />
											-	Must have an iPhone or Android smartphone <br />
											-	Must be able to pass background check <br />
											-	Must be able to speak English <br />
											-	Willing and able to lift/carry large items (minimum 80 lbs) <br />
											-	Must have Zelle account to receive direct deposits <br />
											-	Must be friendly and courteous to customers at all times <br />

										</div>
									</div>
									<div className="tab">
										<input type="radio" id="rd10" name="rd" />
										<label className="tab-label" htmlFor="rd10">What kind of work will I be doing?</label>
										<div className="tab-content">
											Most Move It jobs are quick deliveries for someone that can’t fit an item in their vehicle from a store. Or small moves (moving a few things like a couch, TV, or table to one location to another). Or just helping people who can’t physically lift or carrying items by themselves or at all.
										</div>
									</div>


									<div className="tab">
										<input type="radio" id="rd11" name="rd" />
										<label className="tab-label" htmlFor="rd11">How do I get Move It jobs?</label>
										<div className="tab-content">
											Once you are approved on the Move It platform, you will have access to the Move It Helper app. Inside the app you will see available jobs you can accept and start working. Each Move It job will have specific details to make sure you can perform the job to the fullest extent. Work when you want, how often you want.
										</div>
									</div>
									<div className="tab">
										<input type="radio" id="rd12" name="rd" />
										<label className="tab-label" htmlFor="rd12">How long does it take to be approved after I apply?</label>
										<div className="tab-content">
											Your Move It application takes usually 5-7 business days, sometimes much quicker. We will stay in contact through email and/or chat on the app.
										</div>
									</div>

									<div className="tab">
										<input type="radio" id="rd13" name="rd" />
										<label className="tab-label" htmlFor="rd13">Highly recommended / required</label>
										<div className="tab-content">
											You are required to move and deliver customers items in a safe and secure manner. We highly suggest you have straps, bungies, dolly, blankets, tool box, and anything else to help make your move easiest for you and keeping the customers items safe and secure.
										</div>
									</div>
									<div className="tab">
										<input type="radio" id="rd14" name="rd" />
										<label className="tab-label" htmlFor="rd14">How do I communicate with the customer?</label>
										<div className="tab-content">
											You and your customer will be able to message / text and call one another via the Move It app. You should always be in communication with customer.
										</div>
									</div>

									<div className="tab">
										<input type="radio" id="rd15" name="rd" />
										<label className="tab-label" htmlFor="rd15">How do I communicate with the other helper on job with me?</label>
										<div className="tab-content">
											Helpers NEED to communicate on every job. Text and call is all through the Move It Helper app.
										</div>
									</div>

									<div className="tab">
										<input type="radio" id="rd16" name="rd" />
										<label className="tab-label" htmlFor="rd16">Who do I contact if I have more questions?</label>
										<div className="tab-content">
											Please contact <a href="mailto:helpers@gomoveit.com" target="_blank">helpers@gomoveit.com</a> whenever you have questions.
										</div>
									</div>
									<div className="tab">
										<input type="radio" id="rd17" name="rd" />
										<label className="tab-label" htmlFor="rd17">Cancellation fees</label>
										<div className="tab-content">
											In the event that you cancel your Move It with more than 24 hours notice, there is no fee. If you cancel with less than 24 hours notice and a job is scheduled, a 10% cancellation fee is applied. In the event that your Move It job is within two hours of arrival time, and you cancel for whatever reason, a 50% cancellation fee is applied.
											We understand emergencies can happen, please contact helper support right away if this happens. Depending on circumstances, we hold the right to waive cancellation fee.
										</div>
									</div>
									<div className="tab">
										<input type="radio" id="rd18" name="rd" />
										<label className="tab-label" htmlFor="rd18">What happens if I damage an item or the property on a job?</label>
										<div className="tab-content">
											In the event you damage an item, wall, floor, or anything else the in the pickup or drop-off location we will be here to help you. <br />
											The customer is covered by our $250 commercial liability insurance. However, you the helper, will also be deducted pay from this job to cover administrative costs. This can be up to 100% of the pay you were to receive on the job. <br />
											<br />
											Move It admin will do a full investigation.



										</div>
									</div>

								</div>

							</div>
						</div>
					</div>
				</section>

				<Link className="section red padd-50" to="/contact-us">
					<article className="text-center">
						<h6 className="h2">Still have  <strong> questions? </strong></h6>
						<span className="button outline inverse">Contact Us <i className="fa fa-chevron-right"></i></span>
					</article>
				</Link>
			</main>
			<Footer></Footer>
		</>
	);
}

export default faqProMuscles;
