import React, {useState, useCallback, Component, useEffect} from "react";
import { BrowserRouter as Router,Routes,Route,Link,NavLink } from 'react-router-dom';
import Header from './../layout/Header'; //Include Heder
import Footer from './../layout/Footer'; //Include Footer
import HelperSideBar from './../layout/HelperSideBar';
import ProfileSection from './profileSection';
import Service from './../../service'; 
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import MetaHelmet from './../metaHelmet'; //Include metaHelmet

import moment from 'moment-timezone';
var api = new Service();

const TipInfo= props =>{
    const navigate = useNavigate();
    const [helperProfile,sethelperProfile] = useState({});
    const [loading, setloading] = useState(false);
    const [page_no, setpage_no] = useState(1);
    const [pagesCount, setpagesCount] = useState(0);
    const [dataArray, setdataArray] = useState({paid:{info:[]},un_paid:[]});
    const [activeTab, setactiveTab] = useState('tab1');
    
    useEffect(() => {
        GetHelperProfile();
    }, [])
    const GetHelperProfile = () => {
        setloading(true)
        api.getApiHelper('get-helper-info').then(response => {
            if(response && response.is_verified==0) {
                setloading(false);
                navigate('/helper-pending')
            } else {
                sethelperProfile(response);
                getAccountingInfo();
            }
        }).catch(error => { 
            console.log(error)
            setloading(false);
            toast.error(error.message);
        });
    }
    const getAccountingInfo = (page_no=1) => {
        setloading(true)
        setpage_no(page_no)
        api.postApiHelper('get-helper-tip-accounting-info',{timezone:moment.tz.guess()}).then(response => {
            setdataArray(response.data);
            setloading(false)
        }).catch(error => {  
            setloading(false)
            toast.error(error.message);
        }); 
    }  
    
    
    const changeTab = (tab) => {
        setactiveTab(tab)
    }
    return (
        <>
        <Toaster position="top-right" reverseOrder={false} />
        <MetaHelmet title="Move It | Helper Tip Info" description="" /> 
             <Header helperProfile={helperProfile} />
            <main>
             {helperProfile.is_verified?
            <>
                <section className="coverphoto-helper p-0"></section>
                <ProfileSection helperProfile={helperProfile}/>
            
                <section className="work white p40">
                    <article className="container">
                        <div className="row">
                            <HelperSideBar helperProfile={helperProfile} />
                            <div className="col-md-8 col-lg-9">
                                <div className="row d-flex align-items-center justify-content-center" data-aos="fade-left" data-aos-delay="100">
                                    <div className="col-12 col-md-12 col-lg-10">
                                        <h3>Tip Payment Info</h3>
                                    </div>
                                    <div className="col-md-12 col-lg-10">
                                        <div className="contact-wrapper">
                                            <div id="tsum-tabs">
                                                <input id="tab1" type="radio" name="tabs" checked  readOnly/>
                                                <label htmlFor="tab1">Paid</label>
            
                                                <input id="tab2" type="radio" name="tabs"  readOnly/>
                                                <label htmlFor="tab2">Un-Paid</label>
                                                <section id="content1" className="mymovesbox">
                                                 <div className="notification-scroll" id="style-4"> 
                                                  {dataArray.paid.info.length > 0 && dataArray.paid.info.map((option, index) => (
                                                   
                                                    <div className="d-block w-100 float-right"  key={index}>
                                                        <div className="p-3 d-flex align-items-center bg-light border-bottom osahan-post-header">
                                                            <Link to="">
                                                                <div className="dropdown-list-image mr-3">
                                                                    <img className="rounded-circle" src={option.photo_url} alt="" />
                                                                </div> </Link>
                                                                <div className="font-weight-bold mr-3 notification-hrf">
                                                                    <Link to="">
                                                                        <div className="">Move ID #{option.request_id}</div>
                                                                        <div className="small">
                                                                            <span className="maptxt"><i className="far fa-calendar-alt pr-1 maploc-1"></i>{option.created_datetime}</span>
                                                                        </div>
                                                                    </Link>
                                                                </div>
                                                                <span className="ml-auto mb-auto">
                    													<div className="text-right price-helper-green">+${option.total_amount}</div>
                    											</span>
                                                           
                                                        </div>
                                                      </div>
                                                    ))}
                                                     </div>
                                                    
                                                    {dataArray.paid.info.length==0?
                                                        <div className="form-card-verifycation">
                                                            <div className="col-12 col-sm-12 mb-5">
                                                                <img src="web/img/logo.png" className="imgpnd" />
                                                                <h2 className="fs-title text-center mb-0">No Payment Information Yet!</h2>
                                                            </div>
                                                        </div>
                                                    : '' }
                                                </section>
                                                <section id="content2" className="mymovesbox">
                                                   <div className="notification-scroll" id="style-4"> 
                                                   
                                                   {dataArray.un_paid.length > 0 && dataArray.un_paid.map((option, index) => (
                                                        
                                                        <div className="d-block w-100 float-right"  key={index}>
                                                            <div className="p-3 d-flex align-items-center bg-light border-bottom osahan-post-header">
                                                                <Link to="">
                                                                    <div className="dropdown-list-image mr-3">
                                                                        <img className="rounded-circle" src={option.photo_url} alt="" />
                                                                    </div> </Link>
                                                                    <div className="font-weight-bold mr-3 notification-hrf">
                                                                        <Link to="">
                                                                            <div className="">Move ID #{option.request_id}</div>
                                                                            <div className="small">
                                                                                <span className="maptxt"><i className="far fa-calendar-alt pr-1 maploc-1"></i>{option.created_datetime}</span>
                                                                            </div>
                                                                        </Link>
                                                                    </div>
                                                                    <span className="ml-auto mb-auto">
                        												<div className="text-right price-helper-green">+${option.total_amount}</div>
                        											</span>
                                                            </div>
                                                          </div>
                                                        
                                                    ))}
						   	                        </div>
							                        {dataArray.un_paid.length==0? 
                                                        <div className="form-card-verifycation">
                                                            <div className="col-12 col-sm-12 mb-5">
                                                                <img src="web/img/logo.png" className="imgpnd" />
                                                                <h2 className="fs-title text-center mb-0">No Payment Information Yet!</h2>
                                                            </div>
                                                        </div>
                                                    : '' }
                                                </section>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>
                </section>
            </>
            : '' }
            </main>
             <Footer loading={loading}></Footer>
        </>
    )
}
export default TipInfo;